import { useTranslation } from '@elzeard/common-components';
import { LengthUnitEnum, variableToParameterInput } from '@elzeard/shared-dimensions';
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import HookedTextInput from '../../../common/components/field/InputField';
import { ConfirmationModal } from '../../../common/components/modal/ConfirmationModal';
import { PlotInput } from '../../../common/generated/graphql';
import { usePositionState } from '../state-context';
import { useCreatePlotMutation } from './useCreatePlotMutation';

export function DuplicatePlotModal({ duplicatedPlotId, onClose }: { duplicatedPlotId: string; onClose: () => void }) {
  const { t } = useTranslation();

  const { basePlots } = usePositionState();

  const duplicatedPlot = basePlots.find((plot) => plot.plotId === duplicatedPlotId);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ name: string }>({
    resolver: yupResolver(
      yup.object({
        name: yup.string().required().min(3),
      }),
    ),
    defaultValues: {
      name: '',
    },
  });

  const { farmId } = usePositionState();
  const [
    triggerCreatePlotMutation,
    {
      loading,
      // TODO error
    },
  ] = useCreatePlotMutation(farmId, onClose);

  return (
    <ConfirmationModal
      title={t('position:plot-modal.duplicate.title')}
      details={
        <HookedTextInput
          label={t('position:plot-modal.info-step.name')}
          required
          placeholder={t('position:plot-modal.info-step.name')}
          fieldName="name"
          register={register}
          errors={errors}
        />
      }
      loading={loading}
      onCancel={onClose}
      onConfirm={handleSubmit(({ name }) => {
        const { cultureMode, length, width } = duplicatedPlot;
        const lengthInput = variableToParameterInput({ value: length, unit: LengthUnitEnum.Meter })
        const objectInput: PlotInput = {
          identifierPlot: name,
          hasCultureModeInput: { id: cultureMode },
          hasLengthInput: lengthInput,
          hasWidthInput: {
            parameterValue: width,
            hasLengthUnitInput: { id: LengthUnitEnum.Meter },
            hasParameterUnitInput: { id: LengthUnitEnum.Meter },
          },
        };
        if ('beds' in duplicatedPlot) {
          objectInput.hasFootPassWidthInput = variableToParameterInput(duplicatedPlot.footpathWidth)
          objectInput.hasCultivationBedInputs = Array(duplicatedPlot.numberOfBeds).fill(0).map((_, index) => ({
            bedNumber: index+1,
            hasLengthInput: lengthInput,
            hasWidthInput: variableToParameterInput(duplicatedPlot.bedsWidth),
          }));
        }
        triggerCreatePlotMutation({
          variables: {
            farmId,
            input: objectInput
          },
        });
      })}
    />
  );
}
