import React, { PropsWithChildren, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { useClickAway } from 'react-use';
import styled from 'styled-components';

const ModalOverlay = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: rgba(0, 0, 0, 0.25);
  z-index: 100;
  backdrop-filter: blur(1px);
`;
const ModalContainer = styled.div`
  position: absolute;
`;

export function Modal({
  children,
  className,
  onClose,
}: PropsWithChildren<{ className?: string; onClose: () => void }>) {
  const rootElemRef = useRef<HTMLElement>(document.createElement('div'));
  const modalRef = useRef<HTMLDivElement>(null);
  useClickAway(modalRef, onClose);

  useEffect(() => {
    const elt = rootElemRef.current;
    document.body.appendChild(elt);
    return () => {
      elt.remove();
    };
  }, []);

  return createPortal(
    <ModalOverlay>
      <ModalContainer
        ref={modalRef}
        className={className}
      >
        {children}
      </ModalContainer>
    </ModalOverlay>,
    rootElemRef.current,
  );
}
