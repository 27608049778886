import { PlantFamilyColors } from '@elzeard/common-components';
import { MouseEventHandler, PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';
import { colorStyle, fillColors } from '../../../common/style/colors';
import { getTextStyle } from '../../../common/style/text';
import { timelineCell, weekWidth } from '../../table/style';
import { outletsHeights } from './style';

export const bodyWeekCell = css`
  ${timelineCell}
  ${getTextStyle(14, 'regular')}
  color: ${colorStyle.greys['1-black']};
`;
export type ProductWeekCellProps = PropsWithChildren<{
  isAvailable: boolean;
  hasValue: boolean;
  isDisabled: boolean;
  isEditing: boolean;
  onClick: MouseEventHandler;
  isSubrow?: boolean;
  isLastRow?: boolean;
  isFirstCell?: boolean;
  isLastCell?: boolean;
  plantFamilyColors: PlantFamilyColors;
}>;

export const OutletWeekCell = styled.td`
  ${bodyWeekCell}
  height: ${outletsHeights.outletRow};
  & > div {
    width: ${weekWidth - 1}px;
  }
`;
export const ProductWeekCell = styled.td<ProductWeekCellProps>`
  ${bodyWeekCell}
  height: ${outletsHeights.productRow};
  ${({ isDisabled, isEditing, isAvailable }) => (isAvailable && !isDisabled && !isEditing ? 'cursor: pointer;' : '')}
  position: relative;
  padding: 0;
  background-color: ${({ plantFamilyColors, isAvailable, hasValue }) =>
    hasValue && !isAvailable ? plantFamilyColors.veryFaded : 'transparent'};
  .content {
    position: relative; // make it appear over the absolute background
    width: ${weekWidth - 1}px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    ${({ isAvailable, hasValue }) =>
      isAvailable ? `background-color: ${hasValue ? fillColors.white85 : 'transparent'};` : ''};
  }
`;
export const SubProductWeekCell = styled.td<ProductWeekCellProps>`
  ${bodyWeekCell}
  height: ${outletsHeights.subProductRow};
  ${({ isDisabled, isEditing }) => (!isDisabled && !isEditing ? 'cursor: pointer;' : '')}
  ${({ isEditing }) => (isEditing ? 'position: relative;' : '')}    
  ${({ isAvailable }) => (isAvailable ? `background-color: #F8F7F5;` : '')};
  ${({ isLastRow, isAvailable }) => (isAvailable && isLastRow ? 'border-bottom: 1px solid #E5E1DC;' : '')}
  ${({ isFirstCell }) => (isFirstCell ? 'border-left: 1px solid #E5E1DC;' : '')}
  ${({ isLastCell }) => (isLastCell ? 'border-right: 1px solid #E5E1DC;' : '')}
  ${({ isLastRow, isAvailable, isFirstCell }) =>
    isLastRow && isAvailable && isFirstCell ? 'border-bottom-left-radius: 2px;' : ''}
  ${({ isLastRow, isAvailable, isLastCell }) =>
    isLastRow && isAvailable && isLastCell ? 'border-bottom-right-radius: 2px;' : ''}
  .content {
    position: relative; // make it appear over the absolute background
    width: ${weekWidth - 1}px;
  }
`;

export const BackgroundCellContainer = styled.div<{ isExpanded: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  overflow: visible;
  padding: 0;
  border: 0;
  pointer-events: none;

  .content {
    position: absolute;
    top: 5px;
    overflow: hidden;
    ${({ isExpanded }) =>
      isExpanded
        ? `
      height: calc(${outletsHeights.productRow} - 5px);
      border-radius: 2px 2px 0px 0px;
    `
        : `
      height: calc(${outletsHeights.productRow} - 10px);
      border-radius: 4px;
    `}
  }
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;
