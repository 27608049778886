import React, {
  ButtonHTMLAttributes,
  ChangeEventHandler,
  ComponentProps,
  ComponentType,
  FocusEventHandler,
  MouseEventHandler,
  MutableRefObject,
  ReactNode,
} from 'react';
import { Link, LinkProps, NavLink } from 'react-router-dom';
import styled, { ThemedStyledFunction } from 'styled-components';
import { ButtonLabel, buttonSize, ButtonStyleProps, getButtonStyle, TransientButtonStyleProps } from './Button_style';
import Spinner from './Spinner';
import { Svg } from './Svg';

const StyledButton = styled.button<TransientButtonStyleProps>`
  ${getButtonStyle}
`;

const StyledLinkButton = styled(Link)<TransientButtonStyleProps>`
  ${getButtonStyle}
`;

const StyledNavLinkButton = styled(NavLink)<TransientButtonStyleProps>`
  ${getButtonStyle}
`;

type CommonButtonProps = ButtonStyleProps & {
  label?: string; // TODO ReactNode ?
  leftIcon?: ComponentType;
  rightIcon?: ComponentType;
  loading?: boolean;
  loadingPosition?: 'left' | 'right';
  to?: LinkProps['to'];
  isNavLink?: boolean;
  children?: ReactNode;
  forwardedRef?: MutableRefObject<HTMLElement>;
};

type EitherElement = HTMLButtonElement | HTMLAnchorElement;

export type HTMLButtonProps = CommonButtonProps & {
  onBlur?: FocusEventHandler<EitherElement>;
  onFocus?: FocusEventHandler<EitherElement>;
  onChange?: ChangeEventHandler<EitherElement>;
  onClick?: MouseEventHandler<EitherElement>;
  onMouseEnter?: MouseEventHandler<EitherElement>;
  onMouseLeave?: MouseEventHandler<EitherElement>;
} & Pick<
    ButtonHTMLAttributes<HTMLButtonElement>,
    | 'autoFocus' // specific to button
    | 'className'
    | 'style'
    | 'title'
    | 'type'
  > & {
    navLinkActiveClassName?: Parameters<typeof NavLink>[0]['activeClassName'];
    navLinkExact?: Parameters<typeof NavLink>[0]['exact'];
  };

export type ButtonProps = HTMLButtonProps; // | ButtonLinkProps

export function Button({
  sizing = 'M',
  variant,
  fullWidth,
  isActive,
  isFilled,
  label,
  children = label,
  leftIcon,
  rightIcon,
  loading,
  loadingPosition = rightIcon && !leftIcon ? 'right' : 'left',
  to,
  isNavLink,
  navLinkActiveClassName: activeClassName,
  navLinkExact: exact,
  forwardedRef,
  type = 'button',
  ...buttonProps
}: ButtonProps) {
  const iconSize = buttonSize[sizing].iconSize;
  const innerContent = (
    <>
      {loading && loadingPosition === 'left' ? (
        <Spinner
          size={iconSize}
          textI18nKey={null}
          className="button-icon"
        />
      ) : (
        leftIcon && (
          <Svg
            value={leftIcon}
            size={iconSize}
            className="button-icon"
          />
        )
      )}
      {children && <ButtonLabel $sizing={sizing}>{children}</ButtonLabel>}
      {loading && loadingPosition === 'right' ? (
        <Spinner
          size={iconSize}
          textI18nKey={null}
          className="button-icon"
        />
      ) : (
        rightIcon && (
          <Svg
            value={rightIcon}
            size={iconSize}
            className="button-icon"
          />
        )
      )}
    </>
  );
  if (to) {
    const Component = isNavLink ? (StyledNavLinkButton as typeof StyledLinkButton) : StyledLinkButton;
    return (
      <Component
        {...({
          ref: forwardedRef as MutableRefObject<HTMLAnchorElement>,
          $sizing: sizing,
          $variant: variant,
          $fullWidth: fullWidth,
          $isActive: isActive,
          $isFilled: isFilled,
          to,
          activeClassName,
          exact,
          type,
          ...(buttonProps as CommonButtonProps & Omit<LinkProps, 'to'>),
        } as ComponentProps<typeof StyledLinkButton>)}
      >
        {innerContent}
      </Component>
    );
  } else {
    return (
      <StyledButton
        {...{
          ref: forwardedRef as MutableRefObject<HTMLButtonElement>,
          $sizing: sizing,
          $variant: variant,
          $fullWidth: fullWidth,
          $isActive: isActive,
          $isFilled: isFilled,
          type,
          ...(buttonProps as ButtonHTMLAttributes<HTMLButtonElement>),
        }}
      >
        {innerContent}
      </StyledButton>
    );
  }
}

/**
 * Helper tag function encapsulating `attrs` for simpler typing.
 *
 * Add it to your tsconfig for autocompletion:
 * 
 * ```
  "compilerOptions": {
    "plugins": [
      {
        "name": "typescript-styled-plugin",
        "tags": [
          "styled",
          "css",
          "buttonWithDefaults"
        ]
      }
    ]
  }
 ```
 */
export function buttonWithDefaults<P extends object = Partial<ButtonProps>>(
  attributes: ButtonProps | ((props: P & Partial<ButtonProps>) => ButtonProps),
) {
  return ((strings, ...templateArgs) =>
    styled(Button).attrs<P>(attributes)<P>(strings, ...templateArgs)) as unknown as ThemedStyledFunction<
    ComponentType<P & Partial<ButtonProps>>,
    any // TODO theme interface
  >;
}
