import { TFunction, useTranslation } from '@elzeard/common-components';
import { HarvestUnitEnum } from '@elzeard/shared-dimensions';
import React, { Fragment, ReactNode } from 'react';
import styled from 'styled-components';
import { ReactComponent as ArrowLeftIcon } from '../../../assets/arrow-strokebig-left.svg';
import { ReactComponent as AddIcon } from '../../../assets/icon-add.svg';
import { ReactComponent as SetupIcon } from '../../../assets/icon-setup.svg';
import { colorStyle } from '../../../common/style/colors';
import { getTextStyle } from '../../../common/style/text';
import { suffixNumber } from '../../../common/utils/math';
import { Setter } from '../../../common/utils/types';
import { translateHarvestUnitEnum } from '../../../shared-elzeard/constants/translate-constant';
import {
  HeaderEmptyRow,
  HeaderTable,
  HeaderTableBody,
  HeaderTableContainer,
  HeaderTableFoot,
  HeaderTableHead
} from '../../table/header-table-containers';
import { TopbarButton } from '../../table/page-containers';
import { headerCell, height, MarginRightCell, TopHeaderCell, TopHeaderTitle, TopHeaderUnit } from '../../table/style';
import { useOutletsCommands, useOutletsState } from '../state-context';
import { HeaderColumnName, MainDisplayOption } from '../state-displayOptions';
import { Outlet, OutletProduct } from '../state-full';
import { HeaderFooterRow, OutletHeaderRows } from './OutletHeaderRows';
import { SalesTargetHeaderRow } from './SalesTargetHeaderRow';
import { firstHeaderColumnWidth } from './style';

const AllOutletsTitleCell = styled.th`
  ${headerCell}
  height: ${height.headerRow};
  width: ${firstHeaderColumnWidth.allOutlets};
  min-width: ${firstHeaderColumnWidth.allOutlets};
  padding: 0 16px;
`;
const AllOutletsTitleCellContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const AllOutletsTitle = styled.div`
  flex: auto;
  text-transform: uppercase;
  ${getTextStyle(16, 'medium')}
  color: ${colorStyle.greys['1-black']};
`;

const EditedOutletTitleCell = styled.th`
  ${headerCell}
  height: ${height.headerRow};
  width: ${firstHeaderColumnWidth.editedOutlet};
  min-width: ${firstHeaderColumnWidth.editedOutlet};
  padding: 0 16px;
`;
const EditedOutletTitleCellContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const EditedOutletTitle = styled.div`
  flex: auto;
  ${getTextStyle(16, 'medium')}
  color: ${colorStyle.greys['1-black']};
`;
const EditedOutletTitleActions = styled.div`
  display: flex;
  gap: 8px;
`;

export interface HeaderProps {
  t: TFunction;
  isEditingSingleOutlet: boolean;
}
export interface ValueCellProps<C extends keyof OutletProduct> {
  value: OutletProduct[C];
  t: TFunction;
  formatNumber: ReturnType<typeof useTranslation>['formatNumber'];
}
export interface ColumnDefinition<C extends keyof OutletProduct> {
  header(props: HeaderProps): ReactNode;
  value(props: ValueCellProps<C>): ReactNode;
}

export const displayValue: ColumnDefinition<MainDisplayOption>['value'] = ({ value, formatNumber, t }) =>
  suffixNumber(value, formatNumber) ?? '-';

export const columnDefinitions: Partial<{
  [C in keyof OutletProduct]: ColumnDefinition<C>;
}> = {
  totalNeeds: {
    header({ t }) {
      return (
        <TopHeaderCell key="totalNeeds">
          <TopHeaderTitle>{t('outlet:header-table.head.totalNeeds.label')}</TopHeaderTitle>
        </TopHeaderCell>
      );
    },
    value: displayValue,
  },
  quantityUnit: {
    header({ t }) {
      return (
        <TopHeaderCell key="quantityUnit">
          <TopHeaderTitle>{t('outlet:header-table.head.quantityUnit.label')}</TopHeaderTitle>
        </TopHeaderCell>
      );
    },
    value({ value, t }) {
      return (
        translateHarvestUnitEnum(
          t,
          value,
          value === HarvestUnitEnum.Kilogram || value === HarvestUnitEnum.Tonne ? 'short' : 'long',
        ) || '-'
      );
    },
  },
  defaultUnitPrice: {
    header({ t }) {
      return (
        <TopHeaderCell key="defaultUnitPrice">
          <TopHeaderTitle>{t('outlet:header-table.head.defaultUnitPrice.label')}</TopHeaderTitle>
          <TopHeaderUnit>{t('outlet:header-table.head.defaultUnitPrice.unit')}</TopHeaderUnit>
        </TopHeaderCell>
      );
    },
    value: ({ value, formatNumber, t }) => {
      return value == null ? '-' : formatNumber(value, 0, 2);
    },
  },
  totalSales: {
    header({ t }) {
      return (
        <TopHeaderCell key="totalSales">
          <TopHeaderTitle>{t('outlet:header-table.head.totalSales.label')}</TopHeaderTitle>
          <TopHeaderUnit>{t('outlet:header-table.head.totalSales.unit')}</TopHeaderUnit>
        </TopHeaderCell>
      );
    },
    value: displayValue,
  },
};
export const allColumns: Array<HeaderColumnName> = ['totalNeeds', 'quantityUnit', 'defaultUnitPrice', 'totalSales'];

export type OutletsHeaderTableProps = {
  isEditingSingleOutlet: boolean;
  setShowCreateOutletForm: Setter<boolean | Outlet>;
};

const allOutletsColumns: Array<HeaderColumnName> = ['totalSales'];

export function OutletsHeaderTable({ isEditingSingleOutlet, setShowCreateOutletForm }: OutletsHeaderTableProps) {
  const { t, formatNumber } = useTranslation();
  const applyCommand = useOutletsCommands();
  const { outlets, allOutlets, visibleColumns, displaySalesTarget } = useOutletsState();

  const columns = isEditingSingleOutlet ? visibleColumns : allOutletsColumns;
  const editedOutlet = isEditingSingleOutlet && outlets[0];
  return (
    <HeaderTableContainer>
      <HeaderTable>
        <HeaderTableHead>
          <tr>
            {isEditingSingleOutlet ? (
              <>
                <EditedOutletTitleCell>
                  <EditedOutletTitleCellContent>
                    <EditedOutletTitle>{editedOutlet.name}</EditedOutletTitle>
                    <EditedOutletTitleActions>
                      <TopbarButton
                        onClick={() =>
                          applyCommand({
                            type: 'editOutletContent',
                            outletRowId: null,
                          })
                        }
                        leftIcon={ArrowLeftIcon}
                        // TODO title or tooltip
                      />
                      <TopbarButton
                        onClick={() => setShowCreateOutletForm(editedOutlet)}
                        rightIcon={SetupIcon}
                        // TODO title or tooltip
                      />
                    </EditedOutletTitleActions>
                  </EditedOutletTitleCellContent>
                </EditedOutletTitleCell>
                {visibleColumns.map((column) => columnDefinitions[column].header({ t, isEditingSingleOutlet }))}
              </>
            ) : (
              <>
                <AllOutletsTitleCell>
                  <AllOutletsTitleCellContent>
                    <AllOutletsTitle>{t('outlet:header-table.head.title')}</AllOutletsTitle>
                    <div>
                      <TopbarButton
                        onClick={() => setShowCreateOutletForm(true)}
                        rightIcon={AddIcon}
                      >
                        {t('outlet:home.topbar.create-outlet')}
                      </TopbarButton>
                    </div>
                  </AllOutletsTitleCellContent>
                </AllOutletsTitleCell>
                {columnDefinitions.totalSales.header({ t, isEditingSingleOutlet })}
              </>
            )}
            <MarginRightCell></MarginRightCell>
          </tr>
        </HeaderTableHead>
        <HeaderTableBody>
          {!isEditingSingleOutlet && (
            <>
              {displaySalesTarget && (
                <SalesTargetHeaderRow
                  {...{
                    totalSalesTarget: allOutlets.totalSalesTarget,
                    columns,
                  }}
                />
              )}
              <OutletHeaderRows
                {...{
                  outlet: allOutlets,
                  columns,
                  isAllOutlets: true,
                  isEditingSingleOutlet,
                  setShowCreateOutletForm,
                }}
              />
            </>
          )}
          {outlets.map((outlet) => (
            <Fragment key={outlet.rowId}>
              {displaySalesTarget && (
                <SalesTargetHeaderRow
                  {...{
                    totalSalesTarget: outlet.totalSalesTarget,
                    columns,
                  }}
                />
              )}
              <OutletHeaderRows
                {...{
                  outlet,
                  columns,
                  isEditingSingleOutlet,
                  setShowCreateOutletForm,
                }}
              />
            </Fragment>
          ))}
          <HeaderEmptyRow columns={columns} />
        </HeaderTableBody>
        <HeaderTableFoot>
          {isEditingSingleOutlet ? (
            <>
              <HeaderFooterRow
                columns={columns}
                title={t('outlet:header-table.foot.totalSales')}
                value={displayValue({
                  value: editedOutlet.totalSales,
                  formatNumber,
                  t,
                })}
              />
              <HeaderFooterRow
                columns={columns}
                title={t('outlet:header-table.foot.totalSalesTarget')}
                value={displayValue({
                  value: editedOutlet.totalSalesTarget,
                  formatNumber,
                  t,
                })}
              />
            </>
          ) : (
            <HeaderFooterRow
              columns={columns}
              title={t('outlet:header-table.foot.totalSales')}
              value={displayValue({
                value: allOutlets.totalSales,
                formatNumber,
                t,
              })}
            />
          )}
        </HeaderTableFoot>
      </HeaderTable>
    </HeaderTableContainer>
  );
}

// type FirstFooterCellProps = {
//   columns: OutletsPageState['visibleColumns'];
//   applyCommand: Dispatch<AllOutletCommands>;
//   colSpan?: number;
// };

// function FirstFooterCell({ columns, applyCommand, colSpan }: FirstFooterCellProps) {
//   const areAllColumnsVisible = columns.length === allColumns.length;
//   return (
//     <th colSpan={colSpan}>
//       <div
//         className="action-button"
//         onClick={() =>
//           applyCommand({
//             type: 'setVisibleColumns',
//             value: areAllColumnsVisible ? ['totalSales'] : allColumns,
//           })
//         }
//       >
//         <Svg
//           value={areAllColumnsVisible ? MinusIcon : AddIcon}
//           size={14}
//         />
//       </div>
//     </th>
//   );
// }
