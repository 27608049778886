import { buildWeek, Week } from '@elzeard/common-components';

export function getWeekKey(week: Week) {
  if (!week) {
    return null;
  }
  return week.year + '-' + week.weekNumber;
}

export function weekKeyToWeek(weekKey: string) {
  return buildWeek(...(weekKey.split('-').map(Number) as [number, number]));
}

export function buildOutletProductRowId(outletRowId: string, cropItineraryId: string) {
  return outletRowId + '-' + (cropItineraryId || Math.random());
}
