import { useTranslation } from '@elzeard/common-components';
import React from 'react';
import styled from 'styled-components';
import { ReactComponent as TargetIcon } from '../../../assets/arrow-stroke-lefttop.svg';
import { Svg } from '../../../common/components/Svg';
import { colorStyle } from '../../../common/style/colors';
import { getTextStyle } from '../../../common/style/text';
import { firstHeaderCell, headerCell } from '../../table/style';
import { OutletsPageState } from '../state-full';
import { outletsHeights } from './style';

const Row = styled.tr`
  height: ${outletsHeights.targetRow};
  th {
    ${firstHeaderCell}
  }
  .arrow-box {
    cursor: default;
    color: ${colorStyle.greys['4-defaultIcon']};
  }
  .title {
    ${getTextStyle(14, 'semibold')}
    color: ${colorStyle.greys['1-black']};
  }
  .value {
    ${headerCell}
    text-align: center;
    vertical-align: middle;
    ${getTextStyle(14, 'semibold')}
    color: ${colorStyle.greys['4-defaultIcon']};
  }
`;

export type SalesTargetHeaderRowProps = {
  columns: OutletsPageState['visibleColumns'];
  totalSalesTarget: number;
};

export function SalesTargetHeaderRow({ totalSalesTarget, columns }: SalesTargetHeaderRowProps) {
  const { t, formatNumber } = useTranslation();
  return (
    <Row>
      <th colSpan={columns.length}>
        <div className="content">
          <div className="arrow-box">
            <Svg
              size={12}
              value={TargetIcon}
            />
          </div>
          <div className="title">{t('outlet:header-table.sales-target.header')}</div>
        </div>
      </th>
      <td className="value">{totalSalesTarget ? formatNumber(totalSalesTarget) : '-'}</td>
      <td></td>
    </Row>
  );
}
