import React from 'react';
import { FieldPath, FieldValues } from 'react-hook-form';
import { ReactComponent as RadioCheckedIcon } from '../../../assets/radiocase-checked.svg';
import { ReactComponent as RadioUncheckedIcon } from '../../../assets/radiocase-unchecked.svg';
import { BaseCheckbox, CheckboxProps, Icons } from './base/BaseCheckbox';
import { ControlledHookedField, ControlledHookedFieldProps } from './base/ControlledHookedField';

const icons: Icons = {
  checked: RadioCheckedIcon,
  unchecked: RadioUncheckedIcon,
};

export function Radio({ ...props }: CheckboxProps) {
  return (
    <BaseCheckbox
      icons={icons}
      {...props}
    />
  );
}

export function HookedRadio<Form extends FieldValues>(
  props: ControlledHookedFieldProps<
    FieldPath<Form>,
    // @ts-ignore
    boolean,
    Form,
    CheckboxProps
  >,
) {
  return (
    // @ts-ignore
    <ControlledHookedField
      {...props}
      // @ts-ignore
      Child={Radio}
    />
  );
}
