import { getWeek, getWeekInterval, Week } from '@elzeard/common-components';
import { addDays, areIntervalsOverlapping, max, min } from 'date-fns';
import { last, sortBy } from 'lodash';
import { getWeekKey } from '../outlet/utils';

export interface ProductAvailability {
  begin: Week;
  end: Week;
  weeks: Record<string, boolean>;
}

export function buildAvailabilityPeriod(begin: Week, end: Week): ProductAvailability {
  const availabilityInterval = getWeekInterval(begin, end);
  return {
    begin,
    end,
    weeks: Object.fromEntries(availabilityInterval.weeks.map((week) => [getWeekKey(week), true])),
  };
}

export function buildStoragePeriod(
  harvestPeriod: Pick<ProductAvailability, 'begin' | 'end'>,
  storageDurationWeeks: number,
): ProductAvailability {
  if (!storageDurationWeeks) {
    return { begin: null, end: null, weeks: {} };
  }
  const begin = addDays(harvestPeriod.begin.firstDay, 7);
  const end = addDays(harvestPeriod.end.firstDay, 7 * storageDurationWeeks);
  return buildAvailabilityPeriod(getWeek(begin), getWeek(end));
}

export function mergeOverlappingPeriods(
  period1: ProductAvailability,
  period2: ProductAvailability,
): ProductAvailability {
  return {
    begin: getWeek(min([period1.begin.firstDay, period2.begin.firstDay])),
    end: getWeek(max([period1.end.firstDay, period2.end.firstDay])),
    weeks: {
      ...period1.weeks,
      ...period2.weeks,
    },
  };
}
/**
 *
 * @param periods1 sorted and non overlapping periods
 * @param periods2 sorted (same order as periods1) and non overlapping periods
 */

export function mergeMaybeOverlappingPeriods(
  periods1: Array<ProductAvailability>,
  periods2: Array<ProductAvailability>,
): Array<ProductAvailability> {
  // for each period from periods2, check the overlaps with the periods from periods1 => [period2, overlappingPeriods1, nonOverlappingPeriods1]
  const overlaps = periods2.map<[ProductAvailability, Array<ProductAvailability>, Array<ProductAvailability>]>(
    (period2) => [
      period2,
      ...periods1.reduce(
        (result, period1) => {
          const [overlappingPeriods1, nonOverlappingPeriods1] = result;
          if (
            areIntervalsOverlapping(
              {
                start: period2.begin.firstDay,
                end: period2.end.firstDay,
              },
              {
                start: period1.begin.firstDay,
                end: period1.end.firstDay,
              },
              { inclusive: true },
            )
          ) {
            overlappingPeriods1.push(period1);
          } else {
            nonOverlappingPeriods1.push(period1);
          }
          return result;
        },
        [[], []] as [Array<ProductAvailability>, Array<ProductAvailability>],
      ),
    ],
  );

  // pick the periods from periods1 that have no overlap with any period from periods2 (=> present in every nonOverlappingPeriods1 array)
  const nonOverlappingPeriods = (() => {
    const firstPeriodMatches = overlaps[0][2];
    const otherPeriodsMatches = overlaps.slice(1);
    return firstPeriodMatches.filter((period) =>
      otherPeriodsMatches.every(([, , otherNonOverlappingPeriods]) => otherNonOverlappingPeriods.includes(period)),
    );
  })();

  const mergedPeriods = overlaps
    .map(([period2, overlappingPeriods1]) =>
      overlappingPeriods1.reduce(
        (mergedPeriod, overlappingPeriod) => mergeOverlappingPeriods(mergedPeriod, overlappingPeriod),
        period2,
      ),
    )
    .reduce((nonOverlappingPeriods, period) => {
      const previousPeriod = last(nonOverlappingPeriods);
      if (
        previousPeriod &&
        areIntervalsOverlapping(
          {
            start: previousPeriod.begin.firstDay,
            end: previousPeriod.end.firstDay,
          },
          {
            start: period.begin.firstDay,
            end: period.end.firstDay,
          },
          { inclusive: true },
        )
      ) {
        nonOverlappingPeriods[nonOverlappingPeriods.length - 1] = mergeOverlappingPeriods(previousPeriod, period);
      } else {
        nonOverlappingPeriods.push(period);
      }
      return nonOverlappingPeriods;
    }, [] as Array<ProductAvailability>);

  if (nonOverlappingPeriods.length) {
    return sortBy([...mergedPeriods, ...nonOverlappingPeriods], (periods) => periods.begin.firstDay.getTime());
  } else {
    return mergedPeriods;
  }
}
