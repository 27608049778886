import { useTranslation } from '@elzeard/common-components';
import React, { FC, FunctionComponent, SetStateAction, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { useAuth } from '../../context/AuthContext';
import { useResetUserAccountPasswordMutation } from '../../generated/graphql';
import { colorStyle } from '../../style/colors';
import { getTextStyle } from '../../style/text';
import ButtonObsolete from '../ButtonObsolete';
import { ErrorMessage } from '../ErrorMessage';
import { HookedProps } from '../field/base/types';
import InputField, { TextInputProps } from '../field/InputField';
import PanelBase from './PanelBase';

const PanelContent = styled.div`
  padding: 36px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex-grow: 1;
  ${getTextStyle(12, 'regular')}
`;

const AccountInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
const AccountInfoLabel = styled.span`
  ${getTextStyle(12, 'medium')}
`;
const AccountInfoValue = styled.span`
  ${getTextStyle(12, 'regular')}
`;

const Actions = styled.div`
  margin-top: auto;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
const StyledInputField = styled(InputField)`
  & > input {
    border: 1px solid ${colorStyle.greys['1-black']};
    margin-top: -1px;
  }
` as unknown as FC<HookedProps<string, TextInputProps, IFormChangePassword>>;

const Form = styled.form`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

export interface IAccountSettingsPanelProps {
  handlePanelOpening: (value: SetStateAction<boolean>) => void;
}

enum PanelView {
  Info,
  ChangePassword,
  ChangePasswordSuccess,
}

interface IPanelViewProps {
  setView: (value: PanelView) => void;
}

const AccountSettingsPanel: FunctionComponent<IAccountSettingsPanelProps> = ({ handlePanelOpening }) => {
  const { t } = useTranslation();
  const [view, setView] = useState<PanelView>(PanelView.Info);

  return (
    <PanelBase
      panelTitle={
        view === PanelView.Info
          ? t('account:panel.title-settings')
          : (view === PanelView.ChangePassword || view === PanelView.ChangePasswordSuccess) &&
            t('account:panel.title-password')
      }
      handlePanelOpening={handlePanelOpening}
    >
      {view === PanelView.Info ? (
        <AccounSettingsPanelInfo setView={setView} />
      ) : view === PanelView.ChangePassword ? (
        <AccountSettingsPanelEdit setView={setView} />
      ) : (
        view === PanelView.ChangePasswordSuccess && <AccountSettingsPanelEditSuccess setView={setView} />
      )}
    </PanelBase>
  );
};

const AccounSettingsPanelInfo: FC<IPanelViewProps> = ({ setView }) => {
  const { t } = useTranslation();
  const auth = useAuth();
  return (
    <PanelContent>
      <AccountInfo>
        <AccountInfoLabel>{t('common:fields.username')}</AccountInfoLabel>
        <AccountInfoValue>{auth.user.userAccount.username}</AccountInfoValue>
      </AccountInfo>
      <AccountInfo>
        <AccountInfoLabel>{t('common:fields.email')}</AccountInfoLabel>
        <AccountInfoValue>{auth.user.userAccount.username}</AccountInfoValue>
      </AccountInfo>
      <AccountInfo>
        <AccountInfoLabel>{t('common:fields.password')}</AccountInfoLabel>
        <AccountInfoValue>*******************</AccountInfoValue>
      </AccountInfo>
      <Actions>
        <ButtonObsolete
          variant="outlined"
          fullWidth
          onClick={() => setView(PanelView.ChangePassword)}
        >
          {t('account:panel.action-change-password')}
        </ButtonObsolete>
      </Actions>
    </PanelContent>
  );
};

interface IFormChangePassword {
  oldPassword: string;
  newPassword: string;
  newPasswordConfirm: string;
}
const AccountSettingsPanelEdit: FC<IPanelViewProps> = ({ setView }) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    // watch,
    formState: { errors },
  } = useForm<IFormChangePassword>();
  const [apiError, setApiError] = useState<string | null>();
  const [mutateUpdatePassword] = useResetUserAccountPasswordMutation();

  const onSubmit = async (formData: IFormChangePassword) => {
    setApiError(null);
    try {
      await mutateUpdatePassword({ variables: { ...formData } });
      setView(PanelView.ChangePasswordSuccess);
    } catch (e) {
      setApiError(
        e.message === 'FORM_VALIDATION_ERROR' ? t('common:error.form-validation') : t('common:error.generic'),
      );
    }
  };

  return (
    <PanelContent>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <StyledInputField
          placeholder={t('account:panel.current-password')}
          fieldName="oldPassword"
          // @ts-ignore - error with register combined with a Styled InputField
          register={register}
          errors={errors}
          required
          type="password"
        />
        <StyledInputField
          placeholder={t('account:panel.new-password')}
          fieldName="newPassword"
          // @ts-ignore - error with register combined with a Styled InputField
          register={register}
          errors={errors}
          required
          type="password"
        />
        <StyledInputField
          placeholder={t('account:panel.new-password-confirm')}
          fieldName="newPasswordConfirm"
          // @ts-ignore - error with register combined with a Styled InputField
          register={register}
          errors={errors}
          required
          type="password"
        />

        <ErrorMessage
          message={
            (errors.oldPassword || errors.newPassword || errors.newPasswordConfirm) && t('common:error.empty-field')
          }
        />
        <ErrorMessage message={apiError} />
        <Actions>
          <ButtonObsolete
            variant="outlined"
            fullWidth
          >
            {t('common:button.validate')}
          </ButtonObsolete>
          <ButtonObsolete
            variant="outlined"
            fullWidth
            onClick={() => setView(PanelView.Info)}
          >
            {t('common:button.back')}
          </ButtonObsolete>
        </Actions>
      </Form>
    </PanelContent>
  );
};

const AccountSettingsPanelEditSuccess: FC<IPanelViewProps> = ({ setView }) => {
  const { t } = useTranslation();
  return (
    <PanelContent>
      {t('account:panel.password-updated-success')}
      <Actions>
        <ButtonObsolete
          variant="outlined"
          fullWidth
          onClick={() => setView(PanelView.Info)}
        >
          {t('common:button.OK')}
        </ButtonObsolete>
      </Actions>
    </PanelContent>
  );
};

export default AccountSettingsPanel;
