import { useTranslation } from '@elzeard/common-components';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as ArrowLeftIcon } from '../../assets/arrow-strokebig-left.svg';
import { ReactComponent as PurchaseResaleIcon } from '../../assets/culture-mode-achat-revente.svg';
import { ReactComponent as OutletIcon } from '../../assets/icon-outlet.svg';
import { ActionIcon } from '../../common/components/ActionIcon';
import { buttonWithDefaults } from '../../common/components/Button';
import { SecondaryTopbar } from '../../common/components/layouts/SecondaryTopbar';
import { ConfirmCommandModal } from '../../common/components/modal/ConfirmCommandModal';
import { AllProjectCommands } from '../commands';
import { ExportProjectButton } from '../common/ExportProjectButton';
import { SaveProjectButton } from '../common/SaveProjectButton';
import { ProjectPageSpinner } from '../ProjectPage';
import { ProjectPageState } from '../state';
import { useCommonProjectCommands } from '../state-context';
import { PageContainer, TablesContainer } from '../table/page-containers';
import { SeriesHeaderTable } from './components/SeriesHeaderTable';
import { SeriesTimelineTable } from './components/SeriesTimelineTable';
import { enterSeriesPage, leaveSeriesPage, updateSeriesState } from './state-build';
import { useSeriesCommands, useSeriesState } from './state-context';
import { SeriesPageState } from './state-full';

export interface SeriesPageProps {
  state: ProjectPageState<SeriesPageState>;
  applyCommand: (command: AllProjectCommands) => void;
}

export function SeriesPage() {
  const { pathname: routePath } = useLocation();

  const applyCommand = useCommonProjectCommands();
  const state = useSeriesState();

  // TODO find a way to initialize state without a useless first render
  useEffect(() => {
    applyCommand({
      type: 'enterPage',
      routePath,
      enter: enterSeriesPage,
      update: updateSeriesState,
    });
    return () => {
      applyCommand({
        type: 'leavePage',
        routePath,
        function: leaveSeriesPage,
      });
    };
  }, [applyCommand, routePath]);

  return state && 'seriesProducts' in state ? (
    <SeriesPageInner />
  ) : (
    <ProjectPageSpinner textI18nKey="outlet:home.spinner" />
  );
}

const SeriesTopbar = styled(SecondaryTopbar)`
  .secondary-topbar-left {
    gap: 10px;
  }
`;

const NavigationContainer = styled.div`
  display: flex;
`;
const NavigationButton = buttonWithDefaults({
  variant: 'CTA',
  sizing: 'M',
})`
  height: 40px;
`;

export function SeriesPageInner() {
  const { t } = useTranslation();
  const applyCommand = useSeriesCommands();
  const state = useSeriesState();
  const isEditingSingleProduct = !!state.editedSeriesProductRowId;

  return (
    <PageContainer>
      <SeriesTopbar
        left={
          <>
            <SaveProjectButton />
            <ExportProjectButton />
            <NavigationContainer>
              {isEditingSingleProduct ? (
                <>
                  <NavigationButton
                    leftIcon={ArrowLeftIcon}
                    onClick={() =>
                      applyCommand({
                        type: 'editProductSeries',
                        editedSeriesProductRowId: null,
                      })
                    }
                  >
                    {t('series:home.topbar.back')}
                  </NavigationButton>
                  <NavigationButton
                    isActive={state.displayedColumns === 'duration'}
                    onClick={() =>
                      applyCommand({
                        type: 'displayColumns',
                        displayedColumns: 'duration',
                      })
                    }
                  >
                    {t('series:home.topbar.duration')}
                  </NavigationButton>
                  <NavigationButton
                    isActive={state.displayedColumns === 'quantity'}
                    onClick={() =>
                      applyCommand({
                        type: 'displayColumns',
                        displayedColumns: 'quantity',
                      })
                    }
                  >
                    {t('series:home.topbar.quantity')}
                  </NavigationButton>
                </>
              ) : null}
            </NavigationContainer>
            <ActionIcon
              icon={OutletIcon}
              onClick={() => applyCommand({ type: 'toggleDisplayAllProducts' })}
              isActive={!state.displayAllProducts}
            />
            <ActionIcon
              icon={PurchaseResaleIcon}
              onClick={() => applyCommand({ type: 'toggleDisplayPurchaseResale' })}
              isActive={state.displayPurchaseResale}
              disabled={
                isEditingSingleProduct &&
                state.seriesProducts[0].purchaseResale &&
                !state.seriesProducts[0].purchaseResale.isToBeDeleted
              }
            />
          </>
        }
        right={
          isEditingSingleProduct ? (
            <NavigationButton
              isFilled
              label={t('series:home.topbar.auto-compute-needs')}
              onClick={() => applyCommand({ type: 'autoComputeSeriesNeeds' })}
            />
          ) : (
            <></>
          )
        }
      />
      <TablesContainer>
        <SeriesHeaderTable isEditingSingleProduct={isEditingSingleProduct} />
        <SeriesTimelineTable isEditingSingleProduct={isEditingSingleProduct} />
        {state.seriesCommandConfirmationModal && (
          <ConfirmCommandModal
            title={state.seriesCommandConfirmationModal.title}
            details={state.seriesCommandConfirmationModal.details}
            command={state.seriesCommandConfirmationModal.command}
            applyCommand={applyCommand}
            onCancel={() =>
              applyCommand({
                type: 'askSeriesCommandConfirmation',
                command: null,
                title: null,
              })
            }
          />
        )}
      </TablesContainer>
    </PageContainer>
  );
}
