import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { colorStyle } from '../../style/colors';
import { getTextStyle } from '../../style/text';
import { CenteredModal, CenteredModalProps } from './CenteredModal';

const DetailsContainer = styled.main`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 24px;
  gap: 10px;
  background: ${colorStyle.cta2['5']};
  ${getTextStyle(14, 'regular')}
  color: ${colorStyle.greys['3']};
`;

export interface ConfirmationModalProps extends CenteredModalProps {
  details?: ReactNode;
}
export function ConfirmationModal({ details, ...modalProps }: ConfirmationModalProps) {
  return (
    <CenteredModal {...modalProps}>
      {details && (
        <DetailsContainer>
          {/* <Svg value={InfoIcon} /> */}
          <div>{details}</div>
        </DetailsContainer>
      )}
    </CenteredModal>
  );
}
