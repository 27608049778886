import { useTranslation } from '@elzeard/common-components';
import { yupResolver } from '@hookform/resolvers/yup';
import { identity } from 'lodash';
import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import * as yup from 'yup';
import { HookedProps } from '../../../../common/components/field/base/types';
import HookedTextInput, { TextInputProps } from '../../../../common/components/field/InputField';
import { HookedRadioGroup } from '../../../../common/components/field/RadioGroup';
import { ModalErrorInformation, RightFormModal } from '../../../../common/components/modal/RightFormModal';
import { colorStyle } from '../../../../common/style/colors';
import { getTextStyle } from '../../../../common/style/text';
import { DistributionCircuit, PriceLevel } from '../../../state';
import { useOutletsState } from '../../state-context';
import { OutletForm } from './types';

const Main = styled.div`
  flex: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

const FieldLabel = styled.div`
  padding: 8px 0 0;
  ${getTextStyle(14, 'medium')}
  color: ${colorStyle.greys['3']};
`;
const SubFieldLabel = styled.div`
  padding: 8px 0 0;
  ${getTextStyle(14, 'regular')}
  color: ${colorStyle.greys['3']};
`;

const StyledInput = styled(HookedTextInput)`
  width: 100%;
` as unknown as FC<HookedProps<string, TextInputProps, OutletForm>>;

const priceLevels = [PriceLevel.Low, PriceLevel.Medium, PriceLevel.High];

const distributionCircuits = [DistributionCircuit.Short, DistributionCircuit.Long];

export interface IStep1Props {
  onClose: () => void;
  onSave: (form: OutletForm) => void;
  outletForm: OutletForm;
}

/**
 * https://www.figma.com/file/rlROk0f9Am247kGr5zNxUO/Pep-new?node-id=966%3A488308
 */
export const Step1: FC<IStep1Props> = ({ onClose, onSave, outletForm }) => {
  const { t } = useTranslation();
  const { outletsByRowId } = useOutletsState();
  const schema = yup.object({
    name: yup
      .string()
      .required()
      .notOneOf(
        Object.values(outletsByRowId)
          .filter((outlet) => !outletForm || outlet.name !== outletForm.name)
          .map((outlet) => outlet.name),
        t('outlet:config-main-modal.name.not-unique-error'),
      ),
    distributionCircuit: yup.string().required(),
    priceLevel: yup.string().required(),
  });
  const {
    control,
    register,
    handleSubmit,
    formState: { isValid, isSubmitted, errors },
  } = useForm<OutletForm>({
    resolver: yupResolver(schema),
    defaultValues: outletForm || {
      name: '',
      distributionCircuit: DistributionCircuit.Short,
      priceLevel: '' as PriceLevel,
    },
    mode: 'all',
  });

  return (
    <RightFormModal
      title={t('outlet:config-main-modal.title')}
      confirmButtonLabelKey="common:button.validate"
      cancelButtonLabelKey="common:button.back"
      onCancel={onClose}
      onConfirm={handleSubmit(onSave)}
      footerInfo={
        isSubmitted &&
        !isValid && (
          <ModalErrorInformation>
            {errors.name?.type === 'notOneOf' ? errors.name.message : t('common:error.missing-fields')}
          </ModalErrorInformation>
        )
      }
      blueBackground
    >
      <Main>
        <FieldLabel>{t('outlet:config-main-modal.name.label')} *</FieldLabel>
        <StyledInput
          fieldName="name"
          register={register}
          errors={errors}
          required
          placeholder={t('outlet:config-main-modal.name.placeholder')}
        />
        <FieldLabel>{t('outlet:config-main-modal.default-prices.title')} *</FieldLabel>
        <SubFieldLabel>{t('outlet:config-main-modal.default-prices.circuit.label')} *</SubFieldLabel>
        <HookedRadioGroup
          fieldName="distributionCircuit"
          required
          options={distributionCircuits}
          getOptionLabel={(value) => t(`outlet:config-main-modal.default-prices.circuit.${value}`)}
          getOptionId={identity}
          control={control}
        />
        <SubFieldLabel>{t('outlet:config-main-modal.default-prices.level.label')} *</SubFieldLabel>
        <HookedRadioGroup
          fieldName="priceLevel"
          required
          options={priceLevels}
          getOptionLabel={(value) => t(`outlet:config-main-modal.default-prices.level.${value}`)}
          getOptionId={identity}
          control={control}
        />
      </Main>
    </RightFormModal>
  );
};
export default Step1;
