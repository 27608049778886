import styled from 'styled-components';
import { timelineCell, weekWidth } from '../../table/style';

export const seriesHeights = {
  productRow: '45px',
  targetRow: '45px',
  seriesRow: '40px',
};

export const firstHeaderColumnWidth = {
  allProducts: '311px',
  allProductsTitle: '191px',
  editedProduct: '129px',
  editedProductTitle: '72px',
};

export const WeekCell = styled.td`
  ${timelineCell}
  .content {
    box-sizing: border-box;
    width: ${weekWidth - 1}px;
  }
`;
