import { useTranslation } from '@elzeard/common-components';
import React, { Dispatch, useMemo } from 'react';
import styled from 'styled-components';
import { EditedCell } from '../../outlet/components/EditedCell';
import { AllSeriesCommands } from '../commands';
import { useSeriesCommands } from '../state-context';
import { SeriesProduct } from '../state-full';
import { displayValue } from './SeriesHeaderTable';
import { seriesHeights, WeekCell } from './style';

const Container = styled(WeekCell)<{
  $isEditing: boolean;
}>`
  height: ${seriesHeights.seriesRow};
  ${({ $isEditing }) => ($isEditing ? 'position: relative;' : 'cursor: pointer;')}
  padding: 2px 0;
  .content {
    position: relative; // make it appear over the absolute background
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export interface EditablePurchaseResaleNeedCellProps {
  weekKey: string;
  product: SeriesProduct;
  isEditing: boolean;
}

export function EditablePurchaseResaleNeedCell({ weekKey, product, isEditing }: EditablePurchaseResaleNeedCellProps) {
  const { t, formatNumber } = useTranslation();
  const applyCommand = useSeriesCommands();

  const value = product.purchaseResale?.weeklyNeeds[weekKey];
  const formattedValue = displayValue({
    value,
    t,
    formatNumber,
  });
  return (
    <Container
      key={weekKey}
      $isEditing={isEditing}
      onClick={
        isEditing
          ? undefined
          : () =>
              applyCommand({
                type: 'setEditedSeriesCell',
                parentItineraryId: product.parentCropItineraryId,
                serieRowId: null,
                weekKey,
                editedProperty: null,
              })
      }
    >
      {isEditing ? (
        <EditedPurchaseResaleNeedCell
          {...{
            parentItineraryId: product.parentCropItineraryId,
            applyCommand,
            value,
            weekKey,
          }}
        />
      ) : (
        <div className="content">{formattedValue || '-'}</div>
      )}
    </Container>
  );
}

interface EditedPurchaseResaleNeedCellProps {
  parentItineraryId: string;
  weekKey: string;
  value: number;
  applyCommand: Dispatch<AllSeriesCommands>;
}
function EditedPurchaseResaleNeedCell({
  parentItineraryId,
  weekKey,
  value,
  applyCommand,
}: EditedPurchaseResaleNeedCellProps) {
  const { quitEdition, editProperty } = useMemo(() => {
    return {
      quitEdition: () =>
        applyCommand({
          type: 'setEditedSeriesCell',
          parentItineraryId: null,
          serieRowId: null,
          editedProperty: null,
          weekKey: null,
        }),
      editProperty: (value: number) =>
        applyCommand({
          type: 'editPurchaseResaleNeed',
          parentItineraryId,
          weekKey,
          value,
        }),
    };
  }, [applyCommand, parentItineraryId, weekKey]);

  return (
    <EditedCell
      value={value || null}
      quitEdition={quitEdition}
      editProperty={editProperty}
    />
  );
}
