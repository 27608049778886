import React, { Fragment } from 'react';
import { weekWidth } from '../../table/style';
import {
  StyledTimeline,
  TimelineCell,
  TimelineEmptyRow,
  TimelineRow,
  TimelineTable,
  TimelineTableBody,
  TimelineTableContainer,
  TimelineTableFoot,
  TimelineTableHead,
} from '../../table/timeline-table-containers';
import { useSeriesState } from '../state-context';
import { SeriesTimelineRows } from './SeriesTimelineRows';

export type SeriesTimelineTableProps = { isEditingSingleProduct: boolean };

export function SeriesTimelineTable({ isEditingSingleProduct }: SeriesTimelineTableProps) {
  const { seriesProducts, time } = useSeriesState();
  return (
    <TimelineTableContainer>
      <TimelineTable>
        <TimelineTableHead>
          <TimelineRow>
            <TimelineCell colSpan={time.weeks.length}>
              <StyledTimeline
                time={time}
                weekMargin={0}
                weekWidth={weekWidth}
                monthText="full"
              />
            </TimelineCell>
          </TimelineRow>
        </TimelineTableHead>
        <TimelineTableBody>
          {seriesProducts.map((product) => (
            <Fragment key={product.parentCropItineraryId}>
              <SeriesTimelineRows
                {...{
                  product,
                  isEditingSingleProduct,
                }}
              />
            </Fragment>
          ))}
          <TimelineEmptyRow time={time} />
        </TimelineTableBody>
        <TimelineTableFoot></TimelineTableFoot>
      </TimelineTable>
    </TimelineTableContainer>
  );
}
