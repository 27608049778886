import { noop } from 'lodash';
import React, { DetailedHTMLProps, Ref } from 'react';
import { FieldPath, FieldValues } from 'react-hook-form';
import { BaseFieldProps } from './base/types';
import { UncontrolledHookedField, UncontrolledHookedFieldProps } from './base/UncontrolledHookedField';
import { Field, FieldVariant } from './Field';

export type TextInputProps = BaseFieldProps<string> &
  Partial<
    Pick<
      DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
      // | 'onChange'
      'placeholder' | 'type' | 'onKeyDown'
    >
  > & {
    variant?: FieldVariant;
  };

export const TextInput = React.forwardRef(
  (
    { error, label, onValueChange = noop, type = 'text', variant = 'block', className, ...inputProps }: TextInputProps,
    ref: Ref<HTMLInputElement>,
  ) => {
    return (
      <Field
        $disabled={inputProps.disabled}
        $error={!!error}
        $hasValue={!!inputProps.value}
        $variant={variant}
        className={className}
      >
        {label && (
          <label>
            {label}
            {inputProps.required && ' *'}
          </label>
        )}
        <input
          ref={ref as any}
          className="input"
          type={type}
          onChange={(e) => onValueChange(e.target.value)}
          {...inputProps}
        />
      </Field>
    );
  },
);

export function HookedTextInput<Form extends FieldValues>(
  props: UncontrolledHookedFieldProps<
    FieldPath<Form>,
    // @ts-ignore
    string,
    Form,
    TextInputProps
  >,
) {
  return (
    // @ts-ignore
    <UncontrolledHookedField
      {...props}
      // @ts-ignore
      Child={TextInput}
    />
  );
}

export default HookedTextInput;
