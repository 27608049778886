import { ComponentType, MouseEventHandler } from 'react';
import { colorStyle } from '../style/colors';
import { buttonWithDefaults } from './Button';

export interface ActionIconProps {
  icon: ComponentType<{}>;
  onClick: MouseEventHandler;
  isActive?: boolean;
  disabled?: boolean;
  loading?: boolean;
  className?: string;
}
export const ActionIcon = buttonWithDefaults<ActionIconProps>((props) => ({
  variant: 'CTA',
  sizing: 'M',
  isFilled: false,
  leftIcon: props.icon,
}))`
  border-radius: 15px !important;
  padding: 1px;
  ${({ isActive }) =>
    isActive
      ? `
    color: ${colorStyle.cta1['1-active']};
    border: 2px solid ${colorStyle.cta1['1-active']};
    background-color: ${colorStyle.greys['9-white']};
  `
      : ''}
`;
