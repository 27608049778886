import styled from 'styled-components';
import { colorStyle } from '../../../common/style/colors';
import { getTextStyle } from '../../../common/style/text';
import { seriesHeights, WeekCell } from './style';

export interface SerieWeekCellProps {
  isAvailable: boolean;
  hasValue: boolean;
  isDisabled: boolean;
  isEditing: boolean;
}

export const ProductWeekCell = styled(WeekCell)<{
  isInPeriod: boolean;
  isFirst: boolean;
}>`
  height: ${seriesHeights.productRow};
  padding: 5px 0 0;
  ${({ isFirst }) => (isFirst ? 'position: relative;' : '')}
`;
export const AvailableProductWeek = styled.div<{
  total: number;
  target: number;
}>`
  position: relative; // make it appear over the absolute background
  ${getTextStyle(14, 'medium')}
  height: 35px;
  line-height: 35px;
  background: rgba(255, 255, 255, 0.8);
  border-right: 1px solid rgba(246, 244, 241, 0.2);
  color: ${({ total, target }) => {
    if (!total) {
      return colorStyle.greys['3'];
    } else if (total > target) {
      return '#088F4E';
    } else if (total === target) {
      return '#988210';
    } else {
      return '#981010';
    }
  }};
`;
export const TargetWeekCell = styled(WeekCell)<{ isInPeriod: boolean }>`
  position: relative; // make it appear over the absolute background
  height: ${seriesHeights.targetRow};
  padding: 0 0 5px;
  /* ${({ isInPeriod }) => (isInPeriod ? 'border: 1px solid rgba(246, 244, 241, 0.2);' : '')} */
`;
export const AvailableTargetWeek = styled.div`
  ${getTextStyle(12, 'regular')}
  height: 35px;
  line-height: 35px;
  width: 100%;
  background: rgba(255, 255, 255, 0.9);
  border: 1px solid rgba(246, 244, 241, 0.2);
`;
export const ProductBackgroundContainer = styled.div<{}>`
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  overflow: visible;
  padding: 0;
  border: 0;
  pointer-events: none;
`;
export const ProductBackground = styled.div<{}>`
  position: absolute;
  border-radius: 4px;
  top: 10px; // TODO should be 5px (top "margin") ?
  height: 74px; // TODO should be 74px ?
  overflow: hidden;
`;
