import { useTranslation } from '@elzeard/common-components';
import React, { useRef, useState } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { useClickAway } from 'react-use';
import styled from 'styled-components';
import { routes } from '../../../app/routes';
import { ReactComponent as IconLogo } from '../../../assets/icon-application-logo.svg';
import { colorStyle } from '../../style/colors';
import { PRIMARY_TOPBAR_HEIGHT } from '../../style/constants';
import { buttonWithDefaults } from '../Button';
import MainMenu from '../MainMenu';
import PanelAccountSettings from '../panels/PanelAccountSettings';

const Header = styled.header`
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: ${colorStyle.backgrounds['3']};
  /* color: ${colorStyle.greys['4']}; */
  height: ${PRIMARY_TOPBAR_HEIGHT};

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 25px;
  box-sizing: border-box;

  & > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
  }
`;

const MenuButton = buttonWithDefaults((props) => ({
  variant: 'secondary',
  sizing: props.sizing || 'S',
  navLinkActiveClassName: 'active-nav',
}))`
  height: 100%;
  padding: 0 11px;
  &.active-nav {
    border-radius: 0px;
    background-color: ${colorStyle.greys['7']};
    color: ${colorStyle.greys['1-black']};
  }
`;

const TutorielLink = styled.a`
  margin-left: auto;
  font-weight: 500;
  text-decoration: underline;
`;

export function PrimaryTopbar() {
  const { t } = useTranslation();
  const [isAccountSettingsPanelOpen, setIsAccountSettingsPanelOpen] = useState<boolean>(false);
  const [isMainMenuOpen, setIsMainMenuOpen] = useState(false);
  const refMainMenu = useRef<HTMLDivElement>(null);
  const refMainMenuButton = useRef<HTMLElement>(null);
  useClickAway(refMainMenu, (e) => {
    if (!refMainMenuButton.current?.contains(e.target as Node)) {
      setIsMainMenuOpen(false);
    }
  });
  const { farmId } = useParams<{ farmId?: string }>();
  return (
    <>
      <Header>
        <div>
          <MenuButton
            forwardedRef={refMainMenuButton}
            onClick={() => setIsMainMenuOpen((isOpen) => !isOpen)}
            // isMainMenuOpen={isMainMenuOpen}
            leftIcon={IconLogo}
            sizing="M"
          />
          <MenuButton
            isNavLink
            navLinkExact
            to={routes.root}
          >
            {t('common:app.menu.home')}
          </MenuButton>
          {farmId && (
            <>
              <MenuButton
                isNavLink
                to={generatePath(routes.project.details.outlet.root, { farmId })}
              >
                {t('common:app.menu.outlets')}
              </MenuButton>
              <MenuButton
                isNavLink
                to={generatePath(routes.project.details.series.root, { farmId })}
              >
                {t('common:app.menu.series')}
              </MenuButton>
              <MenuButton
                isNavLink
                to={generatePath(routes.project.details.position.root, { farmId })}
              >
                {t('common:app.menu.position')}
              </MenuButton>
            </>
          )}
        </div>
        <div>
          <TutorielLink href='https://edued.fr/BAS/pepiniere/' target='_blank' rel='noreferrer'>{t('common:app.tutorial')}</TutorielLink>
        </div>
      </Header>
      {isMainMenuOpen && (
        <div ref={refMainMenu}>
          <MainMenu setIsAccountSettingsPanelOpen={setIsAccountSettingsPanelOpen} />
        </div>
      )}
      {isAccountSettingsPanelOpen && <PanelAccountSettings handlePanelOpening={setIsAccountSettingsPanelOpen} />}
    </>
  );
}
