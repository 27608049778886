import { useTranslation } from '@elzeard/common-components';
import { HarvestUnitEnum } from '@elzeard/shared-dimensions';
import { sortBy } from 'lodash';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Checkbox } from '../../../common/components/field/Checkbox';
import { RightFormModal } from '../../../common/components/modal/RightFormModal';
import { colorStyle } from '../../../common/style/colors';
import { getTextStyle } from '../../../common/style/text';
import { Setter } from '../../../common/utils/types';
import { translateHarvestUnitEnum } from '../../../shared-elzeard/constants/translate-constant';
import { useOutletsCommands, useOutletsState } from '../state-context';

const Modal = styled(RightFormModal)`
  background-color: ${colorStyle.cta2['6']};
`;
const Table = styled.table`
  width: 100%;
  background-color: ${colorStyle.greys['9-white']};
  border-collapse: collapse;
  border-spacing: 0;

  td,
  th {
    height: 50px;
    padding: 0px 4px;
    border: 1px solid ${colorStyle.greys['7']};
    text-align: left;
    ${getTextStyle(14, 'regular')}
    color: ${colorStyle.greys['1-black']};
  }
`;

export interface ProductSelection {
  parentCropItineraryId: string;
  isSelected: boolean;
  name: string;
  quantityUnit: HarvestUnitEnum;
}

export interface SelectProductsModalProps {
  setShowSelectProductsModal: Setter<boolean>;
}

export function SelectProductsModal({ setShowSelectProductsModal }: SelectProductsModalProps) {
  const { t } = useTranslation();
  const { selectedParentProducts, otherPossibleParentProducts } = useOutletsState();
  const applyCommand = useOutletsCommands();

  const [selectedProducts, setSelectedProducts] = useState<ProductSelection[]>(() => {
    const products: ProductSelection[] = [
      ...Object.values(selectedParentProducts).map((product) => ({
        parentCropItineraryId: product.parentCropItineraryId,
        isSelected: true,
        name: product.name,
        quantityUnit: product.quantityUnit,
      })),
      ...Object.values(otherPossibleParentProducts).map((product) => ({
        parentCropItineraryId: product.parentCropItineraryId,
        isSelected: false,
        name: product.name,
        quantityUnit: product.quantityUnit,
      })),
    ];
    return sortBy(products, ({ name }) => name);
  });

  const toggleProductSelected = (editedProduct: ProductSelection) =>
    setSelectedProducts((selectedProducts) =>
      selectedProducts.map((product) =>
        product.parentCropItineraryId === editedProduct.parentCropItineraryId
          ? {
              ...product,
              isSelected: !product.isSelected,
            }
          : product,
      ),
    );

  const onClose = () => setShowSelectProductsModal(false);
  const onSubmit = () => {
    applyCommand({
      type: 'setSelectedProducts',
      selectedProducts,
    });
    onClose();
  };
  return (
    <Modal
      title={t('outlet:select-products-modal.title')}
      onCancel={onClose}
      onConfirm={onSubmit}
    >
      <Table>
        <thead>
          <tr>
            <th>{t('outlet:select-products-modal.headers.products')}</th>
            <th>{t('outlet:select-products-modal.headers.unit')}</th>
          </tr>
        </thead>
        <tbody>
          {selectedProducts.map((product) => (
            <tr key={product.parentCropItineraryId}>
              <td>
                <Checkbox
                  value={product.isSelected}
                  onValueChange={() => toggleProductSelected(product)}
                  label={product.name}
                />
              </td>
              <td>{translateHarvestUnitEnum(t, product.quantityUnit, 'long')}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Modal>
  );
}
