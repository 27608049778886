import { FarmingSystemEnum } from '@elzeard/shared-dimensions';
import React, { useEffect, useState } from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { routes } from '../../../app/routes';
import Spinner from '../../../common/components/Spinner';
import { Farm_Farm, useMyProjectQuery } from '../../../common/generated/graphql';
import { Setter } from '../../../common/utils/types';
import { Step1 } from './Step1';
import { Step2 } from './Step2';

export interface IEditedProject {
  id?: string;
  title: string;
  address: {
    street1: string;
    street2?: string;
    postalCode: string;
    city: string;
  };
  geopoint?: {
    latitude: number;
    longitude: number;
  };
  // climate?: ClimateEnum;
  // equipmentLevel?: FarmEquipmentLevelEnum;
  farmingSystem?: FarmingSystemEnum;
  beginDate?: string;
  outlets?: {
    id: string;
    name: string;
    isDisabled: boolean;
  }[];
}

function convertToEditedProject(farm: Farm_Farm): IEditedProject {
  return {
    id: farm.id,
    title: farm.name,
    address: {
      street1: farm.address.street1,
      street2: farm.address.street2,
      postalCode: farm.address.postalCode,
      city: farm.address.city
    },
    geopoint: farm.geolocation
      ? {
          latitude: farm.geolocation.lat,
          longitude: farm.geolocation.long,
        }
      : null,
    farmingSystem: farm.hasCulturalContext?.cultivatesIn?.edges[0]?.node.id as FarmingSystemEnum,
    beginDate: farm.isPepiniereProject?.beginDatePlan,
    outlets: farm.isPepiniereProject
      ? farm.isPepiniereProject?.hasOutlet.edges.map(({ node: outlet }) => ({
          id: outlet.id,
          name: outlet.name,
          isDisabled: outlet.isDisabled,
        }))
      : [],
  };
}

interface ProjectEditPanelProps {
  setShowCreationModal?: Setter<boolean>;
}
export function ProjectEditPanel({ setShowCreationModal }: ProjectEditPanelProps) {
  const [formStep, setFormStep] = useState<Number>(1);
  const history = useHistory();
  const { farmId: farmIdSuffix } = useParams<{ farmId: string }>();
  const fullFarmId = farmIdSuffix && `farm/${farmIdSuffix}`;

  const { data: projectData, loading } = useMyProjectQuery({
    variables: {
      id: fullFarmId,
    },
    skip: !fullFarmId,
  });

  const [editedFarm, setEditedFarm] = useState<IEditedProject>(null);
  // const [initialFarm, setInitialFarm] = useState<IEditedProject>(null);

  useEffect(() => {
    if (projectData) {
      const initialFarm = convertToEditedProject(projectData.farmForPepiniere as Farm_Farm);
      setEditedFarm(initialFarm);
      // setInitialFarm(initialFarm);
    }
  }, [projectData]);

  const handleClose = () => {
    setFormStep(1);
    setShowCreationModal?.(false);
    history.push(fullFarmId ? generatePath(routes.project.selected, { farmId: farmIdSuffix }) : routes.project.list);
  };

  if (loading) {
    return <Spinner />;
  }

  if (formStep === 1) {
    return (
      <Step1
        editedFarm={editedFarm}
        setEditedFarm={setEditedFarm}
        setFormStep={setFormStep}
        handleClose={handleClose}
      />
    );
  }
  if (formStep === 2) {
    return (
      <Step2
        editedFarm={editedFarm}
        // initialFarm={initialFarm}
        setFormStep={setFormStep}
        handleClose={handleClose}
      />
    );
  }

  return null;
}

export interface IStepProps {
  editedFarm: IEditedProject;
  setFormStep: Setter<number>;
  handleClose: () => void;
}

export default ProjectEditPanel;
