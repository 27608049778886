import { useTranslation } from '@elzeard/common-components';
import React, { PropsWithChildren, ReactNode } from 'react';
import styled from 'styled-components';
import { ReactComponent as CloseIcon } from '../../../assets/icon-cancel.svg';
import { colorStyle, popupShadow } from '../../style/colors';
import { getTextStyle } from '../../style/text';
import { Button } from '../Button';
import { Modal } from './Modal';

const Container = styled(Modal)`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 697px;
  background: ${colorStyle.greys['9-white']};
  ${popupShadow}
  border-radius: 16px;
`;
const ModalHeader = styled.header`
  height: 78px;
  padding: 0 24px;
  gap: 10px;
  display: flex;
  align-items: center;
  .modal-header-title {
    flex: auto;
    ${getTextStyle(16, 'semibold')}
    color: ${colorStyle.greys['3']};
  }
  .modal-header-buttons {
    flex: none;
  }
`;

const ModalFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  .modal-footer-info {
    flex: auto;
  }
  .modal-footer-actions {
    flex: none;
    display: flex;
    align-items: center;
    gap: 2px;
  }
`;

export interface CenteredModalProps {
  title: ReactNode;
  footerInfo?: ReactNode;
  footerExtraButtons?: ReactNode;
  className?: string;
  loading?: boolean;
  cancelActionLabelKey?: string;
  mainActionLabelKey?: string;
  onConfirm?: () => void;
  onCancel: () => void;
  onClose?: () => void;
  noCloseButton?: boolean;
  disabled?: boolean;
}
export function CenteredModal({
  title,
  children,
  footerInfo = null,
  footerExtraButtons = null,
  className,
  loading,
  mainActionLabelKey = 'common:button.validate',
  cancelActionLabelKey = 'common:button.cancel',
  onConfirm,
  onCancel,
  onClose = onCancel,
  noCloseButton,
  disabled,
}: PropsWithChildren<CenteredModalProps>) {
  const { t } = useTranslation();
  return (
    <Container
      className={className}
      onClose={onClose}
    >
      <ModalHeader>
        <div className="modal-header-title">{title}</div>
        <div className="modal-header-buttons">
          {noCloseButton || (
            <Button
              variant="CTA"
              sizing="M"
              isFilled
              leftIcon={CloseIcon}
              onClick={onClose}
            />
          )}
        </div>
      </ModalHeader>
      {children}
      <ModalFooter>
        <div className="modal-footer-info">{footerInfo}</div>
        <div className="modal-footer-actions">
          {footerExtraButtons}
          {onConfirm && (
            <Button
              variant="CTA"
              sizing="M"
              onClick={onCancel}
            >
              {t(cancelActionLabelKey)}
            </Button>
          )}
          <Button
            variant="CTA"
            sizing="M"
            isFilled
            loading={loading}
            disabled={disabled}
            onClick={onConfirm || onCancel}
          >
            {t(mainActionLabelKey)}
          </Button>
        </div>
      </ModalFooter>
    </Container>
  );
}
