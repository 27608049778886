import { useTranslation } from '@elzeard/common-components';
import { deburr, lowerCase, sortBy } from 'lodash';
import React, { FC, useMemo } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { routes } from '../../app/routes';
import { ReactComponent as IconAdd } from '../../assets/icon-add.svg';
import { ReactComponent as IconBin } from '../../assets/icon-bin.svg';
// import { ReactComponent as IconDuplicate } from '../assets/icon-duplicate.svg';
// import { ReactComponent as IconNotes } from '../assets/icon-notes.svg';
import { ReactComponent as IconSetup } from '../../assets/icon-setup.svg';
import { useMyProjectsQuery } from '../../common/generated/graphql';
import { Setter } from '../../common/utils/types';
import CardProject from './CardProject';

const Container = styled.div`
  padding: 36px;
  display: flex;
  flex-wrap: wrap;
  gap: 36px;
`;

export interface IProjectsGalleryProps {
  focusedProjectId: string | null;
  setShowCreationModal: Setter<boolean>;
}

export const ProjectsGallery: FC<IProjectsGalleryProps> = ({ focusedProjectId, setShowCreationModal }) => {
  const { data: meQueryData, loading, error } = useMyProjectsQuery();
  const { t } = useTranslation();
  const history = useHistory();

  const farms = useMemo(() => {
    if (loading || error || !meQueryData.farmsForPepiniere) {
      return [];
    }
    return sortBy(meQueryData.farmsForPepiniere, (farm) => deburr(lowerCase(farm.name)));
  }, [error, loading, meQueryData]);

  return (
    <Container>
      {farms.map((farm) => (
        <CardProject
          key={farm.id}
          farmId={farm.id}
          name={farm.name}
          updatedAt={farm.isPepiniereProject?.lastSaveAt}
          actions={[
            {
              label: t('project:card.settings'),
              icon: IconSetup,
              onClick: (event) => {
                event.stopPropagation();
                history.push(generatePath(routes.project.editModal, { farmId: farm.id.split('/')[1] }));
              },
            },
            // { label: t('project:card.duplicate'), icon: IconDuplicate, onClick: () => {} },
            // { label: t('project:card.notes'), icon: IconNotes, onClick: () => {} },
            {
              label: t('project:card.delete'),
              icon: IconBin,
              onClick: (event) => {
                event.stopPropagation();
                history.push(generatePath(routes.project.removeModal, { farmId: farm.id.split('/')[1] }));
              },
            },
          ]}
          active={focusedProjectId === farm.id}
        />
      ))}
      <CardProject
        name={t('project:edit.new-card-name')}
        actions={[
          {
            label: t('project:card.create'),
            icon: IconAdd,
            onClick: () => {
              history.push(routes.project.list);
              setShowCreationModal(true);
            },
          },
        ]}
      />
    </Container>
  );
};

export default ProjectsGallery;
