import styled from 'styled-components';
import { colorStyle } from '../../../common/style/colors';
import { getTextStyle } from '../../../common/style/text';
import { firstHeaderCell, headerCell, height } from '../../table/style';
import { firstHeaderColumnWidth, outletsHeights } from './style';

interface OutletNameCellProps {
  isEditingSingleOutlet: boolean;
}

export const OutletNameCell = styled.th<OutletNameCellProps>`
  ${firstHeaderCell}
  height: ${outletsHeights.outletRow};
  color: ${colorStyle.greys['1-black']};
  width: ${firstHeaderColumnWidth.allOutlets};
  text-align: left;
  .title {
    ${getTextStyle(14, 'semibold')}
    max-width: 265px;
  }
`;
interface ProductionCellProps {
  isExpanded: boolean;
  isEditingSingleOutlet: boolean;
}

export const ProductionNameCell = styled.td<ProductionCellProps>`
  ${firstHeaderCell}
  height: ${outletsHeights.productRow};
  width: ${({ isEditingSingleOutlet }) =>
    isEditingSingleOutlet ? firstHeaderColumnWidth.editedOutlet : firstHeaderColumnWidth.allOutlets};

  .title {
    ${getTextStyle(14, 'regular')}
    max-width: ${({ isEditingSingleOutlet }) => (isEditingSingleOutlet ? '130px' : '295px')};
  }
`;
export const ValueCell = styled.td`
  ${headerCell}
  padding-left: 4px;
  ${getTextStyle(14, 'regular')}
  color: ${colorStyle.greys['4-defaultIcon']};
`;
export const ProductionSubRowHeader = styled.td`
  height: ${outletsHeights.subProductRow};
  ${firstHeaderCell}
  padding-left: 50px;
  ${getTextStyle(14, 'regular')}
  .title {
    display: flex;
  }
  .unit {
    margin-left: 1ch;
  }
`;

export const headerFooterCell = `
  height: ${height.footerRow};
  ${headerCell}
  ${getTextStyle(14, 'semibold')}
`;
export const HeaderFooterTitleCell = styled.th`
  ${headerFooterCell}
  color: ${colorStyle.greys['1-black']};
  padding-left: 20px;
`;
export const HeaderFooterValueCell = styled.th`
  ${headerFooterCell}
  color: ${colorStyle.greys['4-defaultIcon']};
`;
