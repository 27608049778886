import styled from 'styled-components';
import { buttonWithDefaults } from '../../common/components/Button';
import { secondaryTopbarHeight } from '../../common/components/layouts/SecondaryTopbar';
import { colorStyle } from '../../common/style/colors';
import { PRIMARY_TOPBAR_HEIGHT } from '../../common/style/constants';

export const backgroundColor = colorStyle.greys['7'];
export const borderColor = colorStyle.greys['6-inactive'];
export const rightMargin = '20px';

export const PageContainer = styled.main`
  width: 100%;
  height: calc(100% - ${PRIMARY_TOPBAR_HEIGHT});
  padding-left: 30px;
  box-sizing: border-box;
`;

export const TablesContainer = styled.div`
  position: relative;
  width: 100%;
  height: calc(100% - ${secondaryTopbarHeight});
  overflow: auto;
  display: flex;
  outline: none;
  width: 100%;
  box-sizing: border-box;

  &::after {
    // trick to keep a right margin
    flex: none;
    content: '';
    display: block;
    width: ${rightMargin}; // should be 30px, but I keep an arbitrary extra 10px for the scrollbar
    position: sticky;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2;
    background-color: ${backgroundColor};
    border-left: 1px solid ${borderColor};
  }
`;

export const TopbarButton = buttonWithDefaults({
  variant: 'CTA',
  sizing: 'S',
  isFilled: true,
})``;
