import React from 'react';
import styled from 'styled-components';

const Container = styled.div``;

export interface INoMatchProps {}

export function ViewNoMatch() {
  return <Container>Nomatch</Container>;
}
