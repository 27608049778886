import { useTranslation } from '@elzeard/common-components';
import { lengthToUnit, LengthUnitEnum } from '@elzeard/shared-dimensions';
import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { z } from 'zod';
import { Field } from '../../../common/components/field/Field';
import { HookedNumberInput } from '../../../common/components/field/NumberInput';
import { HookedSelect } from '../../../common/components/field/Select';
import { CenteredModal } from '../../../common/components/modal/CenteredModal';
import { ModalErrorInformation } from '../../../common/components/modal/RightFormModal';
import { colorStyle } from '../../../common/style/colors';
import { translateLengthUnitEnum } from '../../../shared-elzeard/constants/translate-constant';
import { PlotForm } from './CreatePlotModal';

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: flex-start; */
  justify-content: center;
  padding: 24px;
  gap: 20px;
  /* width: 250px; */
  background: ${colorStyle.cta2['6']};
  border-width: 2px 0px;
  border-style: solid;
  border-color: ${colorStyle.cta2['1']};

  .input-with-unit {
    display: flex;
    gap: 4px;
  }
`;

export interface BedsForm {
  numberOfBeds: number;
  bedWidthValue: number;
  bedWidthUnit: LengthUnitEnum;
  footpassWidthValue: number;
  footpassWidthUnit: LengthUnitEnum;
}

function getMaxNumberOfBeds(
  { bedWidthUnit, bedWidthValue, footpassWidthUnit, footpassWidthValue }: BedsForm,
  plotWidth: number,
) {
  if (!plotWidth || !bedWidthValue || bedWidthValue < 0 || footpassWidthValue == null || footpassWidthValue < 0) {
    return 0;
  }
  const bedWidth = lengthToUnit({ parameterValue: bedWidthValue, hasLengthUnit: { id: bedWidthUnit } });
  const footpassWidth = lengthToUnit({
    parameterValue: footpassWidthValue,
    hasLengthUnit: { id: footpassWidthUnit },
  });
  return Math.floor((plotWidth + footpassWidth) / (bedWidth + footpassWidth));
}

const lengthUnits = [LengthUnitEnum.Centimeter, LengthUnitEnum.Meter];

export function BedsInfoModal({
  loading,
  plotForm,
  onConfirm,
  onClose,
  onPrevious,
}: {
  loading: boolean;
  plotForm: PlotForm;
  onClose: () => void;
  onPrevious: () => void;
  onConfirm: (form: PlotForm & BedsForm) => void;
}) {
  const { t } = useTranslation();

  // Partial<BedsForm> because tsconfig strictNullChecks = false
  const schema: z.ZodType<Partial<BedsForm>> = z
    .object({
      numberOfBeds: z.number().positive().int(),
      bedWidthValue: z.number().positive(),
      bedWidthUnit: z.nativeEnum(LengthUnitEnum),
      footpassWidthValue: z.number().nonnegative(),
      footpassWidthUnit: z.nativeEnum(LengthUnitEnum),
    })
    .required()
    .refine(
      (value) => {
        const maxNumberOfBeds = getMaxNumberOfBeds(value as BedsForm, plotForm.width);
        return value.numberOfBeds <= maxNumberOfBeds;
      },
      (value) => ({
        path: ['numberOfBeds'],
        message: t('position:plot-modal.beds-step.number-of-beds-error', {
          maxNumberOfBeds: getMaxNumberOfBeds(value as BedsForm, plotForm.width),
        }),
      }),
    );
  const {
    control,
    // register,
    handleSubmit,
    formState: { errors, isSubmitted, isValid },
  } = useForm<BedsForm & PlotForm>({
    resolver: zodResolver(schema),
    defaultValues: {
      ...plotForm,
      numberOfBeds: null,
      bedWidthValue: null,
      bedWidthUnit: LengthUnitEnum.Centimeter,
      footpassWidthValue: null,
      footpassWidthUnit: LengthUnitEnum.Centimeter,
    },
  });
  return (
    <CenteredModal
      title={`${plotForm.name} - ${t('position:plot-modal.beds-step.title')}`}
      mainActionLabelKey="common:button.validate"
      cancelActionLabelKey="common:button.previous"
      footerInfo={
        isSubmitted &&
        !isValid && (
          <ModalErrorInformation>
            {errors.numberOfBeds?.type === 'custom' ? errors.numberOfBeds.message : t('common:error.missing-fields')}
          </ModalErrorInformation>
        )
      }
      loading={loading}
      noCloseButton
      onConfirm={handleSubmit((form) =>
        onConfirm({
          ...plotForm,
          ...form,
        }),
      )}
      onCancel={onPrevious}
      onClose={onClose}
    >
      <StyledContent>
        <div className="field">
          <HookedNumberInput
            fieldName="numberOfBeds"
            label={t('position:plot-modal.beds-step.number-of-beds')}
            required
            placeholder={t('position:plot-modal.beds-step.number-of-beds')}
            control={control}
            variant="block"
          />
        </div>
        <Field>
          <label>{t('position:plot-modal.beds-step.bed-width')} *</label>
          <div className="input-with-unit">
            <HookedNumberInput
              fieldName="bedWidthValue"
              control={control}
              required
              variant="block"
            />
            <HookedSelect
              fieldName="bedWidthUnit"
              options={lengthUnits}
              getOptionAsString={(value) => translateLengthUnitEnum(t, value, 'short')}
              control={control}
            />
          </div>
        </Field>
        <Field>
          <label>{t('position:plot-modal.beds-step.footpass-width')} *</label>
          <div className="input-with-unit">
            <HookedNumberInput
              fieldName="footpassWidthValue"
              control={control}
              required
              variant="block"
            />
            <HookedSelect
              fieldName="footpassWidthUnit"
              options={lengthUnits}
              getOptionAsString={(value) => translateLengthUnitEnum(t, value, 'short')}
              control={control}
            />
          </div>
        </Field>
      </StyledContent>
    </CenteredModal>
  );
}
