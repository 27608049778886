/* eslint-disable import/no-anonymous-default-export */
export default {
  topbar: {
    'create-plot': 'Nouvelle parcelle',
  },
  'plot-menu': {
    button: 'Éditer',
    duplicate: 'Dupliquer la parcelle',
    delete: 'Supprimer la parcelle',
  },
  'plot-modal': {
    create: {
      'border-step-title': {
        main: 'NOUVELLE PARCELLE',
        length: 'Indiquez une longueur',
        width: 'Indiquez une largeur',
      },
      'border-placeholder': {
        length: 'Longueur',
        width: 'Largeur',
      },
      'border-unit-meters': 'Mètres',
    },
    'info-step': {
      create: {
        title: 'Identifier la nouvelle parcelle',
      },
      name: 'Nom de la parcelle',
      'culture-mode': 'Type de parcelle',
      'with-beds': {
        label: 'Y aura-t-il des planches permanentes ?',
        yes: 'Oui',
        no: 'Non',
      },
    },
    'beds-step': {
      title: 'Paramétrage des planches',
      'number-of-beds': 'Nombre de planches permanentes',
      'number-of-beds-error': 'Le nombre maximal de planches sur cette parcelle est de {{maxNumberOfBeds}}',
      'bed-width': 'Largeur des planches',
      'footpass-width': 'Largeur des passe-pieds',
    },
    duplicate: {
      title: 'Identifier le nouvel emplacement',
      name: 'Nom de la parcelle',
    },
    delete: {
      title: 'Supprimer une parcelle',
      'confirm-delete': 'Êtes-vous sûr de vouloir supprimer la parcelle “{{plotName}}” ?',
      'project-will-be-saved': 'La version actuelle de votre projet sera sauvegardée.',
    },
  },
};
