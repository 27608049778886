import React, { ButtonHTMLAttributes, ComponentType, FC } from 'react';
import styled, { css } from 'styled-components';
import { colorStyle } from '../style/colors';
import { getTextStyle } from '../style/text';
import Spinner from './Spinner';
import { Svg } from './Svg';

// TODO use a text property instead of the children property in order to force usage of the icons properties
export interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'outlined' | 'contained' | 'text';
  size?: 'medium' | 'large';
  fullWidth?: boolean;
  loading?: boolean;
  leftIcon?: ComponentType;
  rightIcon?: ComponentType;
}

const ContainerObsolete = styled.button<IButtonProps>`
  display: flex;
  width: ${(props) => (props.fullWidth ? '100%' : 'unset')};
  justify-content: center;
  align-items: center;
  text-align: center;
  ${(props) =>
    props.variant === 'contained'
      ? css`
          background-color: ${colorStyle.greys['1-black']};
          border: none;
          color: ${colorStyle.greys['9-white']};
        `
      : props.variant === 'outlined'
      ? css`
          background-color: ${colorStyle.greys['8']};
          border: 1px solid ${colorStyle.greys['4-defaultIcon']};
          color: ${colorStyle.greys['4-defaultIcon']};
        `
      : css`
          background-color: none;
          border: none;
          color: ${colorStyle.greys['1-black']};
        `};
  padding: 10px 16px;
  ${getTextStyle(12, 'semibold')}
  cursor: pointer;
  &:not([disabled]):hover {
    color: ${colorStyle.cta1['1-active']};
    border: ${(props) => (props.variant === 'outlined' ? '1px solid ' + colorStyle.cta1['1-active'] : 'none')};
  }
  .left-icon {
    margin-right: 12px;
  }
  .right-icon {
    margin-left: 12px;
  }
`;

export const ButtonObsolete: FC<IButtonProps> = ({ children, leftIcon, rightIcon, ...props }) => {
  return (
    <ContainerObsolete
      {...props}
      disabled={props.disabled || props.loading}
    >
      {leftIcon && (
        <div className="left-icon">
          <Svg
            value={leftIcon}
            size={12}
          />
        </div>
      )}
      {children}
      {(props.loading || rightIcon) && (
        <div className="right-icon">
          {props.loading ? (
            <Spinner
              textI18nKey={null}
              size={12}
            />
          ) : (
            <Svg
              value={rightIcon}
              size={12}
            />
          )}
        </div>
      )}
    </ContainerObsolete>
  );
};

ButtonObsolete.defaultProps = {
  size: 'medium',
  variant: 'text',
};

export default ButtonObsolete;
