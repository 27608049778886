import {
  Farm_Farm,
  ProductionCell,
  useCreatePlotMutation as useCreatePlotMutationFn,
} from '../../../common/generated/graphql';
import { connections } from '../../../graphql/project-outlet';

export function useCreatePlotMutation(farmId: string, onComplete: () => void) {
  return useCreatePlotMutationFn({
    update(cache, result) {
      if (!result.errors?.length) {
        const createdPlot = result.data.createPepinierePlot.createdObject;
        cache.writeFragment<Farm_Farm>({
          id: 'Farm_Farm:' + farmId,
          fragment: connections.cache_FarmCell,
          data: {
            id: farmId,
            hasProductionCell: {
              edges: [
                {
                  node: {
                    ...createdPlot.belongsToProductionCell,
                  },
                  __typename: 'ProductionCellEdge',
                },
              ],
              __typename: 'ProductionCellConnection',
            },
            __typename: 'Farm_Farm',
          },
        });
        const plotsCacheData = cache.readFragment<ProductionCell>({
          id: 'ProductionCell:' + createdPlot.belongsToProductionCell.id,
          fragment: connections.cache_CellPlots,
        }) || {
          ...createdPlot.belongsToProductionCell,
          hasPlot: { edges: [], __typename: 'PlotConnection' },
        };

        cache.writeFragment<ProductionCell>({
          id: 'ProductionCell:' + createdPlot.belongsToProductionCell.id,
          fragment: connections.cache_CellPlots,
          data: {
            ...plotsCacheData,
            hasPlot: {
              ...plotsCacheData.hasPlot,
              edges: [
                ...(plotsCacheData.hasPlot.edges || []),
                {
                  node: {
                    id: createdPlot.id,
                    __typename: 'Plot',
                  },
                  __typename: 'PlotEdge',
                },
              ],
            },
          },
        });
      }
      onComplete();
    },
  });
}
