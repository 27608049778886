import { getStorageBegin, getStorageEnd } from '@elzeard/common-planning';
import { ProductAvailability } from './common/ProductAvailability';
import { BaseProjectPageState, ChildProduct, ParentProduct } from './state';
import { InitialBaseState } from './state-init';

export function reinitBaseState({
  otherPossibleParentProducts,
  selectedParentProducts,
}: Pick<BaseProjectPageState, 'selectedParentProducts' | 'otherPossibleParentProducts'>): InitialBaseState {
  const init = Object.values(otherPossibleParentProducts).reduce(
    (acc, product) => {
      processProduct(product, acc);
      return acc;
    },
    {
      allPossibleParentProducts: {} as Record<string, ParentProduct>,
      parentItineraryIdByChildId: {} as Record<string, string>,
      parentItineraryIdByName: {} as Record<string, string>,
    },
  );
  return Object.values(selectedParentProducts).reduce((acc, parent) => {
    const unselectedChildren: [string, ChildProduct][] = Object.values(parent.selectedChildrenByRowId)
      .filter((child) => child.childCropItineraryId)
      .map((child) => {
        const series = child.itinerarySeries;
        const { harvestPeriods, storagePeriods } = series.reduce(
          (acc, serie) => {
            acc.harvestPeriods.push({
              begin: serie.harvest.begin,
              end: serie.harvest.end,
              weeks: serie.harvestWeeks,
            });
            acc.storagePeriods.push({
              begin: getStorageBegin(serie.harvest.end, serie.storageDays),
              end: getStorageEnd(serie.harvest.end, serie.storageDays),
              weeks: serie.storageWeeks,
            });
            return acc;
          },
          {
            harvestPeriods: [] as ProductAvailability[],
            storagePeriods: [] as ProductAvailability[],
            // maturationWeeks,
          },
        );
        return [
          child.childCropItineraryId,
          {
            ...child,
            isUpdated: false,
            series,
            harvestPeriods,
            // maturationWeeks,
            storagePeriods,
            // TODO ? productId: null,
          },
        ];
      });
    const unselectedParent: ParentProduct = {
      ...parent,
      isUpdated: false,
      selectedChildrenByRowId: {},
      otherPossibleChildrenByChildItineraryId: {
        ...parent.otherPossibleChildrenByChildItineraryId,
        ...Object.fromEntries(unselectedChildren),
      },
      purchaseResale: null,
      productionNeedsByOutletRowId: {},
      removedNeeds: [],
      // TODO ? productId: null,
    };
    processProduct(unselectedParent, acc);
    return acc;
  }, init);
}

function processProduct(parentProduct: ParentProduct, acc: InitialBaseState) {
  acc.parentItineraryIdByName[parentProduct.name] = parentProduct.parentCropItineraryId;
  acc.allPossibleParentProducts[parentProduct.parentCropItineraryId] = parentProduct;
  const children = Object.values(parentProduct.otherPossibleChildrenByChildItineraryId);
  for (const child of children) {
    acc.parentItineraryIdByChildId[child.childCropItineraryId] = parentProduct.parentCropItineraryId;
  }
}
