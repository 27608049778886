import React from 'react';
import styled from 'styled-components';
import { colorStyle } from '../../common/style/colors';
import { backgroundColor, borderColor } from './page-containers';
import { headerCell, MarginRightCell, tfoot, thead } from './style';

export const HeaderTableContainer = styled.div`
  display: flex;
  position: sticky;
  padding-bottom: 0px;
  z-index: 3;
  left: 0;
`;

export const HeaderTable = styled.table`
  position: relative;
  border-spacing: 0;
  border-collapse: collapse;
  background-color: ${colorStyle.greys['9-white']};
  margin-bottom: 2px; // for the border pseudo-elements

  &::before {
    // trick to have a left border
    content: '';
    display: block;
    width: 1px;
    position: absolute;
    left: -1px;
    top: 0;
    bottom: 0;
    z-index: 4;
    background-color: ${borderColor};
  }
  &::after {
    // trick to have a sticky right margin
    content: '';
    display: block;
    width: 8px;
    position: absolute;
    right: -8px;
    top: 0;
    bottom: 0;
    z-index: 4;
    background-color: ${backgroundColor};
    border-top: 1px solid ${backgroundColor};
    border-left: 1px solid ${borderColor};
    border-right: 1px solid ${borderColor};
  }
`;

// HEADER
export const HeaderTableHead = styled.thead`
  ${thead}
  z-index: 4;

  th:first-child,
  td:first-child {
    border-left: 1px solid ${borderColor};
  }
`;

// FOOTER
export const HeaderTableFoot = styled.tfoot`
  ${tfoot}
  background-color: ${colorStyle.greys['7']};
`;

export const HeaderTableBody = styled.tbody`
  /* position: sticky;
  inset-block-start: 0; */

  th:first-child,
  td:first-child {
    border-left: 1px solid ${borderColor};
  }
`;

const EmptyCell = styled.td`
  ${headerCell}
`;

/**
 * Used as a vertically growing flex element
 */
export function HeaderEmptyRow({ columns }: { columns: Array<string> }) {
  return (
    <tr>
      <EmptyCell />
      {columns.map((column) => (
        <EmptyCell key={column}></EmptyCell>
      ))}
      <MarginRightCell />
    </tr>
  );
}
