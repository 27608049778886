import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as SelectProductsIcon } from '../../assets/icon-select-vegetables.svg';
import { ReactComponent as TargetIcon } from '../../assets/icon-target.svg';
import { ActionIcon } from '../../common/components/ActionIcon';
import { SecondaryTopbar } from '../../common/components/layouts/SecondaryTopbar';
import { ConfirmCommandModal } from '../../common/components/modal/ConfirmCommandModal';
import { ExportProjectButton } from '../common/ExportProjectButton';
import { SaveProjectButton } from '../common/SaveProjectButton';
import { ProjectPageSpinner } from '../ProjectPage';
import { useCommonProjectCommands } from '../state-context';
import { PageContainer, TablesContainer } from '../table/page-containers';
import { MainDisplayOptionSelector } from './components/MainDisplayOptionSelector';
import { OutletCreationForm } from './components/outlet-creation-form/OutletCreationForm';
import { OutletsHeaderTable } from './components/OutletsHeaderTable';
import { OutletsTimelineTable } from './components/OutletsTimelineTable';
import { SelectProductsModal } from './components/SelectProductsModal';
import { enterOutletsPage, leaveOutletsPage, updateOutletsState } from './state-build';
import { useOutletsCommands, useOutletsState } from './state-context';
import { Outlet } from './state-full';

export function OutletsHomePage() {
  const { pathname: routePath } = useLocation();

  const applyCommand = useCommonProjectCommands();
  const state = useOutletsState();

  // TODO find a way to initialize state without a useless first render
  useEffect(() => {
    applyCommand({
      type: 'enterPage',
      routePath,
      enter: enterOutletsPage,
      update: updateOutletsState,
    });
    return () => {
      applyCommand({
        type: 'leavePage',
        routePath,
        function: leaveOutletsPage,
      });
    };
  }, [applyCommand, routePath]);

  return state && 'outlets' in state ? <OutletsPageInner /> : <ProjectPageSpinner textI18nKey="outlet:home.spinner" />;
}

const OutletsTopbar = styled(SecondaryTopbar)`
  & > .secondary-topbar-left {
    gap: 10px;
  }
`;

export function OutletsPageInner() {
  const [showCreateOutletForm, setShowCreateOutletForm] = useState<boolean | Outlet>(false);
  const [showSelectProductsModal, setShowSelectProductsModal] = useState<boolean>(false);

  const applyCommand = useOutletsCommands();
  const state = useOutletsState();
  const isEditingSingleOutlet = !!state.editedOutletRowId;

  return (
    <PageContainer>
      <OutletsTopbar
        left={
          <>
            <SaveProjectButton />
            <ExportProjectButton />
            <ActionIcon
              icon={TargetIcon}
              isActive={state.displaySalesTarget}
              onClick={() =>
                applyCommand({
                  type: 'toggleDisplaySalesTarget',
                })
              }
            />
            <ActionIcon
              icon={SelectProductsIcon}
              onClick={() => setShowSelectProductsModal(true)}
            />
          </>
        }
        right={
          <>
            <MainDisplayOptionSelector
              value={state.mainDisplayOption}
              onChange={(value) =>
                applyCommand({
                  type: 'changeMainDisplayOption',
                  value,
                })
              }
            />
          </>
        }
      />
      <TablesContainer>
        <OutletsHeaderTable
          isEditingSingleOutlet={isEditingSingleOutlet}
          setShowCreateOutletForm={setShowCreateOutletForm}
        />
        <OutletsTimelineTable isEditingSingleOutlet={isEditingSingleOutlet} />
        {showCreateOutletForm && (
          <OutletCreationForm
            onClose={() => setShowCreateOutletForm(false)}
            onSave={(newOutlet, defaultPrices) => {
              if (showCreateOutletForm === true) {
                applyCommand({
                  type: 'createOutlet',
                  value: newOutlet,
                  defaultPrices,
                });
              } else {
                applyCommand({
                  type: 'editOutletSettings',
                  outletRowId: showCreateOutletForm.rowId,
                  value: newOutlet,
                  defaultPrices,
                });
              }
              setShowCreateOutletForm(false);
            }}
            editedOutlet={showCreateOutletForm === true ? null : showCreateOutletForm}
          />
        )}
        {showSelectProductsModal && <SelectProductsModal setShowSelectProductsModal={setShowSelectProductsModal} />}
        {state.confirmationModal && (
          <ConfirmCommandModal
            title={state.confirmationModal.title}
            details={state.confirmationModal.details}
            command={state.confirmationModal.command}
            applyCommand={applyCommand}
            onCancel={() =>
              applyCommand({
                type: 'askConfirmation',
                command: null,
                title: null,
              })
            }
          />
        )}
      </TablesContainer>
    </PageContainer>
  );
}
