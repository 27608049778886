import { getPlantColors, getPlantFamilyColorsFromScientificName } from '@elzeard/common-components';

// Colors from Figma (https://www.figma.com/file/rlROk0f9Am247kGr5zNxUO/Pep-new?node-id=0%3A1)
// In the same order, with similar names.

export const popupShadow = 'box-shadow: 0px 0px 40px rgba(73, 98, 226, 0.25);';

export const colorStyle = {
  root: {
    paleOrange: '#FFEFE5',
    lightRed: '#FFE7E7',
    component: '#75654C',
  },
  greys: {
    '1-black': '#130B07',
    '2': '#2E2E2E',
    '3': '#434343',
    '4-defaultIcon': '#5F5D59',
    '5': '#989696',
    '6-inactive': '#CCCCCC',
    '7': '#F6F4F1',
    '8': '#FFFEFC',
    '9-white': '#FFFFFF',
  },
  backgrounds: {
    '1': '#F2EEE1',
    '2': '#FBF7EC',
    '3': '#FFFDF8',
  },
  cta1: {
    '1-pressed': '#DF8755',
    '1-active': '#FF9B63',
    '2-hover': '#FFBF9B',
    '3': '#FFE1D0',
  },
  cta2: {
    '0': '#2C5EA8',
    '1': '#4962E2',
    '2': '#89B8FF',
    '3': '#CFE2FF',
    '4': '#E6F0FE',
    '5': '#EFF3FF',
    '6': '#F7FAFF',
  },
  error: {
    '1': '#DF1414',
    '2': '#FC4E4E',
    '3': '#FF7272',
    '4': '#FFACAC',
    '5': '#FFD6D6',
    '6': '#FFEAEA',
  },
  validate: {
    '1': '#9EDF14',
    '2': '#CBEF90',
    '3': '#EEFFD8',
  },
  secondary: {
    '1-inputDigit': '#B4DBFF',
    '2': '#CBEBE7',
    '3': '#EBFFFD',
    '4': '#FAFFFE',
  },
};

/** @deprecated */
export const otherColors = {
  // paleOrange: colors.root.paleOrange,
  fondSousMenu: '#636363',
  fondAuth: '#CEFEF8',
};

/** @deprecated */
export const fillColors = {
  // header1: colors.greys['2'], // '#2E2E2E',
  // setup: colors.greys['3'], // '#434343',
  // header2: colors.greys['4-defaultIcon'], // '#5F5D59',
  // textHeader2: '#D5D5D5',
  // lightGrey: colors.greys['7'], // '#F6F4F1',
  // fondScreen: colors.greys['8'], // '#FFFEFC',
  // sable: '#F6F3EA',
  white85: 'rgba(255, 250, 245, 0.85)',
  selectedCell: 'rgba(255, 92, 0, 0.07)',
  // postIt: '#FFF492',
};

export type PlantFamilyColorShade = 'primary' | 'faded' | 'veryFaded';

export function getPlantFamilyColor(plantFamily: string, shade: PlantFamilyColorShade = 'primary'): string {
  return getPlantColors(getPlantFamilyColorsFromScientificName(plantFamily))[shade];
}
