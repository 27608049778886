import { useTranslation, Week } from '@elzeard/common-components';
import { Variable, Without, YieldUnitEnum } from '@elzeard/shared-dimensions';
import React, { Dispatch, useMemo, useState } from 'react';
import styled from 'styled-components';
import { getTextStyle } from '../../../common/style/text';
import { KeyOfType } from '../../../common/utils/types';
import { EditedCell } from '../../outlet/components/EditedCell';
import { HeaderCellMenu } from '../../outlet/components/HeaderCellMenu';
import { useProjectBaseState } from '../../state-context';
import { AllSeriesCommands } from '../commands';
import { ProductSerie } from '../state-full';
import { HeaderFieldDef, seriesColumnDefinitions } from './SeriesHeaderTable';
import { seriesHeights, WeekCell } from './style';

const Container = styled(WeekCell)<{
  isEditing: boolean;
}>`
  height: ${seriesHeights.seriesRow};
  ${({ isEditing }) => (!isEditing ? 'cursor: pointer;' : '')}
  ${({ isEditing }) => (isEditing ? 'position: relative;' : '')}
  padding: 2px 0;
  .content {
    position: relative; // make it appear over the absolute background
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    ${getTextStyle(14)}
  }
`;

type SerieNumberOrWeekProperty = KeyOfType<
  Without<ProductSerie, 'editedSurface' | 'computedSurface' | 'computedQuantity'>,
  number | Week
>;

export interface SerieNumberHeaderCellProps {
  propertyName: SerieNumberOrWeekProperty;
  fieldDefinition: HeaderFieldDef;
  serie: ProductSerie;
  isEditing: boolean;
  applyCommand: Dispatch<AllSeriesCommands>;
}

export function SerieNumberHeaderCell({
  propertyName,
  serie,
  isEditing,
  fieldDefinition,
  applyCommand,
}: SerieNumberHeaderCellProps) {
  const { t, formatNumber } = useTranslation();
  const { rowId: serieRowId, parentItineraryId } = serie;
  const value = serie[propertyName];
  const isPercent = fieldDefinition.type === 'percent';
  const isWeek = fieldDefinition.type === 'week';

  const [parentRef, setParentRef] = useState<HTMLElement>(null);
  return (
    <Container
      key={propertyName}
      isEditing={isEditing}
      onClick={() =>
        applyCommand({
          type: 'setEditedSeriesCell',
          parentItineraryId,
          serieRowId,
          weekKey: null,
          editedProperty: propertyName,
        })
      }
      ref={setParentRef}
    >
      {isEditing ? (
        <EditedSerieNumberHeaderCell
          applyCommand={applyCommand}
          serie={serie}
          value={value}
          propertyName={propertyName}
          isPercent={isPercent}
          isWeek={isWeek}
          parentRef={parentRef}
        />
      ) : (
        <div className="content">
          {seriesColumnDefinitions[propertyName].value({
            formatNumber,
            serieOrProduct: serie,
            t,
            value: value as number & Week,
          })}
        </div>
      )}
    </Container>
  );
}

interface EditedSerieNumberHeaderCellProps {
  serie: ProductSerie;
  propertyName: SerieNumberOrWeekProperty;
  value: number | Week;
  isPercent: boolean;
  isWeek: boolean;
  applyCommand: Dispatch<AllSeriesCommands>;
  parentRef: HTMLElement;
}
function EditedSerieNumberHeaderCell({
  serie,
  propertyName,
  value,
  isPercent,
  isWeek,
  applyCommand,
  parentRef,
}: EditedSerieNumberHeaderCellProps) {
  const { childItineraryId, parentItineraryId, rowId, productRowId } = serie;
  const { selectedParentProducts } = useProjectBaseState();
  const itineraryYield =
    propertyName === 'expectedYield' &&
    (() => {
      const parentProduct = selectedParentProducts[parentItineraryId];
      const childProduct = parentProduct.selectedChildrenByRowId[productRowId];
      return childProduct.itineraryYield || parentProduct.itineraryYield;
    })();
  const { quitEdition, editProperty } = useMemo(() => {
    return {
      quitEdition: () =>
        applyCommand({
          type: 'setEditedSeriesCell',
          parentItineraryId: null,
          serieRowId: null,
          editedProperty: null,
          weekKey: null,
        }),
      editProperty: (value: number) =>
        applyCommand({
          type: 'editSerieProperty',
          childItineraryId,
          parentItineraryId,
          productRowId,
          serieRowId: rowId,
          propertyName,
          value,
        }),
    };
  }, [applyCommand, childItineraryId, parentItineraryId, productRowId, rowId, propertyName]);

  return (
    <>
      <EditedCell
        value={value || null}
        quitEdition={quitEdition}
        editProperty={editProperty}
        isPercent={isPercent}
        isWeek={isWeek}
      >
        {itineraryYield && (
          <YieldSelectionMenu
            parentRef={parentRef}
            editProperty={editProperty}
            quitEdition={quitEdition}
            itineraryYield={itineraryYield}
          />
        )}
      </EditedCell>
    </>
  );
}

const yieldValues = ['min', 'value', 'max'] as const;
function YieldSelectionMenu({
  editProperty,
  quitEdition,
  itineraryYield,
  parentRef,
}: {
  itineraryYield: Variable<YieldUnitEnum>;
  editProperty: (value: number) => void;
  quitEdition: () => void;
  parentRef: HTMLElement;
}) {
  const { t, formatNumber } = useTranslation();
  return (
    <HeaderCellMenu parentRef={parentRef}>
      {yieldValues
        .map((key) => [key, itineraryYield[key]] as const)
        .filter(([, value]) => value != null)
        .map(([yieldKey, numericValue]) => (
          <div
            key={yieldKey}
            onClick={(e) => {
              e.stopPropagation();
              editProperty(numericValue);
              quitEdition();
            }}
          >
            <div>{t('series:header-table.serie-sub-row.yield-cell.' + yieldKey)}</div>
            <div>{formatNumber(numericValue, 2, 2)}</div>
          </div>
        ))}
    </HeaderCellMenu>
  );
}
