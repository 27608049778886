// based on https://github.com/remix-run/react-router/issues/8139#issuecomment-1023105785
import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

/**
 * @source https://github.com/remix-run/react-router/issues/8139#issuecomment-1021457943
 */
export function usePrompt(message: string, when: boolean, rootPath: string) {
  const history = useHistory();
  const unblockRef = useRef<() => string | false | void>(null);
  useEffect(() => {
    if (when) {
      const onQuit = (e: BeforeUnloadEvent) => {
        e.preventDefault();
        e.returnValue = message;
        return message;
      };
      window.addEventListener('beforeunload', onQuit);

      const unblock = history.block((location) => {
        if (location.pathname === rootPath || !location.pathname.startsWith(rootPath)) {
          return message;
        }
      });
      unblockRef.current = unblock;

      return () => {
        window.removeEventListener('beforeunload', onQuit);
        unblock();
      };
    } else {
      if (unblockRef.current) {
        unblockRef.current();
      }
    }
  }, [when, history, message, rootPath]);
}
