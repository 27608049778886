import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache, PossibleTypesMap } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import config from '../../config';
import introspectionQueryResultData from '../generated/graphql.schema.json';
import { customRequestFetch } from './customFetch';

export function useApolloClient(): { client: ApolloClient<any> | undefined } {
  const cache = new InMemoryCache({
    possibleTypes: (introspectionQueryResultData as any).__schema.types.reduce((possibleTypes, type) => {
      if (type.possibleTypes) {
        possibleTypes[type.name] = type.possibleTypes.map((subType) => subType.name);
      }
      return possibleTypes;
    }, {} as PossibleTypesMap),
  });

  const httpLink = createHttpLink({
    uri: config.API_URL + '/graphql',
    fetch: customRequestFetch,
  });

  const authLink = setContext(async (_, { headers }) => {
    // get the authentication token from local storage or elsewhere if it exists
    const token = localStorage.getItem(config.AUTH_TOKEN);
    if (token) {
      return {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      };
    }
    return { headers };
  });

  const client = new ApolloClient({
    link: ApolloLink.from([authLink, httpLink]),
    credentials: 'include',
    cache,
  });

  return {
    client,
  };
}
