import { ToHandlers } from '@elzeard/common-components';
import { BaseOutlet, BaseProjectPageState, ParentProduct, ProjectPageState } from '../state';
import { OutletCommands } from './commands';
import { editProductProperty } from './editProductProperty';
import { updateOutletsState } from './state-build';
import { OutletProduct, OutletsPageState } from './state-full';

function buildFullState(state: ProjectPageState<OutletsPageState>) {
  return {
    ...state,
    ...updateOutletsState(state),
  };
}

export const outletCommandHandler: ToHandlers<ProjectPageState<OutletsPageState>, OutletCommands> = {
  createOutlet(previousState, command) {
    const { name, distributionCircuit, priceLevel } = command.value;
    const newOutlet: BaseOutlet = {
      rowId: name + '-' + Math.random(),
      outletId: null,
      clientId: null,
      name,
      defaultPrices: command.defaultPrices,
      distributionCircuit,
      priceLevel,
      weeklySalesTarget: {},
      isUpdated: true,
      isDisabled: false,
    };
    return buildFullState({
      ...previousState,
      outletsByRowId: {
        ...previousState.outletsByRowId,
        [newOutlet.rowId]: newOutlet,
      },
      actions: [...previousState.actions, command],
    });
  },
  editOutletSettings(previousState, command) {
    const { name, distributionCircuit, priceLevel } = command.value;
    const initialOutlet = previousState.outletsByRowId[command.outletRowId];
    const updatedOutlet: BaseOutlet = {
      ...initialOutlet,
      name,
      defaultPrices: command.defaultPrices,
      distributionCircuit,
      priceLevel,
      isUpdated: true,
    };
    return buildFullState({
      ...previousState,
      outletsByRowId: {
        ...previousState.outletsByRowId,
        [updatedOutlet.rowId]: updatedOutlet,
      },
      actions: [...previousState.actions, command],
    });
  },
  toggleExpandRow(previousState, command) {
    const previousValue = previousState.expandedRows[command.rowId] || false;
    return {
      ...previousState,
      expandedRows: {
        ...previousState.expandedRows,
        [command.rowId]: !previousValue,
      },
    };
  },
  setEditedCell(previousState, command) {
    const { type, ...editedCell } = command;
    if (
      (!editedCell && !previousState.editedCell) ||
      (editedCell &&
        previousState.editedCell &&
        editedCell.outletRowId === previousState.editedCell.outletRowId &&
        editedCell.parentCropItineraryId === previousState.editedCell.parentCropItineraryId &&
        editedCell.editedProperty === previousState.editedCell.editedProperty &&
        editedCell.weekKey === previousState.editedCell.weekKey)
    ) {
      return previousState;
    }
    return {
      ...previousState,
      editedCell: editedCell.outletRowId ? editedCell : null,
    };
  },
  editProductProperty(previousState, command) {
    const { outletRowId, parentItineraryId: cropItineraryId, propertyName, value, weekKey } = command;
    const initialFullOutlet = previousState.outlets.find((outlet) => outlet.rowId === outletRowId);
    const initialOutletProduct: OutletProduct = initialFullOutlet.products.find(
      (product) => product.parentCropItineraryId === cropItineraryId,
    );

    const { updatedOutletProduct, updatedSelectedParentProducts } = editProductProperty({
      outletProduct: initialOutletProduct,
      selectedParentProducts: previousState.selectedParentProducts,
      propertyName,
      weekKey,
      value: value as number,
      outlet: initialFullOutlet,
    });
    if (updatedOutletProduct === initialOutletProduct) {
      return previousState;
    }
    // TODO optimize: we need to recompute only the all outlets object and, in the edited outlet, recompute the sums and replace the full product
    return buildFullState({
      ...previousState,
      editedCell: null,
      selectedParentProducts: updatedSelectedParentProducts,
      actions: [...previousState.actions, command],
    });
  },
  editTargetSales(previousState, command) {
    const { outletRowId, weekKey, value } = command;
    const initialOutlet = previousState.outletsByRowId[outletRowId];
    const initialValue = initialOutlet.weeklySalesTarget[weekKey];
    if (initialValue === value) {
      return previousState;
    }
    const updatedOutlet: BaseOutlet = {
      ...initialOutlet,
      isUpdated: true,
      weeklySalesTarget: {
        ...initialOutlet.weeklySalesTarget,
        [weekKey]: value,
      },
    };
    return buildFullState({
      ...previousState,
      outletsByRowId: {
        ...previousState.outletsByRowId,
        [updatedOutlet.rowId]: updatedOutlet,
      },
    });
  },
  changeMainDisplayOption(previousState, command) {
    const { value } = command;
    if (previousState.mainDisplayOption === value) {
      return previousState;
    }
    return buildFullState({
      ...previousState,
      mainDisplayOption: value,
    });
  },
  toggleDisplaySalesTarget(previousState, command) {
    return buildFullState({
      ...previousState,
      displaySalesTarget: !previousState.displaySalesTarget,
    });
  },
  setVisibleColumns(previousState, command) {
    return {
      ...previousState,
      visibleColumns: command.value,
    };
  },
  askConfirmation(previousState, { command, title, details }) {
    if (!previousState.confirmationModal && !command) {
      return previousState;
    }
    if (previousState.confirmationModal?.command === command) {
      return previousState;
    }
    return {
      ...previousState,
      confirmationModal: command ? { command, title, details } : null,
    };
  },
  disableOutlet(previousState, command) {
    return buildFullState({
      ...previousState,
      outletsByRowId: {
        ...previousState.outletsByRowId,
        [command.outletRowId]: {
          ...previousState.outletsByRowId[command.outletRowId],
          isUpdated: true,
          isDisabled: true,
        },
      },
      actions: [...previousState.actions, command],
    });
  },
  removeOutlet(previousState, command) {
    if (!previousState.outletsByRowId[command.outletRowId]) {
      return previousState;
    }
    const updatedOutletsById = {
      ...previousState.outletsByRowId,
    };
    const deletedOutlet = updatedOutletsById[command.outletRowId];
    delete updatedOutletsById[command.outletRowId];
    const updatedProducts: BaseProjectPageState['selectedParentProducts'] = Object.fromEntries(
      Object.entries(previousState.selectedParentProducts).map(([parentItineraryId, parentProduct]) => {
        let isUpdated = false;
        const updatedProduct: ParentProduct = {
          ...parentProduct,
          isUpdated: true,
          productionNeedsByOutletRowId: { ...parentProduct.productionNeedsByOutletRowId },
          removedNeeds: [...parentProduct.removedNeeds],
        };
        const deletedNeed = updatedProduct.productionNeedsByOutletRowId[command.outletRowId];
        if (deletedNeed) {
          isUpdated = true;
          updatedProduct.removedNeeds.push(deletedNeed);
          delete updatedProduct.productionNeedsByOutletRowId[command.outletRowId];
        }
        return [parentItineraryId, isUpdated ? updatedProduct : parentProduct];
      }),
    );
    return buildFullState({
      ...previousState,
      outletsByRowId: updatedOutletsById,
      removedOutlets: [...previousState.removedOutlets, deletedOutlet],
      selectedParentProducts: updatedProducts,
      confirmationModal: null,
      actions: [...previousState.actions, command],
    });
  },
  editOutletContent(previousState, command) {
    return buildFullState({
      ...previousState,
      editedOutletRowId: command.outletRowId,
    });
  },
  setSelectedProducts(previousState, command) {
    const { selectedProducts } = command;
    const { selectedParentProducts, otherPossibleParentProducts, actions } = previousState;

    const { updatedSelectedParentProducts, updatedOtherPossibleParentProducts } = selectedProducts.reduce(
      ({ updatedSelectedParentProducts, updatedOtherPossibleParentProducts }, productSelection) => {
        if (productSelection.isSelected) {
          if (selectedParentProducts[productSelection.parentCropItineraryId]) {
            // was already selected
            updatedSelectedParentProducts[productSelection.parentCropItineraryId] =
              selectedParentProducts[productSelection.parentCropItineraryId];
          } else {
            // select
            const parentProduct = otherPossibleParentProducts[productSelection.parentCropItineraryId];
            updatedSelectedParentProducts[productSelection.parentCropItineraryId] = {
              ...parentProduct,
              isUpdated: true,
            };
          }
        } else if (otherPossibleParentProducts[productSelection.parentCropItineraryId]) {
          // was already not selected
          updatedOtherPossibleParentProducts[productSelection.parentCropItineraryId] =
            otherPossibleParentProducts[productSelection.parentCropItineraryId];
        } else {
          // unselect
          const parentProduct = selectedParentProducts[productSelection.parentCropItineraryId];
          updatedOtherPossibleParentProducts[productSelection.parentCropItineraryId] = {
            ...parentProduct,
            isUpdated: true,
          };
        }
        return { updatedSelectedParentProducts, updatedOtherPossibleParentProducts };
      },
      {
        updatedSelectedParentProducts: {} as Record<string, ParentProduct>,
        updatedOtherPossibleParentProducts: {} as Record<string, ParentProduct>,
      },
    );

    return buildFullState({
      ...previousState,
      actions: [...actions, command],
      selectedParentProducts: updatedSelectedParentProducts,
      otherPossibleParentProducts: updatedOtherPossibleParentProducts,
    });
  },
};
