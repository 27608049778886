import { useBasePositionPlanningApplyCommand, useBasePositionPlanningState } from '@elzeard/common-planning';
import { Dispatch } from 'react';
import { ProjectPageState } from '../state';
import { AllSeriesCommands } from './commands';
import { SeriesPageState } from './state-full';

export function useSeriesState() {
  return useBasePositionPlanningState() as ProjectPageState<SeriesPageState>;
}

export function useSeriesCommands() {
  return useBasePositionPlanningApplyCommand() as Dispatch<AllSeriesCommands>;
}
