import React, { ComponentType, ReactNode } from 'react';
import styled from 'styled-components';

interface SvgProps {
  value?: ComponentType;
  initialSize?: number;
  size?: number;
  children?: ReactNode;
  className?: string;
}
interface SvgContainerProps {
  initialSize: number;
  $size: number;
}

const SVG_SIZE = 16;

const StyledContainer = styled.div<SvgContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => props.$size}px;
  height: ${(props) => props.$size}px;
`;

const InnerContainer = styled.div<SvgContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  transform: ${(props) => {
    const ratio = props.$size / props.initialSize;
    return `scale(${ratio});`;
  }};
`;

export function Svg({ value, initialSize = SVG_SIZE, size = SVG_SIZE, children, className }: SvgProps) {
  const Value = value;
  return (
    <StyledContainer
      initialSize={initialSize}
      $size={size}
      className={className}
    >
      <InnerContainer
        initialSize={initialSize}
        $size={size}
      >
        {value ? <Value /> : children}
      </InnerContainer>
    </StyledContainer>
  );
}
