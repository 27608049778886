export const routes = {
  root: '/',
  login: {
    root: '/login',
    createAccount: '/login/create',
    forgotPassword: '/login/forgotten-password',
    resetPassword: '/login/reset-password',
    callbackAccountCreation: '/login/callback-create',
  },
  project: {
    list: '/project',
    createModal: '/project/new',
    editModal: '/project/:farmId/edit',
    removeModal: '/project/:farmId/remove',
    selected: '/project/:farmId',
    details: {
      root: '/project/:farmId/details',
      outlet: {
        root: '/project/:farmId/details/outlet',
      },
      series: {
        root: '/project/:farmId/details/series',
      },
      position: {
        root: '/project/:farmId/details/position',
      },
    },
  },
};
