import styled from 'styled-components';
import { colorStyle } from '../../../common/style/colors';
import { getTextStyle } from '../../../common/style/text';
import { firstHeaderCell, headerCell } from '../../table/style';
import { firstHeaderColumnWidth, seriesHeights } from './style';

interface ProductCellProps {
  isEditingSingleProduct: boolean;
}
export const ProductNameCell = styled.th<ProductCellProps>`
  ${firstHeaderCell}
  height: ${seriesHeights.productRow};
  color: ${colorStyle.greys['1-black']};
  text-align: left;
  .title {
    ${getTextStyle(14, 'semibold')}
    max-width: ${({ isEditingSingleProduct }) =>
      isEditingSingleProduct ? firstHeaderColumnWidth.editedProductTitle : firstHeaderColumnWidth.allProductsTitle};
  }
  ${({ isEditingSingleProduct }) =>
    isEditingSingleProduct
      ? `
      width: ${firstHeaderColumnWidth.editedProduct};
      .arrow-box {
        display: none;
      }
    `
      : `
      width: ${firstHeaderColumnWidth.allProducts};
    `}
`;

export const OutletNeedsHeader = styled.th<ProductCellProps>`
  ${headerCell}
  height: ${seriesHeights.targetRow};
  padding-left: 32px;
  ${getTextStyle(14, 'medium')}
  width: ${({ isEditingSingleProduct }) =>
    isEditingSingleProduct ? firstHeaderColumnWidth.editedProduct : firstHeaderColumnWidth.allProducts};
  .content {
    display: flex;
    width: 100%;
  }
  .arrow {
    margin-right: 7px;
  }
`;

export const SerieNameCell = styled.td<ProductCellProps>`
  height: ${seriesHeights.seriesRow};
  ${firstHeaderCell}
  border-bottom: 0;
  width: ${({ isEditingSingleProduct }) =>
    isEditingSingleProduct ? firstHeaderColumnWidth.editedProduct : firstHeaderColumnWidth.allProducts};
  padding-left: 20px;
  .title {
    ${getTextStyle(14, 'medium')}
  }
`;
export const SerieValueCell = styled.td`
  height: ${seriesHeights.seriesRow};
  box-sizing: border-box;
  border-right: 1px solid ${colorStyle.greys['7']};
  text-align: left;
  ${getTextStyle(14, 'regular')}
  color: ${colorStyle.greys['4-defaultIcon']};
  padding: 0 4px;
`;

export const TitleCell = styled.th`
  ${headerCell}
  .cell-content {
    display: flex;
    align-items: center;
    padding: 0 16px;
  }
  .title {
    flex: auto;
    ${getTextStyle(16, 'medium')}
    text-transform: uppercase;
    color: ${colorStyle.greys['1-black']};
  }
  .action {
    flex: none;
  }
`;
