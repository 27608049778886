import React, { MutableRefObject } from 'react';
import { FieldErrors, FieldPath, FieldValues, Path, PathValue, UseFormRegister } from 'react-hook-form';
import { BaseFieldProps } from './types';

export type UncontrolledHookedFieldProps<
  TFieldName extends FieldPath<Form>,
  TFieldValue extends PathValue<Form, TFieldName>,
  Form extends FieldValues,
  Props extends BaseFieldProps<TFieldValue>,
> = Props & {
  fieldName: TFieldName;
  register: UseFormRegister<Form>;
  forwardedRef?: MutableRefObject<(props: Props) => void>;
  errors?: FieldErrors<Form>;
};

export function UncontrolledHookedField<
  TFieldName extends FieldPath<Form>,
  TFieldValue extends PathValue<Form, TFieldName>,
  Form extends FieldValues,
  Props extends BaseFieldProps<TFieldValue>,
>({
  Child,
  fieldName,
  register,
  onBlur,
  value,
  errors,
  ...inputProps
}: UncontrolledHookedFieldProps<TFieldName, TFieldValue, Form, Props> & {
  Child: React.ComponentType<BaseFieldProps<TFieldValue>>;
}) {
  return (
    <Child
      {...{
        error: errors?.[fieldName as Path<Form>]?.message,
        ...inputProps,
        ...register(fieldName as Path<Form>, {
          required: inputProps.required,
          disabled: inputProps.disabled,
          onBlur,
          value: value as PathValue<Form, Path<Form>>,
        }),
      }}
    />
  );
}
