import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
html, 
body, 
body > div#root {
  width: 100%;
  height: 100%;
}
*{
  font-family: "Montserrat", sans-serif;
}
body {
  margin: 0;
  outline: 0;
}
a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}
button {
  background-color: transparent;
  color: inherit;
  border: 0;
  padding: 0;
  margin: 0;
  cursor: pointer;
}
`;

export default GlobalStyle;
