import { MonthInterval, PlantFamilyColors, Without } from '@elzeard/common-components';
import { BedPositions, NumberMap, PlanningSerie } from '@elzeard/common-planning';
import { CultureModeEnum, HarvestUnitEnum, LengthUnitEnum, Variable, YieldUnitEnum } from '@elzeard/shared-dimensions';
import { OutletDefaultPrice, Plant_TimeDuration } from '../common/generated/graphql';
import { ProductAvailability } from './common/ProductAvailability';
import { ChildItinerary, ParentItinerary, Rotation } from './useCropItineraries';

export enum DistributionCircuit {
  Short = 'short',
  Long = 'long',
  Custom = 'custom',
}

export enum PriceLevel {
  Low = 'low',
  Medium = 'medium',
  High = 'high',
}

export interface BaseOutlet {
  /** Equals to the client ID when the outlet exists in the DB */
  rowId: string;
  outletId: string | null;
  clientId: string | null;
  // clientCategory: ClientCategoryEnum;
  name: string;
  isUpdated: boolean;
  isDisabled: boolean;
  weeklySalesTarget: NumberMap;
  defaultPrices: {
    [parentItineraryId: string]: OutletDefaultPrice;
  };
  distributionCircuit: DistributionCircuit;
  priceLevel: PriceLevel;
}

export interface BaseProjectProduct {
  /** An parent crop itinerary, aggregating itineraries each matching a single PlannedSerie/PlannedProduct */
  parentCropItineraryId: string;
  name: string;
  plantId: string;
  plantName: string;
  plantFamily: string;
  plantFamilyColors: PlantFamilyColors;
  quantityUnit: HarvestUnitEnum;
  itineraryYield: Variable<YieldUnitEnum>;
  rotation: { interval: Plant_TimeDuration }[];
  itkRotation: Rotation;
  isUpdated: boolean;
}
export interface BaseParentProduct extends BaseProjectProduct {
  children: Array<ChildItinerary>;
  prices: ParentItinerary['prices'];
}

export type CultureMode = CultureModeEnum | 'PurchaseResale' | 'Mixed';

export interface ChildProduct extends BaseProjectProduct {
  /**
   * Equals to productId, or childCropItineraryId, or parentCropItineraryId + random string
   */
  rowId: string;
  /**
   * null if custom product
   */
  childCropItineraryId: string;
  // isDisabled: boolean; // TODO how to store it ?
  /** ID of a PlannedProduct in the DB */
  productId: string | null;
  cultureMode: CultureMode;

  /** Not always a continuous period (there may be gaps), composed from the harvest periods of the PlannedSeries (if already saved) or the children CropItinerary (if new product) */
  harvestPeriods: Array<ProductAvailability>;
  storagePeriods: Array<ProductAvailability>;
  // maturationWeeks: number;
  /**
   * 1 or 2 series (when the availability period overlaps the project period)
   */
  series: Array<PlanningSerie>;
  serieToRemoveId: string;
  /**
   * equals to series when in otherPossibleChildren (used to restore the series when a child product is unselected)
   */
  itinerarySeries: Array<PlanningSerie>;
}

export interface PurchaseResaleChild {
  parentCropItineraryId: string;
  productId: string | null;
  isUpdated: boolean;
  isToBeDeleted: boolean;
  // we cannot handle any link to an outlet (aka client), so there is no use of anything about prices
  productionNeedId: string | null;
  weeklyNeeds: NumberMap;
}

export interface ParentProduct extends BaseProjectProduct {
  productId: string | null;
  selectedChildrenByRowId: Record<string, ChildProduct>;
  otherPossibleChildrenByChildItineraryId: Record<string, ChildProduct>;
  purchaseResale: PurchaseResaleChild | null;
  productionNeedsByOutletRowId: Record<string, OutletProductionNeeds>;
  removedNeeds: Array<OutletProductionNeeds>;
  prices: ParentItinerary['prices'];
}
export interface OutletProductionNeeds {
  /** ID of a ProductionNeed in the DB */
  productionNeedId: string | null;
  weeklyNeeds: NumberMap;
  isUpdated: boolean;

  /** Duplicated on each ProductionNeed */
  defaultUnitPrice: number;
  weeklyPrices: NumberMap;
}

export interface BasePlotWithoutBeds {
  plotId: string;
  plotName: string;
  surface: number;
  width: number;
  length: number;
  cultureMode: CultureModeEnum;
}
export interface BasePlotWithBeds extends BasePlotWithoutBeds {
  numberOfBeds: number;
  bedsWidth: Variable<LengthUnitEnum>;
  footpathWidth: Variable<LengthUnitEnum>;
  beds: Without<BedPositions, 'positions'>[];
}
export type BasePlot = BasePlotWithBeds | BasePlotWithoutBeds;

export interface BaseProjectPageState<S = unknown> {
  farmId: string;
  outletsByRowId: Record<string, BaseOutlet>;
  removedOutlets: Array<BaseOutlet>;
  selectedParentProducts: Record<string, ParentProduct>;
  otherPossibleParentProducts: Record<string, ParentProduct>;
  basePlots: BasePlot[];
  time: MonthInterval;
  isSaving: boolean;
  error?: string;
  actions: any[]; //Array<AllProjectCommands>;
  initializePageState: (baseState: BaseProjectPageState<unknown>, previousState: ProjectPageState<S>) => S;
  /** unused for now */
  updatePageState: (baseState: ProjectPageState<S>) => ProjectPageState<S>;
}

export type ProjectPageState<S> = BaseProjectPageState<S> & S;
