import React, {
  MouseEventHandler,
  MutableRefObject,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useRef,
  useState,
} from 'react';
import { usePopper } from 'react-popper';
import { useClickAway } from 'react-use';
import styled, { css } from 'styled-components';
import { ReactComponent as MoreChoicesIcon } from '../../../assets/icon-more-choices.svg';
import { Button } from '../../../common/components/Button';
import { ButtonVariant } from '../../../common/components/Button_style';
import { Svg } from '../../../common/components/Svg';
import { colorStyle, popupShadow } from '../../../common/style/colors';
import { getTextStyle } from '../../../common/style/text';

export type MenuSize = 'M' | 'L';

const ButtonContainer = styled.div`
  position: relative;
`;
const MenuContainer = styled.div<{
  $size: MenuSize;
  $outlined?: boolean;
}>`
  position: absolute;
  z-index: 5;
  background-color: ${colorStyle.greys['9-white']};
  ${({ $outlined }) =>
    $outlined
      ? css`
          border: 2px solid ${colorStyle.cta2['1']};
          ${popupShadow}
        `
      : css`
          border: 1px solid ${colorStyle.greys['5']};
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        `}
  border-radius: 4px;
  padding: 8px 0;
  & > * {
    height: 36px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    gap: 16px;
    cursor: pointer;
    ${({ $size }) => getTextStyle($size === 'L' ? 16 : 14, 'medium')}
    color: ${colorStyle.greys['1-black']};
    &:hover {
      background-color: ${colorStyle.cta2['6']};
    }
  }
`;

const defaultLabel = (
  <Svg
    value={MoreChoicesIcon}
    size={12}
  />
);

export function HeaderCellMenuButton({
  children,
  className,
  label = defaultLabel,
  variant = 'primary',
  isFilled,
}: PropsWithChildren<{
  className?: string;
  variant?: ButtonVariant;
  label?: ReactNode;
  isFilled?: boolean;
}>) {
  const [isOpen, setIsOpen] = useState(false);
  const close = useCallback(() => setIsOpen(false), []);
  const containerRef = useRef();
  useClickAway(containerRef, close);

  const [triggerRef, setTriggerRef] = useState<HTMLElement>(null);
  return (
    <ButtonContainer
      ref={containerRef}
      className={className}
    >
      <Button
        variant={variant}
        sizing="S"
        isFilled={isFilled}
        forwardedRef={setTriggerRef as unknown as MutableRefObject<HTMLElement>}
        onClick={() => setIsOpen((open) => !open)}
      >
        {label}
      </Button>
      {isOpen && (
        <HeaderCellMenu
          parentRef={triggerRef}
          onClick={() => setIsOpen(false)}
          menuSize="L"
          outlined
        >
          {children}
        </HeaderCellMenu>
      )}
    </ButtonContainer>
  );
}

export function HeaderCellMenu({
  children,
  className,
  onClick,
  parentRef,
  menuSize = 'M',
  outlined,
}: PropsWithChildren<{
  className?: string;
  parentRef: HTMLElement;
  onClick?: MouseEventHandler;
  menuSize?: MenuSize;
  outlined?: boolean;
}>) {
  const [menuRef, setMenuRef] = useState<HTMLElement>(null);
  const { attributes, styles } = usePopper(parentRef, menuRef, { placement: 'bottom-start' });
  return (
    <MenuContainer
      $outlined={outlined}
      $size={menuSize}
      className={className}
      ref={setMenuRef}
      style={styles.popper}
      onClick={onClick}
      {...attributes.popper}
    >
      {children}
    </MenuContainer>
  );
}
