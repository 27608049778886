import React, { ComponentProps } from 'react';
import styled from 'styled-components';
import { CenteredModal } from '../../../common/components/modal/CenteredModal';
import { planningSpacetimeStateAdapter } from '../state-adapter-spacetime';

const StyledModal = styled(CenteredModal)`
  .description {
    margin: 0 30px 10px;
  }
  .options {
    margin: 0 30px;

    label {
      margin-top: 8px;
      margin-left: 8px;
    }
  }
`;

export function NotEnoughSurfaceModal({
  content,
  onClose,
  onSave,
  title,
  disabled,
}: ComponentProps<typeof planningSpacetimeStateAdapter['NotEnoughSurfaceModal']['ModalComponent']>) {
  return (
    <StyledModal
      title={title}
      onCancel={onClose}
      onConfirm={onSave}
      disabled={disabled}
    >
      {content}
    </StyledModal>
  );
}
