import { useTranslation } from '@elzeard/common-components';
import React, { FC } from 'react';
import styled, { keyframes } from 'styled-components';
import { ReactComponent as SpinnerFormSvg } from '../../assets/spinner-form.svg';
import { ReactComponent as SpinnerPageSvg } from '../../assets/spinner-page.svg';
import { colorStyle } from '../style/colors';
import { getTextStyle } from '../style/text';
import { Svg } from './Svg';

export interface ISpinnerProps {
  size?: number;
  textI18nKey?: string;
  className?: string;
}

const spinnerAnimation = keyframes`
 from { transform: rotate(0) }
 to { transform: rotate(360deg) }
`;

const SpinnerFormContainer = styled.div`
  svg {
    animation: ${spinnerAnimation} 1s linear infinite;
  }
`;

export const Spinner: FC<ISpinnerProps> = ({ className, size = 16, textI18nKey = 'common:spinner.form' }) => {
  const { t } = useTranslation();
  return (
    <SpinnerFormContainer className={className}>
      {textI18nKey && <div className="label">{t(textI18nKey)}</div>}
      <Svg
        value={SpinnerFormSvg}
        size={size}
      />
    </SpinnerFormContainer>
  );
};

const FullPageContainer = styled.div`
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colorStyle.greys['8']};
`;

const SpinnerPageContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  color: ${colorStyle.greys['1-black']};
  gap: 1ch;
  svg {
    animation: ${spinnerAnimation} 2s steps(4) infinite;
  }

  .label {
    width: 120px;
    margin-top: 48px;
    text-align: center;
    ${getTextStyle(16, 'semibold')}
  }
`;

export const FullPageSpinner: FC<ISpinnerProps> = ({ className, size = 126, textI18nKey = 'common:spinner.page' }) => {
  const { t } = useTranslation();
  return (
    <FullPageContainer className={className}>
      <SpinnerPageContainer>
        <Svg
          value={SpinnerPageSvg}
          size={size}
        />
        {textI18nKey && <div className="label">{t(textI18nKey)}</div>}
      </SpinnerPageContainer>
    </FullPageContainer>
  );
};

export default Spinner;
