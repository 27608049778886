import React from 'react';
import styled from 'styled-components';
import { Svg } from '../../../common/components/Svg';
import { colorStyle } from '../../../common/style/colors';
import { getTextStyle } from '../../../common/style/text';
import { cultureModeIcons } from '../../common/icons';
import { usePositionState } from '../state-context';
import { Culture } from '../state-full';

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  .product-name {
    ${getTextStyle(14, 'semibold')}
    color: ${colorStyle.greys['1-black']};
  }
`;

export function CultureBlockTitle({ culture }: { culture: Culture }) {
  // const { t } = useTranslation();
  const state = usePositionState();
  const parentProduct = state.selectedParentProducts[culture.parentCropItineraryId];
  return (
    <StyledContainer>
      <div className="product-name">{parentProduct.name}</div>
      <div className="culture-mode">
        <Svg value={cultureModeIcons[culture.cultureMode]} />
      </div>
    </StyledContainer>
  );
}
