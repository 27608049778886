import React, { ComponentType, SVGAttributes } from 'react';
import styled, { css } from 'styled-components';
import { colorStyle } from '../../../style/colors';
import { getTextStyle } from '../../../style/text';
import { Svg } from '../../Svg';
import { BaseFieldProps } from './types';

export type Icons = {
  checked: ComponentType<SVGAttributes<any>>;
  unchecked: ComponentType<SVGAttributes<any>>;
};

export type CheckboxVariant = 'border' | 'default';

const Container = styled.label<{ $variant: CheckboxVariant; $checked: boolean | undefined; $disabled: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  box-sizing: border-box;
  & .checkbox-label {
    ${getTextStyle(14, 'regular')}
  }
  ${({ $checked }) =>
    $checked
      ? css`
          & .checkbox-label {
            color: ${colorStyle.greys['1']};
          }
          & .checkbox-icon {
            color: ${colorStyle.cta2['1']};
          }
        `
      : css`
          & .checkbox-label {
            color: ${colorStyle.greys['5']};
          }
          & .checkbox-icon {
            color: ${colorStyle.greys['4-defaultIcon']};
          }
        `}
  ${({ $variant, $checked }) =>
    $variant === 'border' &&
    css`
      padding: 8px 16px;
      background: ${colorStyle.greys['9-white']};
      border-radius: 4px;
      ${$checked
        ? css`
            border: 1px solid ${colorStyle.cta2['1']};
          `
        : css`
            border: 1px solid ${colorStyle.greys['5']};
          `}
      &:hover {
        background: ${colorStyle.cta2['5']};
        & .checkbox-label {
          color: ${colorStyle.greys['2']};
        }
        & .checkbox-icon {
          color: ${colorStyle.greys['1-black']};
        }
      }
    `}
`;

export interface BaseCheckboxProps extends BaseFieldProps<boolean> {
  icons: Icons;
  variant?: CheckboxVariant;
  // topInfo?: ReactNode;
  // bottomInfo?: ReactNode;
}
export type CheckboxProps = Omit<BaseCheckboxProps, 'icons'>;

export function BaseCheckbox({
  label,
  // TODO error,
  // topInfo,
  // bottomInfo,
  icons,
  // onBlur,
  // TODO required,
  value,
  disabled = false,
  onValueChange,
  className,
  variant = 'default',
}: BaseCheckboxProps) {
  return (
    <Container
      onClick={() => !disabled && onValueChange(!value)}
      $disabled={disabled}
      $checked={value}
      $variant={variant}
      className={className}
    >
      <Svg
        className="checkbox-icon"
        value={icons[value ? 'checked' : 'unchecked']}
      />
      <div className="checkbox-label">{label}</div>
    </Container>
  );
}
