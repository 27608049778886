import { DropBedPositionSerie, DropNoBedPositionSerie, SpacetimeStateAdapter } from '@elzeard/common-planning';
import { ImplantationModeEnum } from '@elzeard/shared-dimensions';
import { Radio } from '../../common/components/field/Radio';
import { ProjectPageState } from '../state';
import { AllPositionPageCommands } from './commands';
import { NotEnoughSurfaceModal } from './components/NotEnoughSurfaceModal';
import { PlotSerie } from './components/PlotSerie';
import { PlotSerieSelectionContent } from './components/PlotSerieSelectionContent';
import { isCultureMatching } from './edition-adapter';
import { CultureCoordinates, PositionPageState, PositionSerie } from './state-full';
import { bedNumberWidth } from './style';

export const planningSpacetimeStateAdapter: SpacetimeStateAdapter<
  CultureCoordinates,
  PositionSerie,
  ProjectPageState<PositionPageState>,
  AllPositionPageCommands
> = {
  bedNumberWidth,
  getWeekWidth(state) {
    return state.weekWidth;
  },
  getSelectedSerie: ({ seriesById, cultureSelection, plotSerieSelection }) => {
    const selection = cultureSelection || plotSerieSelection;
    return selection && seriesById[selection.serieId];
  },
  isSerieSelected: (serie, plotSerieSelection) => {
    const plannedSerie = serie as PositionSerie;
    return (
      plotSerieSelection &&
      plotSerieSelection.parentCropItineraryId === plannedSerie.parentCropItineraryId &&
      plotSerieSelection.cultureMode === plannedSerie.cultureMode &&
      plotSerieSelection.serieIndex === plannedSerie.serieIndex
    );
  },
  isImplantationCompatible(plot, culture) {
    return culture.implantationMode &&
      (culture.implantationMode === ImplantationModeEnum.FreeAreaWithSimpleInterrow ||
        culture.implantationMode === ImplantationModeEnum.FreeArea)
      ? !('beds' in plot)
      : true;
  },

  getPositionSerie: (state, position) => state.seriesById[position.serieId],
  getPositionSerieDependencies({ seriesById }) {
    return [seriesById];
  },

  getSelectedCulture({ plotSerieSelection, cultureSelection, cultures }) {
    const selection = plotSerieSelection || cultureSelection;
    return cultures.find((culture) => isCultureMatching(culture, selection));
  },
  getSelectedCultureDependencies({ plotSerieSelection, cultureSelection, cultures }) {
    return [plotSerieSelection, cultureSelection, cultures];
  },

  getOtherPlotSeries() {
    return [];
  },
  getOtherPlotSeriesDependencies() {
    return [];
  },

  NotEnoughSurfaceModal: {
    ModalComponent: NotEnoughSurfaceModal,
    RadioComponent: Radio,
  },
  PlotWithBedsBlock: {
    getDropBedPositionSerieCommand: ({
      cultureSelection,
      plotSerieSelection,
      selectedPlot,
      startPosition,
      endPosition,
      bed,
      continueOn,
    }) => {
      const { parentCropItineraryId, cultureMode, serieIndex, serieId } = cultureSelection || plotSerieSelection;
      const command: DropBedPositionSerie<CultureCoordinates> = {
        type: 'dropBedPositionSerie',
        plot: selectedPlot,
        bed: bed,
        startPosition,
        endPosition,
        parentCropItineraryId,
        cultureMode,
        serieIndex,
        serieId,
        continueOn: continueOn === 'partial' ? undefined : continueOn,
      };
      if (plotSerieSelection) {
        // if ('isMoving' in selection ) { // plotSerieSelection ?
        const { startPosition, endPosition, plotId, bedId } = plotSerieSelection;
        command.initialPosition = {
          bedId: bedId,
          plotId,
          start: startPosition,
          lengthOrSurface: endPosition - startPosition,
        };
      }
      return command;
    },
  },
  PlotWithoutBedsBlock: {
    getDropNoBedPositionSerieCommand({
      selectedPlot,
      cultureSelection,
      plotSerieSelection,
      startPosition,
      endPosition,
    }) {
      const { parentCropItineraryId, cultureMode, serieIndex, serieId } = cultureSelection || plotSerieSelection;
      const command: DropNoBedPositionSerie<CultureCoordinates> = {
        type: 'dropNoBedPositionSerie',
        plot: selectedPlot,
        startPosition,
        endPosition,
        parentCropItineraryId,
        cultureMode,
        serieIndex,
        serieId,
      };
      if (plotSerieSelection) {
        // if ('isMoving' in selection ) { // plotSerieSelection ?
        const { startPosition, endPosition, plotId, bedId } = plotSerieSelection;
        command.initialPosition = {
          bedId: bedId,
          plotId,
          start: startPosition,
          lengthOrSurface: endPosition - startPosition,
        };
      }
      return command;
    },
  },
  LanesBlock: {
    SerieComponent: PlotSerie,
  },
  PlotSerieSelection: {
    SelectBoxContent: PlotSerieSelectionContent,
  },
};
