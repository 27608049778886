import { fr as cc, initI18n } from '@elzeard/common-components';
import { fr as commonPlanningFr } from '@elzeard/common-planning';
import account from '../../locales/fr/account';
import common from '../../locales/fr/common';
import outlet from '../../locales/fr/outlet';
import position from '../../locales/fr/position';
import project from '../../locales/fr/project';
import series from '../../locales/fr/series';
import shared from '../../shared-elzeard/locales/fr/shared';

initI18n({
  fallbackLng: 'fr',
  debug: false,
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  resources: {
    fr: {
      cc,
      shared,
      common,
      'common-planning': commonPlanningFr,
      outlet,
      series,
      account,
      project,
      position,
    },
  },
  defaultNS: 'common',
});
