import { css } from 'styled-components';

export type TextHeight = 24 | 18 | 16 | 14 | 12 | 10 | 9;
export type TextWeight = 'semibold' | 'medium' | 'regular';
export type TextStyle = 'normal' | 'italic';

const textHeight: Record<`_${TextHeight}`, string> = {
  _9: `
    font-size: 9px;
    line-height: 11px;  
  `,
  _10: `
    font-size: 10px;
    line-height: 12px;  
  `,
  _12: `
    font-size: 12px;
    line-height: 15px;  
  `,
  _14: `
    font-size: 14px;
    line-height: 17px;  
  `,
  _16: `
    font-size: 16px;
    line-height: 20px;  
  `,
  _18: `
    font-size: 18px;
    line-height: 22px;  
  `,
  _24: `
    font-size: 24px;
    line-height: 29px;  
  `,
};
const textWeight: Record<TextWeight, number> = {
  regular: 400,
  medium: 500,
  semibold: 600,
};

export function getTextStyle(height: TextHeight, weight: TextWeight = 'regular', style: TextStyle = 'normal') {
  return `
    ${textHeight['_' + height]}
    font-weight: ${textWeight[weight]};
    font-style: ${style};
  `;
}

/**
 * @deprecated
 */
export const textStyle = {
  '10-bold': css`
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
  `,
  title: css`
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    text-transform: uppercase;
  `,
};
