// eslint-disable-next-line import/no-anonymous-default-export
export default {
  alertUnsavedChanges: "Vous n'avez pas sauvegardé votre projet. Voulez-vous quitter la page ?",
  home: {
    topbar: {
      'create-outlet': 'Créer un débouché',
      back: 'Retour aux débouchés',
      'main-display-option-selector': {
        totalNeeds: {
          label: 'Quantité',
          unit: 'pièces et kg',
        },
        defaultUnitPrice: {
          label: 'Prix unitaire',
          unit: '€ / kg - pièce',
        },
        totalSales: {
          label: "Chiffre d'affaires",
          unit: '€',
        },
      },
    },
    spinner: 'Chargement des données de planification',
  },
  'select-products-modal': {
    title: 'Choix des légumes de la gamme',
    headers: {
      products: 'Légumes de la gamme',
      unit: 'Unités',
    },
  },
  'config-main-modal': {
    title: 'Configuration du débouché',
    name: {
      label: '1- Nom du débouché',
      placeholder: 'Ajouter un nom au débouché',
      'not-unique-error': 'Ce nom est déjà utilisé par un autre débouché',
    },
    'default-prices': {
      title: '2- Choix des prix par défaut',
      circuit: {
        label: 'Type de débouché',
        short: 'Circuits courts',
        long: 'Circuits longs',
      },
      level: {
        label: 'Fourchette de prix',
        low: 'Prix bas',
        medium: 'Prix moyen',
        high: 'Prix haut',
      },
    },
  },
  'prices-modal': {
    title: 'Tableau du prix unitaire des légumes par défaut',
    crops: 'Légumes',
    'unit-price': 'Prix unitaire',
    'unit-price-unit': '€ / u',
    unit: 'Unité',
    'distribution-circuit': {
      short: 'court',
      long: 'long',
      custom: 'perso.',
    },
    'price-level': {
      low: 'bas',
      medium: 'moyen',
      high: 'haut',
    },
  },

  'confirmation-modal': {
    'delete-outlet': {
      title: 'Êtes-vous sûr de vouloir supprimer ce débouché ?',
      details:
        'Attention les légumes qui lui sont associés dans les tableaux de ventes, de séries et d’assolements seront définitivement perdus !',
    },
    'disable-outlet': {
      title: 'Êtes-vous sûr de vouloir désactiver ce débouché et son contenu ?',
      details:
        'Les légumes qui lui sont associés dans les tableaux de ventes, de séries et d’assolements seront également désactivés.',
    },
  },
  'header-table': {
    head: {
      title: 'Débouchés',
      totalNeeds: {
        label: 'Qté',
      },
      quantityUnit: {
        label: 'Unité',
      },
      defaultUnitPrice: {
        label: 'Prix unitaire',
        unit: '€/u',
      },
      totalSales: {
        label: 'CA estimé',
        unit: '€',
      },
    },
    foot: {
      totalSalesTarget: 'Objectifs de vente',
      totalSales: 'Chiffre d’affaires total',
    },
    'product-sub-row': {
      totalSales: {
        name: 'Chiffre d’affaires',
        unit: '€',
      },
      totalNeeds: {
        name: 'Quantité unitaire',
        unit: '{{quantityUnit}}',
      },
      defaultUnitPrice: {
        name: 'Prix unitaire',
        unit: '€ / {{quantityUnit}}',
      },
    },
    'outlet-menu': {
      settings: 'Modifier les paramètres du débouché',
      copy: 'Copier le débouché et son contenu',
      disable: 'Désactiver le débouché',
      delete: 'Supprimer le débouché',
    },
    'sales-target': {
      header: 'Objectif de vente',
    },
  },
  allOutlets: {
    name: 'Tous les débouchés',
  },
};
