import { useTranslation } from '@elzeard/common-components';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components';
import { routes } from '../../app/routes';
import ImgLogo from '../../assets/colored-logo-158.svg';
import ButtonObsolete from '../../common/components/ButtonObsolete';
import { ErrorMessage } from '../../common/components/ErrorMessage';
import InputField from '../../common/components/field/InputField';
import ModalContent from '../../common/components/ModalContent';
import { useResetUserPasswordFromTokenMutation } from '../../common/generated/graphql';
import { textStyle } from '../../common/style/text';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 300px;
  text-align: center;
`;
const Logo = styled.img`
  height: 176px;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 8px;
`;
const Title = styled.div`
  margin-bottom: 24px;
  text-align: center;
  ${textStyle['title']}
`;

interface IFormValues {
  password: string;
  passwordConfirm: string;
}

export function ViewResetPassword() {
  const { t } = useTranslation();
  const [apiError, setApiError] = useState<string | null>();
  const [passwordReset, setPasswordReset] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<IFormValues>();
  const [mutateResetUserAccountPasswordByMail] = useResetUserPasswordFromTokenMutation();

  const history = useHistory();

  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const resetPasswordToken = searchParams.get('token');

  const onSubmit = async (formData: IFormValues) => {
    try {
      if (formData.password && formData.password !== formData.passwordConfirm) {
        setApiError(t('common:error.unmatching-password'));
        return;
      }
      const resetPasswordResult = await mutateResetUserAccountPasswordByMail({
        variables: {
          newPassword: formData.password,
          token: resetPasswordToken,
        },
      });
      if (resetPasswordResult.data.resetUserPasswordFromToken) {
        setPasswordReset(true);
      }
    } catch (e) {
      setApiError(t('common:error.generic'));
    }
  };

  /**
   * Remove the API error message after the user has changed any of the input fields
   */
  const password = watch('password');
  const passwordConfirm = watch('passwordConfirm');
  useEffect(() => {
    setApiError(null);
  }, [password, passwordConfirm]);

  if (!resetPasswordToken) {
    history.push(routes.login.root);
    return;
  }

  if (passwordReset) {
    return (
      <ModalContent
        title={t('common:auth.reset-password.modal-confirmation-title')}
        onClick={() => history.push(routes.login.root)}
        buttonLabel={t('common:button.back')}
      >
        {t('common:auth.reset-password.modal-confirmation-description')}
      </ModalContent>
    );
  }

  return (
    <Container>
      <Logo src={ImgLogo} />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Title>{t('common:app.title')}</Title>
        <InputField
          placeholder={t('common:fields.password')}
          type="password"
          fieldName="password"
          register={register}
          errors={errors}
          required
        />

        <InputField
          placeholder={t('common:fields.passwordConfirm')}
          type="password"
          fieldName="passwordConfirm"
          register={register}
          errors={errors}
          required
        />
        <ErrorMessage message={errors.password && t('common:error.empty-field')} />
        <ErrorMessage message={apiError} />

        <ButtonObsolete variant="contained">{t('common:auth.reset-password.action')}</ButtonObsolete>
      </Form>
    </Container>
  );
}

export default ViewResetPassword;
