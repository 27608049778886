import React from 'react';
import { useHistory } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import styled from 'styled-components';
import { routes } from '../../app/routes';
import Spinner from '../../common/components/Spinner';
import { useValidateAccountMutation } from '../../common/generated/graphql';
import { useSearchParams } from '../../common/hook/useSearchParams';

const Container = styled.div``;

export function ViewCallBackAccountCreation() {
  const searchParams = useSearchParams();
  const [mutateValidateAccount] = useValidateAccountMutation();
  const history = useHistory();

  const token = searchParams.get('token');
  if (!token) {
    history.push(routes.login.root);
  }

  useEffectOnce(() => {
    mutateValidateAccount({ variables: { token } }).finally(() => {
      history.push(`${routes.login.root}?registerComplete=1`);
    });
  });

  return (
    <Container>
      <Spinner />
    </Container>
  );
}
