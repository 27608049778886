/* eslint-disable import/no-anonymous-default-export */
export default {
  app: {
    title: 'La Pépinière',
    menu: {
      home: 'Accueil',
      outlets: 'Gamme',
      series: 'Séries',
      position: 'Assolement',
    },
    tutorial: 'Tutoriels',
    accountValidationRequired: {
      text: "Pour pouvoir utiliser la Pépinière, veuillez tout d'abord valider votre compte via le lien reçu par email. Si vous ne l'avez pas reçu, vous pouvez générer un nouveau lien de validation en cliquant sur le lien suivant",
      action: 'Recevoir un nouveau lien par mail',
      sent: 'Le lien a bien été envoyé, veuillez vérifier votre boîte mail.',
    },
  },
  auth: {
    login: {
      'button-login': 'Connexion',
      'button-reset-password': 'Mot de passe oublié',
      'button-create-account': 'Créer un compte',
      'register-complete': 'Inscription terminée ! Vous pouvez vous connecter à la Pépinière',
    },
    'create-account': {
      'verify-email-title': 'Vérifiez votre boîte mail',
      'verify-email-description':
        "Pour utiliser la Pépinière, cliquez sur le bouton de vérification que vous trouverez dans le mail que nous venons d'envoyer à l'adresse",
      action: 'Créer un compte',
    },
    'forgot-password': {
      'email-sent-title': 'Nouveau mot de passe',
      'email-sent-description': "Un mail de vérification vous a été envoyé à l'adresse",
      action: 'Réinitialiser mon mot de passe',
    },
    'reset-password': {
      'modal-confirmation-title': 'Changement mot de passe',
      'modal-confirmation-description': 'Votre mot de passe a bien été modifié',
      action: 'Valider mon nouveau mot de passe',
    },
  },
  button: {
    back: 'Retour',
    cancel: 'Annuler',
    next: 'Suivant',
    previous: 'Précédent',
    validate: 'Valider',
    OK: 'OK',
    remove: 'Supprimer',
  },
  'date-format': {
    full: 'd LLLL y',
  },
  error: {
    generic:
      "Une erreur est survenue. Veuillez réessayer plus tard. Si le problème persiste, contactez l'administrateur.",
    'empty-field': 'Veuillez remplir les champs manquants',
    'wrong-password': 'Mot de passe incorrect. Réessayez ou cliquez sur mot de passe oublié.',
    'unmatching-password': 'Les mots de passe ne concordent pas.',
    'missing-fields': 'Veuillez saisir tous les champs obligatoires (munis d’une asterisque)',
    'form-validation':
      'Un ou plusieurs champs sont incorrects. Veuillez vérifiers les valeurs des champs et réessayer.',
  },
  fields: {
    email: 'Mail',
    password: 'Mot de passe',
    passwordConfirm: 'Confirmation mot de passe',
    username: "Nom d'utilisateur",
  },
  'main-menu': {
    home: 'Accueil & projets',
    'account-settings': 'Paramètres du compte',
    logout: 'Se déconnecter',
  },
  spinner: {
    page: 'Chargement en cours',
    form: 'Chargement des modifications',
  },
};
