import { useTranslation } from '@elzeard/common-components';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { routes } from '../../app/routes';
import ImgLogo from '../../assets/colored-logo-158.svg';
import ButtonObsolete from '../../common/components/ButtonObsolete';
import { ErrorMessage } from '../../common/components/ErrorMessage';
import ModalContent from '../../common/components/ModalContent';
import Spinner from '../../common/components/Spinner';
import { HookedTextInput } from '../../common/components/field/InputField';
import { useRegisterFarmAccountMutation } from '../../common/generated/graphql';
import { textStyle } from '../../common/style/text';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 300px;
  text-align: center;
`;
const Logo = styled.img`
  height: 176px;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 8px;
`;
const Title = styled.div`
  margin-bottom: 24px;
  text-align: center;
  ${textStyle['title']}
`;

interface IFormValues {
  username: string;
  password: string;
}
export function ViewCreateAccount() {
  const { t } = useTranslation();
  const [isLoading, setIsloading] = useState<boolean>(false);
  const [apiError, setApiError] = useState<string | null>();
  const [accountCreated, setAccountCreated] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<IFormValues>();
  const [mutateCreateAccount] = useRegisterFarmAccountMutation();

  const history = useHistory();

  const onSubmit = async (formData: IFormValues) => {
    setIsloading(true);
    setApiError(null);
    try {
      const registerFarmAccountResult = await mutateCreateAccount({
        variables: {
          input: {
            email: formData.username,
            password: formData.password, 
            redirectUrl: `${window.location.origin}${routes.login.callbackAccountCreation}`,  
          }          
        },
      });
      if (registerFarmAccountResult.data.user_registerAccount) {
        setAccountCreated(true);
      } else {
        throw new Error();
      }
    } catch (e) {
      setApiError(t('common:error.generic'));
    }
    setIsloading(false);
  };

  const formData = watch();

  if (accountCreated) {
    return (
      <ModalContent
        title={t('common:auth.create-account.verify-email-title')}
        onClick={() => history.push('/login')}
        buttonLabel={t('common:button.back')}
      >
        {t('common:auth.create-account.verify-email-description')} {formData.username}
      </ModalContent>
    );
  }
  return (
    <Container>
      <Logo src={ImgLogo} />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Title>{t('common:app.title')}</Title>
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <HookedTextInput
              placeholder={t('common:fields.email')}
              fieldName="username"
              register={register}
              errors={errors}
              required
              type="email"
            />

            <HookedTextInput
              placeholder={t('common:fields.password')}
              type="password"
              fieldName="password"
              errors={errors}
              register={register}
              required
            />
            <ErrorMessage message={(errors.password || errors.username) && t('common:error.generic')} />
            <ErrorMessage message={apiError} />

            <ButtonObsolete variant="contained">{t('common:auth.create-account.action')}</ButtonObsolete>
            <ButtonObsolete
              variant="contained"
              onClick={() => history.go(-1)}
            >
              {t('common:button.back')}
            </ButtonObsolete>
          </>
        )}
      </Form>
    </Container>
  );
}
