import styled from 'styled-components';
import { Radio } from '../../../common/components/field/Radio';
import { colorStyle } from '../../../common/style/colors';
import { getTextStyle } from '../../../common/style/text';

export const Form = styled.form`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
export const ButtonContainer = styled.div`
  margin-top: auto;
  display: flex;
  flex-direction: column;
`;
export const StyledInput = styled.input`
  padding: 10px 16px;
  ${getTextStyle(10, 'regular')}
  border: 1px solid ${colorStyle.greys['4-defaultIcon']};
  ::placeholder {
    color: ${colorStyle.greys['1-black']};
  }
  :not(:first-of-type) {
    margin-top: -1px;
  }
`;

export const StyledRadio = styled(Radio)`
  ${getTextStyle(10, 'regular')}
  display: flex;
  gap: 8px;
  align-items: center;
  height: 30px;
  border: 1px solid ${colorStyle.greys['4-defaultIcon']};
  padding: 3px 11px;
  &:not(:first-of-type) {
    margin-top: -1px;
  }
  & .checkbox-label {
    color: ${colorStyle.greys['4-defaultIcon']};
    &::first-letter {
      text-transform: capitalize;
    }
  }
`;

// const SwitchLabel = styled.label`
//   position: relative;
//   display: inline-block;
//   width: 20px;
//   height: 10px;
// `;
// const Switch = styled.input`
//   opacity: 0;
//   width: 0;
//   height: 0;
//   &:checked + span {
//     background-color: ${colors.greys['4-defaultIcon']};
//   }
//   &:focus + span {
//     box-shadow: 0 0 1px ${colors.greys['4-defaultIcon']};
//   }
//   &:checked + span:before {
//     -webkit-transform: translateX(9px);
//     -ms-transform: translateX(9px);
//     transform: translateX(9px);
//     background-color: ${colors.cta1['1-active']};
//   }
// `;
// const Slider = styled.span`
//   position: absolute;
//   cursor: pointer;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   border-radius: 34px;
//   background-color: ${fillColors.textHeader2};
//   transition: 0.4s;
//   &::before {
//     position: absolute;
//     content: '';
//     height: 6px;
//     width: 6px;
//     left: 2px;
//     bottom: 2px;
//     border-radius: 50%;
//     background-color: ${colors.greys['8']};
//     -webkit-transition: 0.4s;
//     transition: 0.4s;
//   }
// `;
// const SwitchContainer = styled.div`
//   display: flex;
//   align-items: center;
// `;
// const SwitchTypo = styled.span`
//   padding-left: 12px;
//   font-size: 10px;
// `;
export const Select = styled.select`
  ${getTextStyle(10, 'regular')}
  height: 30px;
  padding: 5px;
  color: ${colorStyle.greys['4-defaultIcon']};
  &:hover {
    cursor: pointer;
  }
`;
