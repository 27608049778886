import styled from 'styled-components';
import { colorStyle } from '../style/colors';
import { getTextStyle } from '../style/text';

/**
 * variant primary => square
 * variant secondary => round
 */
export const Token = styled.div<{ $variant: 'primary' | 'secondary'; $variableSize?: boolean }>`
  ${getTextStyle(12, 'semibold')}
  color: ${colorStyle.greys['9-white']};
  display: flex;
  align-items: center;
  flex: none;
  ${({ $variant }) =>
    $variant === 'secondary'
      ? `
      background-color: ${colorStyle.cta1['2-hover']};
      border-radius: 9px;
    `
      : `
      background-color: ${colorStyle.cta1['1-active']};
    `}
  ${({ $variableSize }) =>
    $variableSize
      ? `
      padding: 1px 4px;
    `
      : `
      height: 18px;
      width: 18px;
      justify-content: center;
    `}
`;
