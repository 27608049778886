import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { colorStyle } from '../style/colors';
import { getTextStyle } from '../style/text';

export type Transient<P extends {}> = {
  [K in keyof P as `$${K extends string ? K : never}`]: P[K];
};
// export type TransientKeys<P extends {}> = { [K in keyof P]?: true };
// export function toTransientProps<P extends {}>(
//   props: P,
//   transientKeys: TransientKeys<P>,
// ): Omit<P, keyof typeof transientKeys> & Transient<Pick<P, keyof typeof transientKeys>> {
//   return transform(
//     props,
//     (acc, key: string, value) => (acc[transientKeys[key] ? '$' + key : key] = value),
//     {} as Omit<P, keyof typeof transientKeys> & Transient<Pick<P, keyof typeof transientKeys>>,
//   );
// }

// TODO shared with elzeard
export type Sizing = 'XS' | 'S' | 'M' | 'L';
export type ButtonVariant = 'primary' | 'secondary' | 'CTA' | 'error';

// TODO use it
// type ElzeardButtonVariant = 'default' | 'primary' | 'secondary' | 'error';

// const elzeardToPepiniereVariant: Record<ElzeardButtonVariant, ButtonVariant> = {
//   default: 'secondary',
//   primary: 'CTA',
//   secondary: 'primary',
//   error: 'error',
// };

type ButtonState = 'default' | 'hover' | 'pressed' | 'active' | 'disabled';
export type ButtonFill = 'unfilled' | 'filled';
export const buttonSize: Record<
  Sizing,
  {
    label: FlattenSimpleInterpolation;
    padding: string;
    height: string;
    iconSize: number;
    iconMargin: string;
    borderWidth: number;
    // if the variant specifies a box shadow, should it be displayed ?
    boxShadow?: boolean;
    // if the variant has a borderRadius === true, use this value
    borderRadius: string;
  }
> = {
  XS: {
    label: css`
      ${getTextStyle(12, 'semibold')}
      padding: 0 2px;
    `,
    padding: '4px',
    height: '16px',
    iconSize: 8,
    iconMargin: '0',
    borderWidth: 1,
    borderRadius: '8px',
  },
  S: {
    label: css`
      ${getTextStyle(14, 'semibold')}
      padding: 0 3px;
    `,
    padding: '3px',
    height: '22px',
    iconSize: 12,
    iconMargin: '2px',
    borderWidth: 1,
    borderRadius: '11px',
  },
  M: {
    label: css`
      ${getTextStyle(16, 'semibold')}
      padding: 0 4px;
    `,
    padding: '3px',
    height: '30px',
    iconSize: 16,
    iconMargin: '4px',
    borderWidth: 2,
    borderRadius: '15px',
    boxShadow: true,
  },
  L: {
    label: css`
      ${getTextStyle(18, 'semibold')}
      padding: 0 6px;
    `,
    padding: '0',
    height: '40px',
    iconSize: 24,
    iconMargin: '8px',
    borderWidth: 2,
    borderRadius: '20px',
    boxShadow: true,
  },
};
const buttonVariant: Record<
  ButtonFill,
  Record<
    ButtonVariant,
    Record<
      ButtonState,
      {
        color: string;
        background: string;
        borderColor?: string;
        borderRadius?: string | boolean; // TODO move 1 level up ?
        boxShadow?: string;
      }
    >
  >
> = {
  unfilled: {
    primary: {
      default: {
        background: 'transparent',
        color: colorStyle.greys['1-black'],
      },
      hover: {
        background: 'transparent',
        color: colorStyle.greys['4-defaultIcon'],
      },
      pressed: {
        background: 'transparent',
        color: colorStyle.cta2['1'],
      },
      active: {
        background: 'transparent',
        color: colorStyle.cta2['1'],
      },
      disabled: {
        background: 'transparent',
        color: colorStyle.greys['6-inactive'],
      },
    },
    secondary: {
      default: {
        background: 'transparent',
        color: colorStyle.greys['4-defaultIcon'],
      },
      hover: {
        background: 'transparent',
        color: colorStyle.greys['1-black'],
      },
      pressed: {
        background: 'transparent',
        color: colorStyle.cta2['1'],
      },
      active: {
        background: 'transparent',
        color: colorStyle.cta2['1'],
      },
      disabled: {
        background: 'transparent',
        color: colorStyle.greys['6-inactive'],
      },
    },
    CTA: {
      default: {
        background: colorStyle.greys['9-white'],
        borderColor: colorStyle.cta2['1'],
        borderRadius: '4px',
        color: colorStyle.cta2['1'],
      },
      hover: {
        background: colorStyle.greys['9-white'],
        borderColor: colorStyle.cta2['2'],
        borderRadius: '4px',
        color: colorStyle.cta2['2'],
      },
      pressed: {
        background: colorStyle.cta2['5'],
        borderColor: colorStyle.cta2['0'],
        borderRadius: '4px',
        color: colorStyle.cta2['0'],
      },
      active: {
        background: colorStyle.cta2['1'],
        borderRadius: '4px',
        color: colorStyle.greys['9-white'],
        boxShadow: '0px 4px 4px rgba(73, 98, 226, 0.25)',
      },
      disabled: {
        background: 'transparent',
        borderColor: 'rgba(54, 80, 218, 0.55)',
        borderRadius: '4px',
        color: 'rgba(54, 80, 218, 0.55)',
      },
    },
    error: {
      default: {
        background: 'transparent',
        color: colorStyle.error['3'],
      },
      hover: {
        background: 'transparent',
        color: colorStyle.error['2'],
      },
      pressed: {
        background: 'transparent',
        color: colorStyle.error['1'],
      },
      active: {
        background: 'transparent',
        color: colorStyle.error['1'],
      },
      disabled: {
        background: 'transparent',
        color: colorStyle.greys['6-inactive'],
      },
    },
  },
  filled: {
    primary: {
      default: {
        background: colorStyle.greys['2'],
        color: colorStyle.greys['9-white'],
        borderRadius: true,
      },
      hover: {
        background: colorStyle.greys['4-defaultIcon'],
        color: colorStyle.greys['9-white'],
        borderRadius: true,
      },
      pressed: {
        background: colorStyle.cta2['0'],
        color: colorStyle.greys['9-white'],
        borderRadius: true,
      },
      active: {
        background: colorStyle.cta2['0'],
        color: colorStyle.greys['9-white'],
        borderRadius: true,
      },
      disabled: {
        background: colorStyle.greys['9-white'],
        color: colorStyle.greys['6-inactive'],
        borderRadius: true,
      },
    },
    secondary: {
      default: {
        background: colorStyle.greys['4-defaultIcon'],
        color: colorStyle.greys['9-white'],
        borderRadius: true,
      },
      hover: {
        background: colorStyle.greys['2'],
        color: colorStyle.greys['9-white'],
        borderRadius: true,
      },
      pressed: {
        background: colorStyle.cta2['0'],
        color: colorStyle.greys['9-white'],
        borderRadius: true,
      },
      active: {
        background: colorStyle.cta2['0'],
        color: colorStyle.greys['9-white'],
        borderRadius: true,
      },
      disabled: {
        background: colorStyle.greys['9-white'],
        color: colorStyle.greys['6-inactive'],
        borderRadius: true,
      },
    },
    CTA: {
      default: {
        background: colorStyle.cta2['1'],
        color: colorStyle.greys['9-white'],
        borderRadius: true,
      },
      hover: {
        background: colorStyle.cta2['2'],
        color: colorStyle.greys['9-white'],
        borderRadius: true,
      },
      pressed: {
        background: colorStyle.cta2['0'],
        color: colorStyle.greys['9-white'],
        borderRadius: true,
      },
      active: {
        background: colorStyle.cta1['1-active'],
        color: colorStyle.greys['9-white'],
        borderRadius: true,
      },
      disabled: {
        background: colorStyle.greys['9-white'],
        color: colorStyle.greys['6-inactive'],
        borderRadius: true,
      },
    },
    error: {
      default: {
        background: colorStyle.error['3'],
        color: colorStyle.greys['9-white'],
        borderRadius: true,
      },
      hover: {
        background: colorStyle.error['2'],
        color: colorStyle.greys['9-white'],
        borderRadius: true,
      },
      pressed: {
        background: colorStyle.error['1'],
        color: colorStyle.greys['9-white'],
        borderRadius: true,
      },
      active: {
        background: colorStyle.error['1'],
        color: colorStyle.greys['9-white'],
        borderRadius: true,
      },
      disabled: {
        background: colorStyle.greys['9-white'],
        color: colorStyle.greys['6-inactive'],
        borderRadius: true,
      },
    },
  },
};

export interface ButtonBaseProps {
  variant: ButtonVariant; // cannot be named $variant: transient props (starting with $) are not passed to child components
  sizing?: Sizing;
  isFilled?: boolean;
  isActive?: boolean;
  fullWidth?: boolean;
}

export type ButtonStyleProps = ButtonBaseProps & {
  disabled?: boolean;
};

export type TransientButtonStyleProps = Transient<ButtonBaseProps> & {
  disabled?: boolean;
};

// const transientProps: TransientKeys<ButtonBaseProps> = {
//   variant: true,
//   sizing: true,
//   isFilled: true,
//   isActive: true,
//   fullWidth: true,
// };

// export function toTransientButtonProps<P extends ButtonStyleProps>(props: P) {
//   return toTransientProps(props, transientProps);
// }

const commonStyle = `
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  appearance: none;
  max-height: 100%;
  box-sizing: border-box;
  transition: 0.15s ease, background-color 0.15s ease, color 0.15s ease, box-shadow 0.15s ease, background 0.15s ease;
  border-radius: 4px;
`;
function getVariantStyle({ $isFilled, $sizing, $variant }: TransientButtonStyleProps, $state: ButtonState) {
  const variant = buttonVariant[$isFilled ? 'filled' : 'unfilled'][$variant][$state];
  const size = buttonSize[$sizing];
  // const borderRadius = variant.borderRadius === true ? size.borderRadius : variant.borderRadius;
  // ${borderRadius ? `border-radius: ${borderRadius};` : ''}
  return `
    background-color: ${variant.background};
    color: ${variant.color};
    ${variant.borderColor ? `border: ${size.borderWidth}px solid ${variant.borderColor};` : ''}    
    ${variant.boxShadow ? `box-shadow: ${variant.boxShadow};` : ''}
    ${
      $state === 'disabled'
        ? `
        cursor: default;
        pointer-events: none;
      `
        : `
        cursor: pointer;
      `
    }
  `;
}

export function getButtonStyle(props: TransientButtonStyleProps) {
  const state: ButtonState = props.disabled ? 'disabled' : props.$isActive ? 'active' : 'default';
  const stateStyle = getVariantStyle(props, state);
  const size = buttonSize[props.$sizing];
  return `
    ${commonStyle}
    ${stateStyle}
    width: ${props.$fullWidth ? '100%' : 'fit-content'};
    height: ${size.height};
    padding: ${size.padding};
    .button-icon {
      margin: ${size.iconMargin};
    }
    &:hover {
      ${getVariantStyle(props, 'hover')}
    }
    &:active {
      ${getVariantStyle(props, 'pressed')}
    }
    &.active {
      ${getVariantStyle(props, 'active')}
    }
  `;
}

export const ButtonLabel = styled.div<Pick<TransientButtonStyleProps, '$sizing'>>`
  flex: auto;
  ${({ $sizing }) => buttonSize[$sizing].label}
`;
