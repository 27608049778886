import { computeNumberMapTotal, NumberMap, sumNumberMaps } from '@elzeard/common-planning';
import { identity, sortBy } from 'lodash';
import { mergeMaybeOverlappingPeriods } from '../common/ProductAvailability';
import { BaseOutlet, BaseProjectPageState, ParentProduct, ProjectPageState } from '../state';
import { buildProductAvailabilityPeriod } from '../state-build';
import { allColumns } from './components/OutletsHeaderTable';
import { MainDisplayOption } from './state-displayOptions';
import {
  ALL_OUTLETS_ROW_ID,
  BaseOutletProduct,
  ComputedOutletsPageState,
  Outlet,
  OutletProduct,
  OutletsPageState,
} from './state-full';

export const defaultLostRate = 0.1;

export const mainDisplayOptions: Array<MainDisplayOption> = ['totalSales', 'totalNeeds', 'defaultUnitPrice'];

const displayOption2ProductProp: Record<MainDisplayOption, keyof OutletProduct> = {
  defaultUnitPrice: 'weeklyPrices',
  totalNeeds: 'weeklyNeeds',
  totalSales: 'weeklySales',
};
export function getProductPropertyName(displayOption: MainDisplayOption) {
  return displayOption2ProductProp[displayOption];
}
export function getProductProperty(product: OutletProduct, displayOption: MainDisplayOption): NumberMap {
  const propName = getProductPropertyName(displayOption);
  return product[propName] as NumberMap;
}

function getOutletsState(
  baseState: Pick<BaseProjectPageState, 'selectedParentProducts' | 'outletsByRowId'>,
  editedOutletRowId: string,
): ComputedOutletsPageState {
  const parentProducts = sortBy(Object.values(baseState.selectedParentProducts), ({ name }) => name);
  if (editedOutletRowId) {
    const baseOutlet = baseState.outletsByRowId[editedOutletRowId];
    let totalSales = 0;
    let weeklySales = {};

    const products: Array<OutletProduct> = parentProducts.map((parentProduct) => {
      const baseProduct = combineChildrenProducts(baseOutlet, parentProduct);
      const fullProduct = buildFullProduct(baseProduct);
      weeklySales = sumNumberMaps(weeklySales, fullProduct.weeklySales);
      totalSales += fullProduct.totalSales;
      return fullProduct;
    });
    return {
      allOutlets: null,
      outlets: [
        {
          ...baseOutlet,
          totalSales,
          totalSalesTarget: computeNumberMapTotal(baseOutlet.weeklySalesTarget),
          weeklySales,
          products,
        },
      ],
    };
  } else {
    const { allOutlets, convertedOutlets, allOutletsProductsByParentItineraryId } = Object.values(
      baseState.outletsByRowId,
    ).reduce(
      ({ allOutlets, convertedOutlets, allOutletsProductsByParentItineraryId }, baseOutlet) => {
        let totalSales = 0;
        let weeklySales = {};
        const products: Array<OutletProduct> = parentProducts
          .map((parentProduct) => {
            const needs = parentProduct.productionNeedsByOutletRowId[baseOutlet.rowId];
            if (needs) {
              const baseProduct = combineChildrenProducts(baseOutlet, parentProduct);
              const fullProduct = buildFullProduct(baseProduct);
              weeklySales = sumNumberMaps(weeklySales, fullProduct.weeklySales);
              totalSales += fullProduct.totalSales;
              return fullProduct;
            } else {
              // TODO is it possible ?
              console.warn(
                `Parent product without production needs for outlet [parentCropItineraryId=${parentProduct.parentCropItineraryId}] [outletRowId=${baseOutlet.rowId}]`,
              );
              return null;
            }
          })
          .filter(identity);
        for (const product of products) {
          if (!baseOutlet.isDisabled) {
            let summedProduct: OutletProduct = allOutletsProductsByParentItineraryId[product.parentCropItineraryId];
            if (!summedProduct) {
              summedProduct = {
                ...product,
                rowId: `${ALL_OUTLETS_ROW_ID}-${product.parentCropItineraryId}`,
                defaultUnitPrice: null,
                weeklyNeeds: {},
                weeklyPrices: {},
                weeklySales: {},
                totalNeeds: 0,
                totalSales: 0,
              };
              allOutletsProductsByParentItineraryId[product.parentCropItineraryId] = summedProduct;
            }
            summedProduct.weeklyNeeds = sumNumberMaps(summedProduct.weeklyNeeds, product.weeklyNeeds);
            summedProduct.weeklySales = sumNumberMaps(summedProduct.weeklySales, product.weeklySales);
            summedProduct.totalNeeds += product.totalNeeds;
            summedProduct.totalSales += product.totalSales;
          }
        }
        const outlet: Outlet = {
          ...baseOutlet,
          totalSales,
          totalSalesTarget: computeNumberMapTotal(baseOutlet.weeklySalesTarget),
          weeklySales,
          products,
        };
        convertedOutlets.push(outlet);
        if (!baseOutlet.isDisabled) {
          allOutlets.weeklySales = sumNumberMaps(allOutlets.weeklySales, outlet.weeklySales);
          allOutlets.weeklySalesTarget = sumNumberMaps(allOutlets.weeklySalesTarget, outlet.weeklySalesTarget);
          allOutlets.totalSales += outlet.totalSales;
          allOutlets.totalSalesTarget += outlet.totalSalesTarget;
        }
        return {
          allOutlets,
          convertedOutlets,
          allOutletsProductsByParentItineraryId,
        };
      },
      {
        allOutlets: {
          isUpdated: false,
          name: null,
          outletId: null,
          rowId: ALL_OUTLETS_ROW_ID,
          weeklySales: {},
          weeklySalesTarget: {},
          totalSales: 0,
          totalSalesTarget: 0,
        } as Omit<Outlet, 'products'>,
        convertedOutlets: [] as Array<Outlet>,
        allOutletsProductsByParentItineraryId: {} as Record<string, OutletProduct>,
      },
    );
    return {
      allOutlets: {
        ...allOutlets,
        products: sortBy(Object.values(allOutletsProductsByParentItineraryId), ({ name }) => name),
      },
      outlets: sortBy(convertedOutlets, (outlet) => outlet.name),
    };
  }
}

export function enterOutletsPage(
  baseState: BaseProjectPageState,
  previousState: ProjectPageState<OutletsPageState>,
): OutletsPageState {
  // const fullState = 'editedOutletRowId' in baseState ? baseState : null;
  const previousEditedOutletRowId = previousState?.editedOutletRowId;
  let editedOutletRowId: string = null;
  if (previousEditedOutletRowId) {
    const previousEditedOutlet = previousState.outletsByRowId[previousEditedOutletRowId];
    if (previousEditedOutlet.outletId) {
      // it was already saved, its row id hasn't changed
      editedOutletRowId = previousEditedOutletRowId;
    } else {
      const updatedOutlet = Object.values(baseState.outletsByRowId).find(
        (outlet) => outlet.name === previousEditedOutlet.name,
      );
      editedOutletRowId = updatedOutlet.rowId;
    }
  }
  return {
    visibleColumns: previousState?.visibleColumns || allColumns,
    expandedRows: previousState?.expandedRows || {},
    mainDisplayOption: previousState?.mainDisplayOption || 'totalSales',
    editedOutletRowId,
    displaySalesTarget: previousState?.displaySalesTarget || false,
    editedCell: null,
    confirmationModal: null,
    ...getOutletsState(baseState, editedOutletRowId),
  };
}
export function leaveOutletsPage(state: ProjectPageState<OutletsPageState>): BaseProjectPageState {
  const {
    // visibleColumns,
    // expandedRows,
    // mainDisplayOption,
    editedCell,
    // editedOutletRowId,
    // displaySalesTarget,
    confirmationModal,
    allOutlets,
    outlets,
    ...baseState
  } = state;
  return baseState;
}
export function updateOutletsState(updatedState: ProjectPageState<OutletsPageState>): OutletsPageState {
  return {
    ...updatedState,
    ...getOutletsState(updatedState, updatedState.editedOutletRowId),
  };
}

export function buildFullProduct(base: BaseOutletProduct): OutletProduct {
  const weeklySales = Object.fromEntries(
    Object.entries(base.weeklyNeeds).map(([weekKey, need]) => [
      weekKey,
      need * (base.weeklyPrices[weekKey] || base.defaultUnitPrice || 0),
    ]),
  );
  return {
    ...base,
    weeklySales,
    totalNeeds: computeNumberMapTotal(base.weeklyNeeds),
    totalSales: computeNumberMapTotal(weeklySales),
  };
}

export function combineChildrenProducts(
  outlet: Pick<BaseOutlet, 'rowId' | 'defaultPrices'>,
  parentProduct: ParentProduct,
): BaseOutletProduct {
  const outletNeeds = parentProduct.productionNeedsByOutletRowId[outlet.rowId];

  const initialProduct: BaseOutletProduct = {
    // properties copied from ParentProduct
    name: parentProduct.name,
    parentCropItineraryId: parentProduct.parentCropItineraryId,
    quantityUnit: parentProduct.quantityUnit,
    plantFamilyColors: parentProduct.plantFamilyColors,
    // specific properties
    rowId: `${outlet.rowId}-${parentProduct.parentCropItineraryId}`,
    defaultUnitPrice: outlet.defaultPrices[parentProduct.parentCropItineraryId]?.value,
    weeklyPrices: outletNeeds?.weeklyPrices || {},
    weeklyNeeds: outletNeeds?.weeklyNeeds || {},
    availabilityPeriods: [],
    numberOfSeries: Object.values(parentProduct.selectedChildrenByRowId).length,
  };

  // TODO ajouter une option pour renvoyer null ici quand on sait qu'on aura pas besoin de ce produit (quand pas de débouché sélectionné)
  const combinedParent = sortBy(
    [
      ...Object.values(parentProduct.selectedChildrenByRowId),
      // TODO we may not want to mark the unselected series as available, or mark them differently
      ...Object.values(parentProduct.otherPossibleChildrenByChildItineraryId),
    ].filter((child) => child.harvestPeriods.length > 0),
    (child) => {
      return child.harvestPeriods[0].begin.firstDay.getTime();
    },
  ).reduce((combined, childProduct) => {
    const childAvailability = buildProductAvailabilityPeriod(childProduct);
    return {
      ...combined,
      availabilityPeriods: mergeMaybeOverlappingPeriods(combined.availabilityPeriods, childAvailability),
    };
  }, initialProduct);
  return combinedParent;
}
