import { getWeek, getWeekInterval, MonthInterval } from '@elzeard/common-components';
import { computeNumberMapTotal, computeSerieNeededVolume, NumberMap, sumNumberMaps } from '@elzeard/common-planning';
import { addDays } from 'date-fns';
import { ceil, identity, last, round, sortBy } from 'lodash';
import { mergeMaybeOverlappingPeriods, ProductAvailability } from '../common/ProductAvailability';
import { getWeekKey } from '../outlet/utils';
import { BaseProjectPageState, ChildProduct, CultureMode, ProjectPageState } from '../state';
import { buildProductAvailabilityPeriod } from '../state-build';
import { ComputedSeriesPageState, ProductSerie, SeriesPageState, SeriesProduct } from './state-full';

export function buildProductSerie(childProduct: ChildProduct, isSelected: boolean): ProductSerie {
  const baseSerie = childProduct.series[0];

  const { needs, needsIndexes, implantationWeeks, harvestWeeks, storageWeeks } = childProduct.series.reduce(
    ({ needs, needsIndexes, implantationWeeks, harvestWeeks, storageWeeks }, baseSerie, serieIndex) => {
      const serieImplantationPeriod = getWeekInterval(
        baseSerie.begin,
        getWeek(addDays(baseSerie.harvest.begin.firstDay, -1)),
      );
      return {
        needs: sumNumberMaps(needs, baseSerie.needs),
        needsIndexes: {
          ...needsIndexes,
          ...Object.fromEntries(
            Object.keys({
              ...baseSerie.harvestWeeks,
              ...baseSerie.storageWeeks,
            }).map((weekKey) => [weekKey, weekKey in needsIndexes ? 2 : serieIndex]),
          ),
        },
        implantationWeeks: serieImplantationPeriod.weeks.reduce((implantationWeeks, week) => {
          implantationWeeks[getWeekKey(week)] = true;
          return implantationWeeks;
        }, implantationWeeks),
        harvestWeeks: Object.assign(harvestWeeks, baseSerie.harvestWeeks),
        storageWeeks: Object.assign(storageWeeks, baseSerie.storageWeeks),
      };
    },
    { needs: {}, needsIndexes: {}, implantationWeeks: {}, harvestWeeks: {}, storageWeeks: {} } as Pick<
      ProductSerie,
      'needs' | 'needsIndexes' | 'implantationWeeks' | 'harvestWeeks' | 'storageWeeks'
    >,
  );

  const serie: ProductSerie = {
    isSelected,
    rowId: `${childProduct.rowId}-${baseSerie.id}`,
    productRowId: childProduct.rowId,
    childItineraryId: childProduct.childCropItineraryId,
    parentItineraryId: childProduct.parentCropItineraryId,
    plantFamilyColors: childProduct.plantFamilyColors,
    cultureMode: childProduct.cultureMode,
    implantationWeek: baseSerie.begin,
    // rounding first to avoid calculation errors from javascript floating point arithmetics (is it really useful ? better safe than sorry...)
    maturationDuration: ceil(round(baseSerie.matureDays / 7, 2)),
    harvestDuration: ceil(round(baseSerie.harvestDays / 7, 2)),
    storageDuration: ceil(round(baseSerie.storageDays / 7, 2)),
    harvestQuantityUnit: childProduct.quantityUnit,
    expectedYield: baseSerie.expectedYield,
    lossMargin: baseSerie.expectedLostRate,
    surface: baseSerie.editedSurfaceNeeds ?? baseSerie.computedSurfaceNeeds,
    computedSurface: baseSerie.computedSurfaceNeeds,
    editedSurface: baseSerie.editedSurfaceNeeds,
    bedLength: null, // TODO get bed width
    quantity: computeNumberMapTotal(baseSerie.needs),
    computedQuantity: baseSerie.editedSurfaceNeeds != null ? Math.floor(computeSerieNeededVolume(baseSerie)) : null,
    implantationWeeks,
    harvestWeeks,
    storageWeeks,
    needs,
    needsIndexes,
  };
  return serie;
}

function getSeriesState(
  baseState: BaseProjectPageState,
  editedProductRowId: string,
  displayAllProducts: boolean,
): ComputedSeriesPageState {
  const { selectedParentProducts } = baseState;
  const parentProjectProducts = sortBy(Object.values(selectedParentProducts), ({ name }) => name);
  const selectedProducts = editedProductRowId
    ? [parentProjectProducts.find((parentProduct) => parentProduct.parentCropItineraryId === editedProductRowId)]
    : displayAllProducts
    ? parentProjectProducts
    : parentProjectProducts.filter((parent) =>
        Object.values(parent.productionNeedsByOutletRowId).some(
          (needs) =>
            Object.values(needs.weeklyNeeds).some(identity) ||
            Object.values(parent.selectedChildrenByRowId).some((child) =>
              child.series.some((serie) => serie.expectedVolume || serie.editedSurfaceNeeds),
            ),
        ),
      );
  const seriesProducts = selectedProducts.map((parentProduct) => {
    const childrenProducts = displayAllProducts
      ? [
          ...Object.values(parentProduct.selectedChildrenByRowId).map((child) => [child, true] as const),
          ...Object.values(parentProduct.otherPossibleChildrenByChildItineraryId).map(
            (child) => [child, false] as const,
          ),
        ]
      : Object.values(parentProduct.selectedChildrenByRowId).map((child) => [child, true] as const);

    const { series, availabilityPeriods, weeklyNeedsFromAllSeries, hasEditedSurface, computedQuantity } =
      childrenProducts.reduce(
        (acc, [childProduct, isSelected]) => {
          const serie = buildProductSerie(childProduct, isSelected);
          acc.availabilityPeriods = mergeMaybeOverlappingPeriods(
            acc.availabilityPeriods,
            buildProductAvailabilityPeriod(childProduct),
          );
          acc.series.push(serie);
          acc.weeklyNeedsFromAllSeries = sumNumberMaps(acc.weeklyNeedsFromAllSeries, serie.needs);
          if (serie.computedQuantity != null) {
            acc.hasEditedSurface = true;
            acc.computedQuantity += serie.computedQuantity;
          } else {
            acc.computedQuantity += serie.quantity;
          }
          return acc;
        },
        {
          series: [] as Array<ProductSerie>,
          availabilityPeriods: [] as ProductAvailability[],
          weeklyNeedsFromAllSeries: parentProduct.purchaseResale?.weeklyNeeds || {},
          hasEditedSurface: false,
          computedQuantity: 0,
        },
      );
    const weeklyNeedsFromAllOutlets = Object.values(parentProduct.productionNeedsByOutletRowId).reduce(
      (weeklyNeedsFromAllOutlets, outletNeeds) => {
        return outletNeeds
          ? sumNumberMaps(weeklyNeedsFromAllOutlets, outletNeeds.weeklyNeeds)
          : weeklyNeedsFromAllOutlets;
      },
      {} as NumberMap,
    );
    const cultureMode: CultureMode = series.reduce(
      (cultureMode, serie, serieIndex) =>
        serieIndex === 0 || serie.cultureMode === cultureMode ? cultureMode : 'Mixed',
      null as CultureMode,
    );

    const purchaseResaleTotal = parentProduct.purchaseResale
      ? computeNumberMapTotal(parentProduct.purchaseResale.weeklyNeeds)
      : 0;
    const seriesProduct: SeriesProduct = {
      name: parentProduct.name,
      parentCropItineraryId: parentProduct.parentCropItineraryId,
      plantFamilyColors: parentProduct.plantFamilyColors,
      availabilityPeriods,
      cultureMode,
      harvestQuantityUnit: parentProduct.quantityUnit,
      quantity: computeNumberMapTotal(filterNeedsInProjectPeriod(weeklyNeedsFromAllSeries, baseState.time)),
      computedQuantity: hasEditedSurface ? Math.round(computedQuantity + purchaseResaleTotal) : null,
      series: sortBy(
        series,
        (serie) => serie.implantationWeek.firstDay.getTime(),
        (serie) => serie.maturationDuration,
        (serie) => serie.harvestDuration,
        (serie) => serie.storageDuration || 0,
        (serie) => serie.productRowId,
      ),
      purchaseResale: parentProduct.purchaseResale,
      purchaseResaleTotal,
      outletsTotal: computeNumberMapTotal(weeklyNeedsFromAllOutlets),
      weeklyNeedsFromAllOutlets,
      weeklyNeedsFromAllSeries,
    };
    return seriesProduct;
  });
  return {
    seriesProducts,
  };
}

function filterNeedsInProjectPeriod(needs: NumberMap, time: MonthInterval): NumberMap {
  const beginWeek = time.weeks[0];
  const endWeek = last(time.weeks);
  return Object.fromEntries(
    Object.entries(needs).filter(([weekKey]) => {
      const [year, weekNumber] = weekKey.split('-').map(Number);
      return (
        (year > beginWeek.year || (year === beginWeek.year && weekNumber >= beginWeek.weekNumber)) &&
        (year < endWeek.year || (year === endWeek.year && weekNumber <= endWeek.weekNumber))
      );
    }),
  );
}

export function enterSeriesPage(
  baseState: BaseProjectPageState,
  previousState: ProjectPageState<SeriesPageState>,
): SeriesPageState {
  const editedSeriesProductRowId = previousState?.editedSeriesProductRowId || null;
  const displayAllProducts = previousState?.displayAllProducts || false;
  return {
    expandedSeriesRows: previousState?.expandedSeriesRows || {},
    editedSeriesProductRowId,
    displayedColumns: previousState?.displayedColumns,
    displayOutletNeeds: true, //fullState?.displayOutletNeeds || false,
    displayAllProducts,
    displayPurchaseResale: previousState?.displayPurchaseResale,
    editedSerieCell: null,
    seriesCommandConfirmationModal: null,
    ...getSeriesState(baseState, editedSeriesProductRowId, displayAllProducts),
  };
}
export function leaveSeriesPage(state: ProjectPageState<SeriesPageState>): BaseProjectPageState {
  const {
    editedSerieCell,
    // expandedSeriesRows,
    // editedSeriesProductRowId,
    // displayOutletNeeds,
    // displayedColumns,
    // displayAllProducts,
    // displayPurchaseResale,
    seriesCommandConfirmationModal,
    seriesProducts,
    ...baseState
  } = state;
  return baseState;
}
export function updateSeriesState(updatedState: ProjectPageState<SeriesPageState>): SeriesPageState {
  return {
    ...updatedState,
    ...getSeriesState(updatedState, updatedState.editedSeriesProductRowId, updatedState.displayAllProducts),
  };
}
