import React, { ReactNode } from 'react';
import { FieldPath, FieldValues } from 'react-hook-form';
import { Without } from '../../utils/types';
import { CheckboxVariant } from './base/BaseCheckbox';
import { ControlledHookedField, ControlledHookedFieldProps } from './base/ControlledHookedField';
import { BaseFieldProps } from './base/types';
import { Field } from './Field';
import { Radio } from './Radio';

type RadioGroupProps<T> = BaseFieldProps<T> & {
  variant?: CheckboxVariant;
  options: T[];
  getOptionLabel: (value: T) => ReactNode;
  getOptionId: (value: T) => string;
};

export function RadioGroup<T>({
  className,
  disabled,
  error,
  label,
  onValueChange,
  required,
  value,
  // forwardedRef,
  onBlur,
  getOptionLabel,
  getOptionId,

  options,
  variant = 'border',
}: RadioGroupProps<T>) {
  return (
    <Field
      $disabled={disabled}
      $error={!!error}
      $hasValue={!!value}
      className={className}
    >
      {label && (
        <label>
          {label}
          {required && ' *'}
        </label>
      )}
      {options.map((optionValue) => (
        <Radio
          key={getOptionId(optionValue)}
          className="checkbox"
          value={value === optionValue}
          onValueChange={(checked) => checked && onValueChange(optionValue)}
          label={getOptionLabel(optionValue)}
          variant={variant}
          disabled={disabled}
          error={error}
          onBlur={onBlur}
          required={required}
        />
      ))}
    </Field>
  );
}

export function HookedRadioGroup<T, Form extends FieldValues>(
  props: ControlledHookedFieldProps<
    FieldPath<Form>,
    // @ts-ignore
    T,
    Form,
    Without<RadioGroupProps<T>, 'onValueChange'>
  >,
) {
  return (
    // @ts-ignore
    <ControlledHookedField
      {...props}
      // onValueChange={(value) => setValue(props.fieldName, value)}
      // @ts-ignore
      Child={RadioGroup}
    />
  );
}
