import React, { FunctionComponent, ReactNode, SetStateAction } from 'react';
import styled from 'styled-components';
import { ReactComponent as IconDelete } from '../../../assets/icon-delete.svg';
import { colorStyle } from '../../style/colors';
import { PRIMARY_TOPBAR_HEIGHT } from '../../style/constants';
import { getTextStyle } from '../../style/text';
import { Svg } from '../Svg';

const Container = styled.div`
  position: fixed;
  z-index: 500;
  top: ${PRIMARY_TOPBAR_HEIGHT};
  right: 0;
  width: 324px;
  background-color: ${colorStyle.greys['8']};
  height: calc(100vh - ${PRIMARY_TOPBAR_HEIGHT});
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;
const Content = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 50px;
    background-color: ${colorStyle.cta1['1-active']};
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${colorStyle.cta1['1-active']};
  }
`;
const MainTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${colorStyle.greys['1-black']};
  padding: 12px;
`;
const Title = styled.span`
  ${getTextStyle(12, 'medium')}
`;

export interface IPanelBaseProps {
  panelTitle: string;
  handlePanelOpening: (value: SetStateAction<boolean>) => void;
  children: ReactNode;
}

const PanelBase: FunctionComponent<IPanelBaseProps> = (props) => {
  return (
    <Container>
      <MainTitle>
        <Title>{props.panelTitle}</Title>
        <button onClick={() => props.handlePanelOpening(false)}>
          <Svg
            value={IconDelete}
            size={12}
          />
        </button>
      </MainTitle>
      <Content>{props.children}</Content>
    </Container>
  );
};

PanelBase.defaultProps = {};

export default PanelBase;
