import config from '../../config';

async function triggerRefreshToken(currentToken: string): Promise<string | null> {
  const response = await fetch(`${config.API_URL}/refreshToken`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json',
    },
    body: JSON.stringify({ token: currentToken }),
  });
  if (response.status === 200) {
    const res = await response.json();
    return res.token;
  } else {
    return null;
  }
}

/**
 * Execute initial graphql request
 * If invalid token error, we try refreshing token and re-executing same request
 * (thanks to https://lucasmcgartland.medium.com/refreshing-token-based-authentication-with-apollo-client-2-0-7d45c20dc703)
 *
 * @param uri
 * @param options
 * @returns
 */
export async function customRequestFetch(uri: any, options: any): Promise<Response> {
  const initialResponse = await fetch(uri, options);

  let refreshingPromise: Promise<string> | null = null;

  const cloneResponse = initialResponse.clone();

  const initialResult = await initialResponse.json();

  const invalidTokenError =
    initialResult.errors && initialResult.errors.find((error: Error) => error.message === 'Invalid token');
  if (invalidTokenError) {
    if (!refreshingPromise) {
      const token = localStorage.getItem(config.AUTH_TOKEN);
      refreshingPromise = triggerRefreshToken(token);
    }
    const newAccessToken = await refreshingPromise;

    refreshingPromise = null;

    if (!newAccessToken) {
      localStorage.removeItem(config.AUTH_TOKEN);
      return cloneResponse;
    }

    localStorage.setItem(config.AUTH_TOKEN, newAccessToken);
    options.headers.authorization = `Bearer ${newAccessToken}`;

    return await fetch(uri, options);
  }

  return cloneResponse;
}
