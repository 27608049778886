import { useTranslation } from '@elzeard/common-components';
import { PlotPosition, SelectedPlot } from '@elzeard/common-planning';
import React, { Dispatch } from 'react';
import { ConfirmationModal } from '../../../common/components/modal/ConfirmationModal';
import { useDeletePlotMutation } from '../../../common/generated/graphql';
import { AllProjectCommands } from '../../commands';
import { ChildProduct, ParentProduct } from '../../state';
import { useProjectBaseState } from '../../state-context';
import { buildUpdateProjectInput } from '../../state-save';
import { usePositionApplyCommand, usePositionState } from '../state-context';

export function DeletePlotModal({ deletedPlot, onClose }: { deletedPlot: SelectedPlot; onClose: () => void }) {
  const { t } = useTranslation();

  const baseState = useProjectBaseState();
  const { farmId, selectedParentProducts, seriesById } = usePositionState();
  const applyCommand = usePositionApplyCommand() as Dispatch<AllProjectCommands>;

  const [
    triggerDeletePlotMutation,
    {
      loading,
      // TODO error
    },
  ] = useDeletePlotMutation({
    onCompleted({ updatePepiniereProject: { updatedObject } }) {
      applyCommand({
        type: 'saveSuccess',
        updatedObject,
      });
      onClose();
    },
    onError(error) {
      applyCommand({
        type: 'saveError',
        error,
      });
    },
  });

  return (
    <ConfirmationModal
      title={t('position:plot-modal.delete.title')}
      details={
        <>
          <p>{t('position:plot-modal.delete.confirm-delete', { plotName: deletedPlot.plot.plotName })}</p>
          <p>{t('position:plot-modal.delete.project-will-be-saved')}</p>
        </>
      }
      loading={loading}
      onCancel={onClose}
      onConfirm={() => {
        let deletedPositions: {
          parentProduct: ParentProduct;
          childProduct: ChildProduct;
        }[];
        const getDeletedPosition = ({ position, serieId }: PlotPosition) => {
          const serie = seriesById[serieId];
          const parentProduct = selectedParentProducts[serie.parentCropItineraryId];
          const childProduct = parentProduct.selectedChildrenByRowId[serie.childProductRowId];
          return {
            childProduct,
            parentProduct,
          };
        };
        if ('beds' in deletedPlot) {
          deletedPositions = deletedPlot.plot.beds.flatMap((bed) => bed.positions.map(getDeletedPosition));
        } else {
          deletedPositions = deletedPlot.plot.positions.map(getDeletedPosition);
        }
        const projectInput = buildUpdateProjectInput(
          deletedPositions.reduce((state, deletedPosition) => {
            const updatedChildProduct: ChildProduct = {
              ...deletedPosition.childProduct,
              isUpdated: true,
              series: deletedPosition.childProduct.series.map((serie) => ({
                ...serie,
                isUpdated: true,
                positions: serie.positions.filter((position) => position.plotId !== deletedPlot.plot.plotId),
              })),
            };
            const updatedParent: ParentProduct = {
              ...deletedPosition.parentProduct,
              isUpdated: true,
              selectedChildrenByRowId: {
                ...deletedPosition.parentProduct.selectedChildrenByRowId,
                [updatedChildProduct.rowId]: updatedChildProduct,
              },
            };
            return {
              ...state,
              selectedParentProducts: {
                ...state.selectedParentProducts,
                [updatedParent.parentCropItineraryId]: updatedParent,
              },
            };
          }, baseState),
        );
        triggerDeletePlotMutation({
          variables: {
            plotId: deletedPlot.plot.plotId,
            farmId,
            projectInput
          },
        });
      }}
    />
  );
}
