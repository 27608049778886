import { TFunction } from '@elzeard/common-components';
import {
  ClientCategoryEnum,
  ClimateEnum,
  CultivationEnum,
  CultureModeEnum, DensityUnitEnum,
  DosageUnitEnum, FarmEquipmentLevelEnum,
  FarmingSystemEnum, HarvestUnitEnum, IrrigationModeEnum, LengthUnitEnum, RegionEnum,
  SeasonalityEnum,
  SoilNatureEnum,
  SoilTextureEnum, SurfaceUnitEnum,
  TemporalUnitEnum,
  WorkloadUnitEnum,
  YieldUnitEnum
} from '@elzeard/shared-dimensions';

function translate(t: TFunction, baseKey: string, value: string) {
  const suffix = value?.split(':')[1];
  return suffix && t(`shared:${baseKey}.${suffix}`);
}
export function translateClientCategoryEnum(t: TFunction, value: ClientCategoryEnum) {
  return translate(t, 'individuals.client-category', value);
}
export function translateClimateEnum(t: TFunction, value: ClimateEnum) {
  return translate(t, 'individuals.climate', value);
}
export function translateCultivationEnum(t: TFunction, value: CultivationEnum) {
  return translate(t, 'individuals.cultivation-practice', value);
}
export function translateCultureModeEnum(t: TFunction, value: CultureModeEnum) {
  return translate(t, 'individuals.culture-mode', value);
}
export function translateFarmEquipmentLevelEnum(t: TFunction, value: FarmEquipmentLevelEnum) {
  return translate(t, 'individuals.farm-equipment-level', value);
}
export function translateFarmingSystemEnum(t: TFunction, value: FarmingSystemEnum) {
  return translate(t, 'individuals.farming-system', value);
}
export function translateIrrigationModeEnum(t: TFunction, value: IrrigationModeEnum) {
  return translate(t, 'individuals.irrigation-mode', value);
}
export function translateRegionEnum(t: TFunction, value: RegionEnum) {
  return translate(t, 'individuals.region', value);
}
export function translateSeasonalityEnum(t: TFunction, value: SeasonalityEnum) {
  return translate(t, 'individuals.season', value);
}
export function translateSoilNatureEnum(t: TFunction, value: SoilNatureEnum) {
  return translate(t, 'individuals.soil-nature', value);
}
export function translateSoilTextureEnum(t: TFunction, value: SoilTextureEnum) {
  return translate(t, 'individuals.soil-texture', value);
}
export function translateDensityUnitEnum(t: TFunction, value: DensityUnitEnum) {
  return translate(t, 'units.density', value);
}
export function translateDosageUnitEnum(t: TFunction, value: DosageUnitEnum) {
  return translate(t, 'units.dosage', value);
}
export function translateHarvestUnitEnum(
  t: TFunction,
  value: HarvestUnitEnum,
  option: 'long' | 'short',
) {
  return translate(t, 'units.harvest-quantity.' + option, value);
}
export function translateLengthUnitEnum(t: TFunction, value: LengthUnitEnum, option: 'long' | 'short') {
  return translate(t, 'units.length.' + option, value);
}
export function translateSurfaceUnitEnum(t: TFunction, value: SurfaceUnitEnum) {
  return translate(t, 'units.surface', value);
}
export function translateTemporalUnitEnum(t: TFunction, value: TemporalUnitEnum, option: 'long') {
  return translate(t, 'units.time.' + option, value);
}
export function translateWorkloadUnitEnum(t: TFunction, value: WorkloadUnitEnum) {
  return translate(t, 'units.workload', value);
}
export function translateYieldUnitEnum(t: TFunction, value: YieldUnitEnum) {
  return translate(t, 'units.yield', value);
}
