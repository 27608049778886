import { useTranslation } from '@elzeard/common-components';
import React, { Dispatch, useMemo } from 'react';
import styled from 'styled-components';
import { colorStyle } from '../../../common/style/colors';
import { getTextStyle } from '../../../common/style/text';
import { timelineCell, weekWidth } from '../../table/style';
import { AllOutletCommands } from '../commands';
import { useOutletsCommands } from '../state-context';
import { EditedCell } from './EditedCell';
import { outletsHeights } from './style';

const Container = styled.td<{ isDisabled: boolean }>`
  height: ${outletsHeights.targetRow};
  ${timelineCell}
  ${getTextStyle(12, 'regular')}
  color: ${colorStyle.greys['1-black']};
  position: relative;
  ${({ isDisabled }) => (isDisabled ? '' : 'cursor: pointer;')}
  .content {
    width: ${weekWidth - 1}px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

interface EditableTargetCellProps {
  outletRowId: string;
  weekKey: string;
  value: number;
  isEditing: boolean;
  isDisabled: boolean;
}

export function EditableTargetCell({ weekKey, outletRowId, value, isDisabled, isEditing }: EditableTargetCellProps) {
  const { formatNumber } = useTranslation();
  const applyCommand = useOutletsCommands();

  const formattedValue = value && formatNumber(value, 0, 2);
  return (
    <Container
      key={weekKey}
      isDisabled={isDisabled}
      onClick={
        isDisabled || isEditing
          ? undefined
          : () =>
              applyCommand({
                type: 'setEditedCell',
                outletRowId,
                weekKey,
                parentCropItineraryId: null,
                editedProperty: null,
              })
      }
    >
      {!isDisabled && isEditing ? (
        <EditedTargetCell
          outletRowId={outletRowId}
          weekKey={weekKey}
          value={value}
          applyCommand={applyCommand}
        />
      ) : (
        <div className="content">{formattedValue || '-'}</div>
      )}
    </Container>
  );
}
interface EditedTargetCellProps {
  outletRowId: string;
  weekKey: string;
  value: number;
  applyCommand: Dispatch<AllOutletCommands>;
}
function EditedTargetCell({ outletRowId, weekKey, value, applyCommand }: EditedTargetCellProps) {
  const { quitEdition, editProperty } = useMemo(() => {
    return {
      quitEdition: () =>
        applyCommand({
          type: 'setEditedCell',
          outletRowId: null,
          parentCropItineraryId: null,
          editedProperty: null,
          weekKey: null,
        }),
      editProperty: (value: number) =>
        applyCommand({
          type: 'editTargetSales',
          outletRowId,
          weekKey,
          value,
        }),
    };
  }, [applyCommand, outletRowId, weekKey]);

  return (
    <EditedCell
      value={value || null}
      quitEdition={quitEdition}
      editProperty={editProperty}
    />
  );
}
