import React from 'react';
import Ail_fraisPicture from '../../assets/plant-pictures/1900x100/ail frais.jpg';
import Ail_conservationPicture from '../../assets/plant-pictures/1900x100/ail_conservation.jpg';
import Aromatiques_diversesPicture from '../../assets/plant-pictures/1900x100/aromatiques_diverses.jpg';
import AuberginePicture from '../../assets/plant-pictures/1900x100/aubergine.jpg';
import Betterave_fraisPicture from '../../assets/plant-pictures/1900x100/betterave frais.jpg';
import Betterave_conservationPicture from '../../assets/plant-pictures/1900x100/betterave_conservation.jpg';
import BlettesPicture from '../../assets/plant-pictures/1900x100/blettes.jpg';
import BrocoliPicture from '../../assets/plant-pictures/1900x100/brocoli.jpg';
import Carotte_conservationPicture from '../../assets/plant-pictures/1900x100/carotte_conservation.jpg';
import Carotte_fraisPicture from '../../assets/plant-pictures/1900x100/carotte_frais.jpg';
import Celeri_branchePicture from '../../assets/plant-pictures/1900x100/celeri_branche.jpg';
import Celeri_ravePicture from '../../assets/plant-pictures/1900x100/celeri_rave.jpg';
import Chicoree_frisee_scarolePicture from '../../assets/plant-pictures/1900x100/chicoree_frisee_scarole.jpg';
import Chou_chinoisPicture from '../../assets/plant-pictures/1900x100/chou_chinois.jpg';
import Chou_de_bruxellesPicture from '../../assets/plant-pictures/1900x100/chou_de_bruxelles.jpg';
import Chou_fleurPicture from '../../assets/plant-pictures/1900x100/chou_fleur.jpg';
import Chou_frise_kalePicture from '../../assets/plant-pictures/1900x100/chou_frise_kale.jpg';
import Chou_pommePicture from '../../assets/plant-pictures/1900x100/chou_pomme.jpg';
import Chou_ravePicture from '../../assets/plant-pictures/1900x100/chou_rave.jpg';
import ConcombrePicture from '../../assets/plant-pictures/1900x100/concombre.jpg';
import CourgePicture from '../../assets/plant-pictures/1900x100/courge.jpg';
import CourgettePicture from '../../assets/plant-pictures/1900x100/courgette.jpg';
import EchalottePicture from '../../assets/plant-pictures/1900x100/echalotte.jpg';
import EndivePicture from '../../assets/plant-pictures/1900x100/endive.jpg';
import EpinardPicture from '../../assets/plant-pictures/1900x100/epinard.jpg';
import FenouilPicture from '../../assets/plant-pictures/1900x100/fenouil.jpg';
import FevePicture from '../../assets/plant-pictures/1900x100/feve.jpg';
import FraisePicture from '../../assets/plant-pictures/1900x100/fraise.jpg';
import HaricotPicture from '../../assets/plant-pictures/1900x100/haricot vert.jpg';
import Laitue_saladePicture from '../../assets/plant-pictures/1900x100/laitue_salade.jpg';
import MelonPicture from '../../assets/plant-pictures/1900x100/melon.jpg';
import Navet_conservationPicture from '../../assets/plant-pictures/1900x100/navet_conservation.jpg';
import Navet_fraisPicture from '../../assets/plant-pictures/1900x100/navet_frais.jpg';
import Oignon_conservationPicture from '../../assets/plant-pictures/1900x100/oignon_conservation.jpg';
import Oignon_fraisPicture from '../../assets/plant-pictures/1900x100/oignon_frais.jpg';
// import Oignon_saisonPicture from '../../assets/plant-pictures/1900x100/oignon_saison.jpg';
import PanaisPicture from '../../assets/plant-pictures/1900x100/panais.jpg';
import Petit_poisPicture from '../../assets/plant-pictures/1900x100/petit_pois.jpg';
import PimentPicture from '../../assets/plant-pictures/1900x100/piment.jpg';
import PoireauPicture from '../../assets/plant-pictures/1900x100/poireau.jpg';
import PoivronPicture from '../../assets/plant-pictures/1900x100/poivron.jpg';
import Pomme_de_terre_primeurPicture from '../../assets/plant-pictures/1900x100/pomme de terre primeur.jpg';
import Pomme_de_terre_conservationPicture from '../../assets/plant-pictures/1900x100/pomme_de_terre_conservation.jpg';
import PourpierPicture from '../../assets/plant-pictures/1900x100/pourpier.jpg';
import Radis_conservationPicture from '../../assets/plant-pictures/1900x100/radis_conservation.jpg';
import Radis_fraisPicture from '../../assets/plant-pictures/1900x100/radis_frais.jpg';
import RutabagaPicture from '../../assets/plant-pictures/1900x100/rutabaga.jpg';
import Tomate_anciennePicture from '../../assets/plant-pictures/1900x100/tomate_ancienne.jpg';
import Tomate_cerisePicture from '../../assets/plant-pictures/1900x100/tomate_cerise.jpg';
import Tomate_classiquePicture from '../../assets/plant-pictures/1900x100/tomate_classique.jpg';
import Verdures_diversesPicture from '../../assets/plant-pictures/1900x100/verdures_diverses.jpg';

const picturesByCropItineraryId: Record<string, string> = {
  'c3pokb:StorageGarlic100-i': Ail_conservationPicture, // 'Ail conservation'
  'c3pokb:FreshGarlic115-i': Ail_fraisPicture, // 'Ail frais'
  'c3pokb:Eggplant123-i': AuberginePicture, // 'Aubergine'
  'c3pokb:Basil131-i': Aromatiques_diversesPicture, // 'Basilic'
  'c3pokb:StorageBeet136-i': Betterave_conservationPicture, // 'Betterave conservation'
  'c3pokb:FreshBeet142-i': Betterave_fraisPicture, // 'Betterave frais'
  'c3pokb:Chard163-i': BlettesPicture, // 'Blette'
  'c3pokb:Broccoli176-i': BrocoliPicture, // 'Brocoli'
  'c3pokb:StorageCarrot190-i': Carotte_conservationPicture, // 'Carotte conservation'
  'c3pokb:FreshCarrot197-i': Carotte_fraisPicture, // 'Carotte frais'
  'c3pokb:CurlyChicory242-i': Chicoree_frisee_scarolePicture, // 'Chicorée frisée-scarole'
  'c3pokb:Romanesco333-i': null, // 'Chou Romanesco'
  'c3pokb:SharpHeadedCabbage253-i': null, // 'Chou cabus-pommé-pointu'
  'c3pokb:ChineseCabbage260-i': Chou_chinoisPicture, // 'Chou chinois'
  'c3pokb:BrusselSprout278-i': Chou_de_bruxellesPicture, // 'Chou de Bruxelles'
  'c3pokb:KaleCabbage285-i': Chou_pommePicture, // 'Chou de Milan - frisé pommé'
  'c3pokb:Kale286-i': Chou_frise_kalePicture, // 'Chou frisé-kale'
  'c3pokb:NonHeadedKale292-i': Chou_frise_kalePicture, // 'Chou kale-frisé non pommé'
  'c3pokb:HeadedCabbage296-i': Chou_pommePicture, // 'Chou pommé'
  'c3pokb:Kohlrabi313-i': Chou_ravePicture, // 'Chou rave'
  'c3pokb:Cauliflower337-i': Chou_fleurPicture, // 'Chou-fleur'
  'c3pokb:Chive351-i': Aromatiques_diversesPicture, // 'Ciboulette'
  'c3pokb:Cucumber354-i': ConcombrePicture, // 'Concombre'
  'c3pokb:Coriander369-i': Aromatiques_diversesPicture, // 'Coriandre'
  'c3pokb:Squash378-i': CourgePicture, // 'Courge'
  'c3pokb:Courgette386-i': CourgettePicture, // 'Courgette'
  'c3pokb:Celery219-i': Celeri_branchePicture, // 'Céleri branche'
  'c3pokb:Celeriac230-i': Celeri_ravePicture, // 'Céleri rave'
  'c3pokb:Shallot402-i': EchalottePicture, // 'Echalote'
  'c3pokb:Endive409-i': EndivePicture, // 'Endive'
  'c3pokb:Spinach415-i': EpinardPicture, // 'Epinard'
  'c3pokb:Fennel441-i': FenouilPicture, // 'Fenouil'
  'c3pokb:Strawberry477-i': FraisePicture, // 'Fraise'
  'c3pokb:BroadBean468-i': FevePicture, // 'Fève'
  'c3pokb:DryBean480-i': null, // 'Haricot sec ou demi-sec'
  'c3pokb:GreenBean486-i': HaricotPicture, // 'Haricot vert'
  'c3pokb:Lettuce512-i': Laitue_saladePicture, // 'Laitue-salade'
  'c3pokb:Corn603-i': null, // 'Maïs doux'
  'c3pokb:Melon608-i': MelonPicture, // 'Melon'
  'c3pokb:Mesclun623-i': Verdures_diversesPicture, // 'Mesclun'
  'c3pokb:CornSalad573-i': null, // 'Mâche'
  'c3pokb:StorageTurnip650-i': Navet_conservationPicture, // 'Navet conservation'
  'c3pokb:FreshTurnip656-i': Navet_fraisPicture, // 'Navet frais'
  'c3pokb:StorageWhiteOnion676-i': null, // 'Oignon blanc conservation'
  'c3pokb:BunchingOnion681-i': Oignon_fraisPicture, // 'Oignon botte-ciboule'
  'c3pokb:OpenFieldBunchingOnion964-i': Oignon_fraisPicture, // 'Oignon botte-ciboule plein champ',
  'c3pokb:ClassicStorageOnion700-i': Oignon_conservationPicture, // 'Oignon conservation général',
  'c3pokb:OpenFieldClassicStorageOnion967-i': Oignon_conservationPicture, // 'Oignon conservation général',
  'c3pokb:ColoredStorageOnion718-i': null, // 'Oignon couleur conservation',
  'c3pokb:Parsnip722-i': PanaisPicture, // 'Panais'
  'c3pokb:WaterMelon729-i': null, // 'Pastèque'
  'c3pokb:SweetPotato734-i': null, // 'Patate douce'
  'c3pokb:Parsley738-i': Aromatiques_diversesPicture, // 'Persil'
  'c3pokb:Peas747-i': Petit_poisPicture, // 'Petit pois'
  'c3pokb:SnowPeas761-i': Petit_poisPicture, // 'Petit pois et mange tout'
  'c3pokb:ChilliPepper765-i': PimentPicture, // 'Piment'
  'c3pokb:Leek768-i': PoireauPicture, // 'Poireau'
  'c3pokb:Pepper791-i': PoivronPicture, // 'Poivron'
  'c3pokb:StoragePotato801-i': Pomme_de_terre_conservationPicture, // 'Pomme de terre conservation'
  'c3pokb:FreshPotato809-i': Pomme_de_terre_primeurPicture, // 'Pomme de terre primeur'
  'c3pokb:Purslane820-i': PourpierPicture, // 'Pourpier'
  'c3pokb:StorageRadish842-i': Radis_conservationPicture, // 'Radis conservation'
  'c3pokb:FreshRadish852-i': Radis_fraisPicture, // 'Radis frais'
  'c3pokb:Rutabaga914-i': RutabagaPicture, // 'Rutabaga'
  'c3pokb:HeirloomTomato919-i': Tomate_anciennePicture, // 'Tomate ancienne'
  'c3pokb:CherryTomato935-i': Tomate_cerisePicture, // 'Tomate cerise'
  'c3pokb:ClassicTomato951-i': Tomate_classiquePicture, // 'Tomate classique'
};

export interface PlantPictureProps {
  cropItineraryId: string;
  name: string;
  className?: string;
}
export function PlantPicture({ cropItineraryId, name, className }: PlantPictureProps) {
  const picture = picturesByCropItineraryId[cropItineraryId];
  return (
    picture && (
      <img
        src={picture}
        alt={name}
        className={className}
      />
    )
  );
}
