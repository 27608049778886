import { useTranslation } from '@elzeard/common-components';
import { PlotBlock, SelectedPlot, SelectedPlotWithBeds } from '@elzeard/common-planning';
import React, { CSSProperties, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as ArrowDown } from '../../../assets/arrow-full-down.svg';
import { ReactComponent as ArrowRight } from '../../../assets/arrow-full-right.svg';
import { ReactComponent as FreeSurfaceIcon } from '../../../assets/implantation-mode-free-surface.svg';
import { ReactComponent as BedsIcon } from '../../../assets/implantation-mode-permanent-beds.svg';
import { Svg } from '../../../common/components/Svg';
import { colorStyle } from '../../../common/style/colors';
import { getTextStyle } from '../../../common/style/text';
import { cultureModeIcons } from '../../common/icons';
import { HeaderCellMenuButton } from '../../outlet/components/HeaderCellMenu';
import { DeletePlotModal } from '../plot-modal/DeletePlotModal';
import { DuplicatePlotModal } from '../plot-modal/DuplicatePlotModal';
import { usePositionApplyCommand, usePositionState } from '../state-context';
import { bedNumberWidth } from '../style';

const scenarioPeriodLeftStyle: CSSProperties = { display: 'none' };
const scenarioPeriodRightStyle: CSSProperties = { display: 'none' };

const StyledCollapsiblePlot = styled.div`
  .header {
    display: flex;
    height: 40px;
    padding-left: ${bedNumberWidth}px;

    & > * {
      display: flex;
      height: 100%;
      align-items: center;
    }

    .start {
      flex: auto;
      gap: 16px;
      ${getTextStyle(14, 'regular')}
      color: ${colorStyle.greys['2']};
    }
    .opener {
      display: flex;
      cursor: pointer;
      gap: 7px;
    }
    .icons {
      display: flex;
      align-items: center;
      gap: 4px;
      color: ${colorStyle.greys['4-defaultIcon']};
    }
    .end {
      flex: none;
      display: flex;
    }
  }
  .plot-beds {
    margin: 16px 0;

    .bed-number {
      display: flex;
      justify-content: center;
      align-items: center;
      width: ${bedNumberWidth}px;
      padding: 0;
      ${getTextStyle(12, 'regular')}
      color: ${colorStyle.greys['2']};
    }
    .bed-lanes {
      border-top: 1px solid ${colorStyle.greys['5']};
      border-bottom: 1px solid ${colorStyle.greys['5']};
    }
  }
`;

export function CollapsiblePlot({ plot, isMoving }: { plot: SelectedPlot; isMoving: boolean }) {
  const { formatNumber, t } = useTranslation();

  const applyCommand = usePositionApplyCommand();
  const { collapsedPlots, plotDisplayPeriod, weekWidth } = usePositionState();

  const { plotId, plotName, surface, cultureMode } = plot.plot;

  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const isCollapsed = collapsedPlots[plotId];
  return (
    <StyledCollapsiblePlot
      style={{
        width: `${plotDisplayPeriod.weeks.length * weekWidth + bedNumberWidth}px`,
      }}
    >
      <div className="header">
        <div className="start">
          <span
            className="opener"
            onClick={() =>
              applyCommand({
                type: 'toggleCollapsedPlot',
                plotId,
              })
            }
          >
            <Svg value={isCollapsed ? ArrowRight : ArrowDown} />
            <span>{plotName}</span>
          </span>
          <span>{formatNumber(surface)} m²</span>
          <span className="icons">
            <Svg value={cultureModeIcons[cultureMode]} />
            <Svg value={'beds' in plot ? BedsIcon : FreeSurfaceIcon} />
          </span>
        </div>
        <div className="end">
          <HeaderCellMenuButton
            label={t('position:plot-menu.button')}
            variant="CTA"
            isFilled
          >
            <div onClick={() => setShowDuplicateModal(true)}>{t('position:plot-menu.duplicate')}</div>
            <div onClick={() => setShowDeleteModal(true)}>{t('position:plot-menu.delete')}</div>
          </HeaderCellMenuButton>
        </div>
      </div>
      {!isCollapsed && (
        <PlotBlock
          isMoving={isMoving}
          selectedPlot={plot as SelectedPlotWithBeds} // it may be SelectedPlotWithoutBeds, this cast is just here to make eslint happy
          disabled={false}
          plotStyle={{
            width: '100%',
          }}
          scenarioPeriodLeftStyle={scenarioPeriodLeftStyle}
          scenarioPeriodRightStyle={scenarioPeriodRightStyle}
        />
      )}
      {showDuplicateModal && (
        <DuplicatePlotModal
          duplicatedPlotId={plotId}
          onClose={() => setShowDuplicateModal(false)}
        />
      )}
      {showDeleteModal && (
        <DeletePlotModal
          deletedPlot={plot}
          onClose={() => setShowDeleteModal(false)}
        />
      )}
    </StyledCollapsiblePlot>
  );
}
