import { useTranslation } from '@elzeard/common-components';
import { useSelect } from 'downshift';
import React, { ForwardedRef, forwardRef, PropsWithChildren } from 'react';
import styled from 'styled-components';
import { ReactComponent as ArrowDownIcon } from '../../../assets/arrow-empty-down.svg';
import { ReactComponent as ArrowRightIcon } from '../../../assets/arrow-empty-right.svg';
import { Svg } from '../../../common/components/Svg';
import { colorStyle } from '../../../common/style/colors';
import { getTextStyle } from '../../../common/style/text';
import { mainDisplayOptions } from '../state-build';
import { MainDisplayOption } from '../state-displayOptions';

const ButtonContainer = styled.div<Omit<ItemProps, 'value'>>`
  background-color: ${colorStyle.greys['9-white']};
  border-color: ${colorStyle.cta2['2']};
  border-width: 2px;
  border-style: solid;
  border-radius: 4px;
  :hover {
    color: ${colorStyle.cta2['2']};
    border-color: ${colorStyle.cta2['2']};
  }
  .svg {
    margin-right: 8px;
  }
`;
const MenuContainer = styled.div`
  background-color: ${colorStyle.greys['9-white']};
  position: absolute;
  right: 0;
  top: 48px;
  outline: 0;
  border: 2px solid ${colorStyle.cta2['2']};
  border-radius: 0px 0px 4px 4px;
  filter: drop-shadow(0px 4px 4px rgba(73, 98, 226, 0.5));
`;
const Container = styled.div`
  position: relative;
`;
const ItemContainer = styled.div<Omit<ItemProps, 'value'>>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ isHighlighted }) => (isHighlighted ? colorStyle.cta2['2'] : colorStyle.cta2['1'])};
  height: 40px;
  padding: 0 12px;
  min-width: 256px;
  cursor: pointer;

  .label {
    ${getTextStyle(16, 'semibold')}
    display: flex;
    align-items: center;
    white-space: nowrap;
  }
  .unit {
    ${getTextStyle(14, 'regular')}
    white-space: nowrap;
  }
`;

export interface MainDisplayOptionSelectorProps {
  value: MainDisplayOption;
  onChange: (value: MainDisplayOption) => void;
}

interface ItemProps {
  value: MainDisplayOption;
  isHighlighted?: boolean;
}

const Item = forwardRef(
  ({ value, children, ...otherProps }: PropsWithChildren<ItemProps>, ref: ForwardedRef<HTMLDivElement>) => {
    const { t } = useTranslation();
    return (
      <ItemContainer
        ref={ref}
        {...otherProps}
      >
        <div className="label">
          {children}
          {t(`outlet:home.topbar.main-display-option-selector.${value}.label`)}
        </div>
        <div className="unit">{t(`outlet:home.topbar.main-display-option-selector.${value}.unit`)}</div>
      </ItemContainer>
    );
  },
);

export function MainDisplayOptionSelector({ value, onChange }: MainDisplayOptionSelectorProps) {
  const { isOpen, selectedItem, getToggleButtonProps, getMenuProps, highlightedIndex, getItemProps } = useSelect({
    items: mainDisplayOptions,
    selectedItem: value,
    onSelectedItemChange: (changes) => onChange(changes.selectedItem),
  });
  return (
    <Container>
      <ButtonContainer>
        <Item
          value={value}
          {...getToggleButtonProps()}
        >
          <Svg
            value={isOpen ? ArrowDownIcon : ArrowRightIcon}
            size={16}
            className="svg"
          />
        </Item>
      </ButtonContainer>
      <div {...getMenuProps()}>
        {isOpen && (
          <MenuContainer>
            {mainDisplayOptions.map((item, index) =>
              selectedItem === item ? null : (
                <Item
                  key={`${item}${index}`}
                  value={item}
                  isHighlighted={highlightedIndex === index}
                  {...getItemProps({ item, index })}
                />
              ),
            )}
          </MenuContainer>
        )}
      </div>
    </Container>
  );
}
