import { ToHandlers } from '@elzeard/common-components';
import {
  BaseCultureCommands,
  baseCulturesCommandHandler,
  getCoordinatesDependentCulturesCommandHandler,
} from '@elzeard/common-planning';
import { ProjectPageState } from '../state';
import { PositionPageCommands } from './commands';
import { editionAdapter } from './edition-adapter';
import { planningSpacetimeStateAdapter } from './state-adapter-spacetime';
import { PositionPageState } from './state-full';

const baseHandler = baseCulturesCommandHandler as ToHandlers<ProjectPageState<PositionPageState>, BaseCultureCommands>;
const coordinatesDependentCulturesCommandHandler = getCoordinatesDependentCulturesCommandHandler(
  editionAdapter,
  planningSpacetimeStateAdapter,
);

export const positionPageCommandHandler: ToHandlers<ProjectPageState<PositionPageState>, PositionPageCommands> = {
  ...baseHandler,
  ...coordinatesDependentCulturesCommandHandler,
  setWeekWidth(previousState, command) {
    if (command.weekWidth === previousState.weekWidth) {
      return previousState;
    }
    return {
      ...previousState,
      weekWidth: command.weekWidth,
    };
  },
  toggleCollapsedPlot(previousState, command) {
    return {
      ...previousState,
      collapsedPlots: {
        ...previousState.collapsedPlots,
        [command.plotId]: !previousState.collapsedPlots[command.plotId],
      },
    };
  },
  navigatePlotPeriod(previousState, command) {
    const updatedState = baseHandler.navigatePlotPeriod(previousState, command);
    return {
      ...updatedState,
      ...editionAdapter.updatePlotState(
        updatedState.plots.map((plot) => plot.plot),
        updatedState,
      ),
      // weekWidth: getWeekWidth(plotDisplayPeriod),
    };
  },
};
