import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { colorStyle } from '../../style/colors';
import { PrimaryTopbar } from './PrimaryTopbar';

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${colorStyle.greys['7']};
`;

export function LayoutMain({ children }: PropsWithChildren<{}>) {
  return (
    <Container>
      {/* A "layout route" is a good place to put markup you want to
share across all the pages on your site, like navigation. */}
      <PrimaryTopbar />
      {children}
    </Container>
  );
}

export default LayoutMain;
