import { useBasePositionPlanningApplyCommand, useBasePositionPlanningState } from '@elzeard/common-planning';
import { Dispatch } from 'react';
import { AllBaseProjectCommands } from './commands';
import { ProjectPageState } from './state';

export function useProjectBaseState() {
  return useBasePositionPlanningState() as ProjectPageState<unknown>;
}
export function useCommonProjectCommands() {
  return useBasePositionPlanningApplyCommand() as Dispatch<AllBaseProjectCommands>;
}
