import { CommercialOutletEnum, PriceUnitEnum } from '@elzeard/shared-dimensions';
import { VariableWithMinMax } from '../shared-elzeard/variables';
import { DistributionCircuit, PriceLevel } from './state';
import { ParentItinerary } from './useCropItineraries';

const commercialOutletByDistributionCircuit = {
  [DistributionCircuit.Short]: CommercialOutletEnum.ShortSupplyChain,
  [DistributionCircuit.Long]: CommercialOutletEnum.LongSupplyChain,
} as const;

export function getPriceValueByLevel(price: VariableWithMinMax<PriceUnitEnum>, level: PriceLevel): number {
  if (!price || !level) {
    return null;
  }
  switch (level) {
    case PriceLevel.High:
      return price.max;
    case PriceLevel.Low:
      return price.min;
    case PriceLevel.Medium:
      return price.value;
  }
}
export function getItineraryPrices(
  cropItinerary: Pick<ParentItinerary, 'prices'>,
  distributionCircuit: DistributionCircuit,
) {
  if (!distributionCircuit || distributionCircuit === DistributionCircuit.Custom) {
    return null;
  }
  return cropItinerary.prices[commercialOutletByDistributionCircuit[distributionCircuit]];
}
