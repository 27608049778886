import { ApolloProvider } from '@apollo/client';
import { I18nProvider, TranslationProvider } from '@elzeard/common-components';
import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import { ViewCallBackAccountCreation } from '../auth/views/ViewCallBackAccountCreation';
import { ViewCreateAccount } from '../auth/views/ViewCreateAccount';
import { ViewForgottenPassword } from '../auth/views/ViewForgottenPassword';
import { ViewLogin } from '../auth/views/ViewLogin';
import { ViewResetPassword } from '../auth/views/ViewResetPassword';
import { LayoutAuthentication } from '../common/components/layouts/LayoutAuthentication';
import { LayoutMain } from '../common/components/layouts/LayoutMain';
import { AuthProvider, RequireAuth } from '../common/context/AuthContext';
import '../common/context/init-i18n';
import { useApolloClient } from '../common/hook/useApolloClient';
import { ProjectPage } from '../project/ProjectPage';
import { routes } from './routes';
import { ViewHome } from './views/ViewHome';
import { ViewNoMatch } from './views/ViewNoMatch';

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export function App() {
  const { client } = useApolloClient();
  if (!client) {
    return <>Initializing ApolloClient ...</>;
  }
  return (
    <BrowserRouter>
      <I18nProvider>
        <TranslationProvider>
          <ApolloProvider client={client}>
            <AuthProvider>
              <Container>
                <Switch>
                  <Route path={routes.login.root}>
                    <LayoutAuthentication>
                      <Switch>
                        <Route
                          exact
                          path={routes.login.root}
                          component={ViewLogin}
                        />
                        <Route
                          path={routes.login.createAccount}
                          component={ViewCreateAccount}
                        />
                        <Route
                          path={routes.login.forgotPassword}
                          component={ViewForgottenPassword}
                        />
                        <Route
                          path={routes.login.resetPassword}
                          component={ViewResetPassword}
                        />
                        <Route
                          path={routes.login.callbackAccountCreation}
                          component={ViewCallBackAccountCreation}
                        />
                      </Switch>
                    </LayoutAuthentication>
                  </Route>
                  <Route path="/">
                    <RequireAuth>
                      <Switch>
                        <Route path={routes.project.selected}>
                          <LayoutMain>
                            <Switch>
                              <Route
                                path={routes.project.details.root}
                                component={ProjectPage}
                              />
                              <Route
                                path={routes.project.selected}
                                component={ViewHome}
                              />
                            </Switch>
                          </LayoutMain>
                        </Route>
                        <Route path="/">
                          <LayoutMain>
                            <Switch>
                              <Route
                                exact
                                path="/"
                                component={ViewHome}
                              />
                              <Route
                                exact
                                path={routes.project.list}
                                component={ViewHome}
                              />
                              {/* Using path="*"" means "match anything", so this route
                              acts like a catch-all for URLs that we don't have explicit
                              routes for. 
                          */}
                              <Route
                                path="*"
                                component={ViewNoMatch}
                              />
                            </Switch>
                          </LayoutMain>
                        </Route>
                      </Switch>
                    </RequireAuth>
                  </Route>
                </Switch>
              </Container>
            </AuthProvider>
          </ApolloProvider>
        </TranslationProvider>
      </I18nProvider>
    </BrowserRouter>
  );
}

export default App;
