import React, { PropsWithChildren, useEffect, useState } from 'react';
import { Route, Switch, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { colorStyle } from '../../common/style/colors';
import { PRIMARY_TOPBAR_HEIGHT } from '../../common/style/constants';
import { ProjectEditPanel } from '../../project/list/edit/PanelEditProject';
import { ModalRemoveProject } from '../../project/list/ModalRemoveProject';
import { ProjectsGallery } from '../../project/list/ProjectsGallery';
import { routes } from '../routes';

const Container = styled.div`
  background-color: ${colorStyle.greys['3']};
  height: calc(100vh - ${PRIMARY_TOPBAR_HEIGHT});
`;

export function ViewHome({ children }: PropsWithChildren<{}>) {
  const [showCreationModal, setShowCreationModal] = useState(false);

  const params = useParams<{ farmId?: string }>();

  useEffect(() => setShowCreationModal(false), []);

  return (
    <Container>
      <ProjectsGallery
        focusedProjectId={params.farmId && `farm/${params.farmId}`}
        setShowCreationModal={setShowCreationModal}
      />
      {children || null}
      {showCreationModal && <ProjectEditPanel setShowCreationModal={setShowCreationModal} />}
      {params.farmId && (
        <Switch>
          <Route
            path={routes.project.editModal}
            component={ProjectEditPanel}
          />
          <Route
            path={routes.project.removeModal}
            component={ModalRemoveProject}
          />
        </Switch>
      )}
    </Container>
  );
}
