import React from 'react';
import { ReactComponent as DownloadIcon } from '../../assets/icon-download.svg';
import { ActionIcon } from '../../common/components/ActionIcon';
import { useProjectBaseState } from '../state-context';
import { useExportData } from './export';

export function ExportProjectButton() {
  const { actions, isSaving } = useProjectBaseState();
  const hasUnsavedActions = actions.length === 0;
  const exportData = useExportData();

  return (
    <ActionIcon
      icon={DownloadIcon}
      disabled={!hasUnsavedActions}
      loading={isSaving}
      onClick={exportData}
    />
  );
}
