import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { colorStyle } from '../../style/colors';

export const secondaryTopbarHeight = '60px';

const Header = styled.header`
  height: ${secondaryTopbarHeight};
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 10;
  box-sizing: border-box;
  padding-right: 30px;
`;

const Child = styled.div`
  display: flex;
  align-items: center;
`;
const FlexChild = styled(Child)`
  flex: 1;
`;

export interface SecondaryTopbarProps {
  left?: ReactNode;
  center?: ReactNode;
  right?: ReactNode;
  className?: string;
}

export function SecondaryTopbar({ left, center, right, className }: SecondaryTopbarProps) {
  if (center) {
    // force the same width on every child
    return (
      <Header className={className}>
        <FlexChild className="secondary-topbar-left">{left || null}</FlexChild>
        <FlexChild className="secondary-topbar-center">{center}</FlexChild>
        <FlexChild className="secondary-topbar-right">{right || null}</FlexChild>
      </Header>
    );
  } else {
    // allow left or right to take more than the half of the width
    return (
      <Header className={className}>
        <Child className="secondary-topbar-left">{left || null}</Child>
        <Child className="secondary-topbar-right">{right || null}</Child>
      </Header>
    );
  }
}

// TODO remove
export const VerticalSeparator = styled.div`
  height: 26px;
  width: 2px;
  background-color: ${colorStyle.greys['9-white']};
`;
