import { useTranslation } from '@elzeard/common-components';
import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useAuth } from '../context/AuthContext';
import { colorStyle } from '../style/colors';
import { PRIMARY_TOPBAR_HEIGHT } from '../style/constants';
import MainMenuItem from './MainMenuItem';

const Container = styled.div`
  position: fixed;
  z-index: 500;
  top: calc(${PRIMARY_TOPBAR_HEIGHT} + 4px);
  left: 4px;
  background-color: ${colorStyle.greys['1-black']};
  color: ${colorStyle.greys['9-white']};
`;

export interface IMainMenuProps {
  setIsAccountSettingsPanelOpen: (isOpen: boolean) => void;
}

const MainMenu: FunctionComponent<IMainMenuProps> = (props) => {
  const { t } = useTranslation();

  const history = useHistory();
  const auth = useAuth();

  return (
    <Container>
      <MainMenuItem onClick={() => history.push('/')}>{t('common:main-menu.home')}</MainMenuItem>
      <MainMenuItem onClick={() => props.setIsAccountSettingsPanelOpen(true)}>
        <div onClick={() => props.setIsAccountSettingsPanelOpen(true)}>{t('common:main-menu.account-settings')}</div>
      </MainMenuItem>
      <MainMenuItem onClick={() => auth.signout()}>{t('common:main-menu.logout')}</MainMenuItem>
    </Container>
  );
};

MainMenu.defaultProps = {};

export default MainMenu;
