/* eslint-disable import/no-anonymous-default-export */
export default {
  individuals: {
    'client-category': {
      // ClientCategoryEnum
      CSA: 'Vente paniers',
      // DirectSelling: 'Vente directe',
      FarmSelling: 'Vente à la ferme',
      Market: 'Marché',
      OtherClientCategory: 'Autre',
      Restaurant: 'Restauration',
      Shipping: 'Expédition',
    },
    climate: {
      // ClimateEnum
      Continental: 'continental',
      Oceanic: 'océanique',
      Mountain: 'montagneux',
      SemiOceanic: 'semi-océanique',
      Mediterranean: 'méditerranéen',
    },
    // ClimateCharacteristicsEnum
    'cultivation-practice': {
      // TODO remove ?
      // CultivationEnum
      BallPlanting: 'plantation en motte',
      BedCulture: 'culture sur planche',
      BlockPlanting: 'plantation par bouquets',
      BroadcastSowing: 'semis à la volée',
      BudPlanting: 'plantation en bulbille',
      BunchHarvest: 'récolte en botte',
      BulkHarvest: 'récolte en vrac',
      Dibbling: 'semis en poquet',
      MoundCulture: 'culture sur butte',
      Mulching: 'mulch', // TODO corriger dans l'ontologie (paillage est trop général)
      Planting: 'plantation',
      Ploughing: 'labour',
      SeedBedPreparation: 'préparation du lit de semence',
      SeedDrilling: 'semis en ligne',
      Sowing: 'semis',
      StubbleCultivation: 'faux-semis',
    },
    'culture-mode': {
      // CultureModeEnum
      OpenField: 'Plein champ',
      UnderCover: 'Sous abri',
      HeatedGreenHouse: 'Serre chauffée',
    },
    // FarmEquipmentEnum
    'farm-equipment-level': {
      // FarmEquipmentLevelEnum
      AnimalPoweredEquipment: 'Animal',
      HeavyPoweredEquipment: 'Tracteur',
      LightPoweredEquipment: 'Motoculteur',
      HandPoweredEquipment: 'Outils manuels',
      RobotPoweredEquipment: 'Robot',
    },
    'farming-system': {
      // FarmingSystemEnum
      OrganicAgriculture: 'bio',
      ConventionalAgriculture: 'conventionnel',
      Permaculture: 'permaculture', // TODO remove from ontology (that's a philosophy, not a set of agricultural practices)
      SoilConservationAgriculture: 'Conservation des sols',
      // TODO complete ontology (TCS/conservation des sols, biodynamie, PBI, PNPP, ...)
    },
    // FieldEquipmentEnum
    // HarvestModeEnum
    'irrigation-mode': {
      // IrrigationModeEnum
      Drip: 'goutte-à-goutte',
      Gravity: 'gravité',
      Sprinkler: 'aspersion',
    },
    // PhotoPeriodicityEnum
    region: {
      // RegionEnum
      AuvergneRhoneAlpes: 'Auvergne-Rhône-Alpes',
      BourgogneFrancheComté: 'Bourgogne-Franche-Comté',
      Bretagne: 'Bretagne',
      CentreValDeLoire: 'Centre-Val de Loire',
      Corse: 'Corse',
      GrandEst: 'Grand Est',
      HautsDeFrance: 'Hauts-de-France',
      IleDeFrance: 'Île-de-France',
      Normandie: 'Normandie',
      NouvelleAquitaine: 'Nouvelle-Aquitaine',
      Occitanie: 'Occitanie',
      PaysDeLaLoire: 'Pays de la Loire',
      ProvenceAlpesCoteDAzur: "Provence-Alpes-Côte d'Azur",
    },
    season: {
      // SeasonalityEnum
      Spring: 'printemps',
      Summer: 'été',
      Autumn: 'automne',
      Winter: 'hiver',
    },
    'soil-nature': {
      // SoilNatureEnum
      HumusRich: 'Humifère',
      Rich: 'Meublé',
      WellDrained: 'Drainé',
      Loose: 'Léger',
      LimestoneSoil: 'Calcaire',
    },
    'soil-texture': {
      // SoilTextureEnum
      ClaySoil: 'Argileux',
      LoamSoil: 'Limoneux',
      SandSoil: 'Sableux',
      ClayLoamSoil: 'Limoneux-Argileux',
      SandyLoamSoil: 'Limoneux-Sableux',
    },
  },
  units: {
    density: {
      // DensityUnitEnum
      GramPerSquareMeter: 'g/m²',
      KilogramPerHectare: 'kg/ha',
      SeedPerSquareMeter: 'graines/m²',
      SeedPerMeter: 'graines/m',
      PlantPerSquareMeter: 'plantes/m²',
      PlantPerMeter: 'plantes/m',
    },
    dosage: {
      // DosageUnitEnum
      LitrePerHectare: 'L/ha',
      GramPerHectare: 'g/ha',
      KilogramPerHectare: 'kg/ha',
      TonnePerHectare: 'T/ha',
    },
    'harvest-quantity': {
      // HarvestUnitEnum
      long: {
        Bunch: 'bottes',
        Crate: 'cageots',
        Kilogram: 'kilos',
        Piece: 'pièces',
        Tonne: 'tonnes',
      },
      short: {
        Bunch: 'b.',
        Crate: 'c.',
        Kilogram: 'kg',
        Piece: 'pc',
        Tonne: 't',
      },
    },
    length: {
      // LengthUnitEnum
      long: {
        Centimeter: 'centimètre',
        Meter: 'mètre',
        plural: {
          Centimeter: 'centimètre(s)',
          Meter: 'mètre(s)',
        },
      },
      short: {
        Centimeter: 'cm',
        Meter: 'm',
      },
    },
    surface: {
      // SurfaceUnitEnum
      Are: 'a',
      Hectare: 'ha',
      SquareMeter: 'm²',
    },
    time: {
      // TemporalUnitEnum
      long: {
        unitSecond: 'secondes',
        unitMinute: 'jours',
        unitHour: 'heures',
        unitDay: 'jours',
        unitWeek: 'semaines',
        unitFortnight: 'quinzaines',
        unitMonth: 'mois',
        unitYear: 'années',
      },
    },
    workload: {
      // WorkloadUnitEnum
      HourPerAre: 'h/are',
      HourPerHectare: 'h/ha',
      SecondPerCup: 'sec/godet',
      SecondPerSoldUnit: 'sec/unité vendue',
      SecondPerLinearMeter: 'sec/mètre linéaire',
      SecondPerBall: 'sec/motte',
      SecondPerTray: 'sec/caissette',
      SecondPerSquareMeter: 'sec/m2',
      SecondPerAlveolus: 'sec/alvéole',
    },
    yield: {
      // YieldUnitEnum
      UnitPerSquareMeter: 'pièce/m2',
      UnitPerHectare: 'pièce/ha',
      BunchPerSquareMeter: 'botte/m2',
      BunchPerHectare: 'botte/ha',
      KilogramPerSquareMeter: 'kg/m2',
      TonnePerAre: 't/are',
      TonnePerHectare: 't/ha',
    },
  },
};
