import { round } from 'lodash';
import { BaseProjectPageState, ChildProduct, OutletProductionNeeds, ParentProduct } from '../state';
import { buildProductAvailabilityPeriod } from '../state-build';
import { Outlet, OutletProduct } from './state-full';

export function editProductProperty({
  outletProduct,
  selectedParentProducts,
  propertyName,
  weekKey,
  value,
  outlet,
}: {
  outletProduct: OutletProduct;
  selectedParentProducts: BaseProjectPageState['selectedParentProducts'];
  propertyName: keyof OutletProduct;
  weekKey: string;
  value: number;
  outlet: Pick<Outlet, 'defaultPrices' | 'rowId'>;
}): {
  updatedOutletProduct: OutletProduct;
  updatedSelectedParentProducts: BaseProjectPageState['selectedParentProducts'];
} {
  const projectProduct = selectedParentProducts[outletProduct.parentCropItineraryId];
  const [updatedOutletProduct, updatedProjectProduct] = editOutletProductProperty({
    outletProduct,
    projectProduct,
    propertyName,
    weekKey,
    value,
    outlet,
  });
  if (updatedProjectProduct === projectProduct) {
    return {
      updatedOutletProduct: updatedOutletProduct,
      updatedSelectedParentProducts: selectedParentProducts,
    };
  } else {
    return {
      updatedOutletProduct: updatedOutletProduct,
      updatedSelectedParentProducts: {
        ...selectedParentProducts,
        [updatedProjectProduct.parentCropItineraryId]: updatedProjectProduct,
      },
    };
  }
}

function editOutletProductProperty({
  outletProduct,
  projectProduct,
  propertyName,
  weekKey,
  value,
  outlet,
}: {
  outletProduct: OutletProduct;
  projectProduct: ParentProduct;
  propertyName: keyof OutletProduct;
  weekKey: string;
  value: number;
  outlet: Pick<Outlet, 'defaultPrices' | 'rowId'>;
}): [OutletProduct, ParentProduct] {
  if (propertyName === 'weeklySales') {
    const price = outletProduct.weeklyPrices[weekKey] || outletProduct.defaultUnitPrice;
    if (price) {
      const needValue = round((value || 0) / price, 2);
      return editNeedsProperty({
        propertyName: 'weeklyNeeds',
        outletProduct,
        projectProduct,
        weekKey,
        value: needValue,
        outlet,
      });
    } else {
      // cannot divide by 0, reject the update
      return [outletProduct, projectProduct]; // TODO warn the user ?
    }
  } else {
    if (propertyName === 'weeklyNeeds') {
      return editNeedsProperty({ propertyName, outletProduct, projectProduct, weekKey, value, outlet });
    } else if (propertyName === 'weeklyPrices') {
      if ((value ?? null) === ((outletProduct.weeklyPrices[weekKey] || outletProduct.defaultUnitPrice) ?? null)) {
        // to avoid a rerender of the edited cell on focus
        return [outletProduct, projectProduct];
      }
      return editNeedsProperty({ propertyName, outletProduct, projectProduct, weekKey, value, outlet });
    } else {
      // TODO WTF ? throw error ?
      console.warn(`Trying to edit an unexpected property [${propertyName}]`);
      return [outletProduct, projectProduct];
    }
  }
}

function editNeedsProperty({
  outletProduct,
  projectProduct,
  propertyName,
  weekKey,
  value,
  outlet,
}: {
  outletProduct: OutletProduct;
  projectProduct: ParentProduct;
  propertyName: keyof Pick<OutletProductionNeeds, 'weeklyNeeds' | 'weeklyPrices'>;
  weekKey: string;
  value: number;
  outlet: Pick<Outlet, 'defaultPrices' | 'rowId'>;
}): [OutletProduct, ParentProduct] {
  if ((outletProduct[propertyName][weekKey] || 0) === (value || 0)) {
    return [outletProduct, projectProduct];
  } else {
    const initialNeeds = projectProduct.productionNeedsByOutletRowId[outlet.rowId];
    let updatedNeed: OutletProductionNeeds;
    if (initialNeeds) {
      updatedNeed = {
        ...initialNeeds,
        isUpdated: true,
        [propertyName]: {
          ...initialNeeds[propertyName],
          [weekKey]: value,
        },
      };
    } else {
      const otherProperty = propertyName === 'weeklyNeeds' ? 'weeklyPrices' : 'weeklyNeeds';
      updatedNeed = {
        defaultUnitPrice: outlet.defaultPrices[projectProduct.parentCropItineraryId]?.value,
        productionNeedId: null,
        [otherProperty as 'weeklyPrices']: {},
        isUpdated: true,
        [propertyName as 'weeklyNeeds']: {
          [weekKey]: value,
        },
      };
    }

    const updatedProjectProduct: ParentProduct = {
      ...projectProduct,
      isUpdated: true,
      productionNeedsByOutletRowId: {
        ...projectProduct.productionNeedsByOutletRowId,
        [outlet.rowId]: updatedNeed,
      },
    };
    const updatedOutletProduct: OutletProduct = {
      ...outletProduct,
      [propertyName]: updatedNeed[propertyName],
      // TODO update weeklySales  and totalSales ?
    };
    if (value) {
      // look for newly selected children
      const childrenToSelect = Object.values(projectProduct.otherPossibleChildrenByChildItineraryId).filter((child) =>
        isProductAvailableOnWeek(child, weekKey),
      );
      if (childrenToSelect.length) {
        updatedProjectProduct.otherPossibleChildrenByChildItineraryId = {
          ...updatedProjectProduct.otherPossibleChildrenByChildItineraryId,
        };
        updatedProjectProduct.selectedChildrenByRowId = { ...updatedProjectProduct.selectedChildrenByRowId };
        for (let child of childrenToSelect) {
          delete updatedProjectProduct.otherPossibleChildrenByChildItineraryId[child.childCropItineraryId];
          updatedProjectProduct.selectedChildrenByRowId[child.rowId] = {
            ...child,
            isUpdated: true,
          };
        }
      }
    } else {
      // look for children to unselect
      const childrenToCheck = Object.values(projectProduct.selectedChildrenByRowId).filter((child) =>
        isProductAvailableOnWeek(child, weekKey),
      );
      const allOutletNeeds = Object.values(updatedProjectProduct.productionNeedsByOutletRowId);
      const childrenToUnselect = childrenToCheck.filter((child) => !isProductSelected(child, allOutletNeeds));
      for (let child of childrenToUnselect) {
        const updatedProjectChild: ChildProduct = {
          ...child,
          isUpdated: true,
          series: child.itinerarySeries,
        };
        // console.log('remove needs, unselect child product', { matchingChild, projectChild, updatedProjectChild });
        delete updatedProjectProduct.selectedChildrenByRowId[child.rowId];
        if (child.childCropItineraryId) {
          updatedProjectProduct.otherPossibleChildrenByChildItineraryId[child.childCropItineraryId] =
            updatedProjectChild;
        } else if (updatedProjectChild.productId) {
          // console.log('should be completely removed');
          // TODO it is an ugly hack, to keep track of this product in order to remove it from the DB
          // TODO change otherPossibleChildrenByChildItineraryId and selectedChildrenByRowId to arrays
          updatedProjectProduct.otherPossibleChildrenByChildItineraryId[child.rowId] = updatedProjectChild;
        }
      }
    }
    return [updatedOutletProduct, updatedProjectProduct];
  }
}

function isProductAvailableOnWeek(product: ChildProduct, weekKey: string) {
  return [...product.harvestPeriods, ...product.storagePeriods].some((period) => period.weeks[weekKey]);
}

function isProductSelected(product: ChildProduct, needs: OutletProductionNeeds[]) {
  if (product.series[0].editedSurfaceNeeds || product.series[0].expectedVolume) {
    return true;
  }
  const availability = buildProductAvailabilityPeriod(product).reduce(
    (cumulated, availability) => ({
      ...cumulated,
      ...availability.weeks,
    }),
    {} as Record<string, boolean>,
  );
  return needs.some((needs) =>
    Object.entries(needs.weeklyNeeds).some(([weekKey, need]) => need && availability[weekKey]),
  );
}
