import { useTranslation } from '@elzeard/common-components';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { routes } from '../../app/routes';
import ImgLogo from '../../assets/colored-logo-158.svg';
import ButtonObsolete from '../../common/components/ButtonObsolete';
import { ErrorMessage } from '../../common/components/ErrorMessage';
import ModalContent from '../../common/components/ModalContent';
import InputField from '../../common/components/field/InputField';
import { useRequestResetPasswordByMailMutation } from '../../common/generated/graphql';
import { textStyle } from '../../common/style/text';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 300px;
  text-align: center;
`;
const Logo = styled.img`
  height: 176px;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 8px;
`;
const Title = styled.div`
  margin-bottom: 24px;
  text-align: center;
  ${textStyle['title']}
`;

interface IFormValues {
  username: string;
}

export function ViewForgottenPassword() {
  const { t } = useTranslation();
  const [apiError, setApiError] = useState<string | null>();
  const [passwordReset, setPasswordReset] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<IFormValues>();
  const [mutateRequestResetUserAccountPasswordByMail] = useRequestResetPasswordByMailMutation();

  const history = useHistory();

  const onSubmit = async (formData: IFormValues) => {
    try {
      await mutateRequestResetUserAccountPasswordByMail({
        variables: {
          email: formData.username,
          redirectUrl: `${window.location.origin}${routes.login.resetPassword}`,
        },
      });

      setPasswordReset(true);
    } catch (e) {
      setApiError(t('common:error.generic'));
    }
  };

  /**
   * Remove the API error message after the user has changed any of the input fields
   */
  const username = watch('username');
  useEffect(() => {
    setApiError(null);
  }, [username]);

  if (passwordReset) {
    return (
      <ModalContent
        title={t('common:auth.forgot-password.email-sent-title')}
        buttonLabel={t('common:button.back')}
        onClick={() => history.push('/login')}
      >
        {t('common:auth.forgot-password.email-sent-description')} {username}
      </ModalContent>
    );
  }

  return (
    <Container>
      <Logo src={ImgLogo} />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Title>{t('common:app.title')}</Title>
        <InputField
          placeholder={t('common:fields.email')}
          fieldName="username"
          register={register}
          errors={errors}
          required
          type="email"
        />
        <ErrorMessage message={errors.username && t('common:error.empty-field')} />
        <ErrorMessage message={apiError} />

        <ButtonObsolete variant="contained">{t('common:auth.forgot-password.action')}</ButtonObsolete>
        <ButtonObsolete
          variant="contained"
          onClick={() => history.go(-1)}
        >
          {t('common:button.back')}
        </ButtonObsolete>
      </Form>
    </Container>
  );
}
