import { buildWeek, Week } from '@elzeard/common-components';
import React, { KeyboardEventHandler, ReactNode, useEffect, useRef } from 'react';
import { useClickAway } from 'react-use';
import styled from 'styled-components';
import { NumberInput } from '../../../common/components/field/NumberInput';
import { colorStyle } from '../../../common/style/colors';
import { weekWidth } from '../../table/style';

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  min-width: 100%;
  z-index: 1;
`;
const Input = styled(NumberInput)`
  height: 100%;
  min-width: 100%;
  background-color: ${colorStyle.greys['8']};
  font-family: inherit;
  padding: 0 7px;
  &:focus-visible {
    outline: 0;
    padding: 0 7px;
  }
`;

function getWidth(value: number) {
  return value ? value.toString().length + 2 + 'ch' : weekWidth + 'px';
}
export type EditedCellProps<T extends number | Week> = {
  value: T;
  isPercent?: boolean;
  children?: ReactNode;
  quitEdition: () => void;
  editProperty: (value: T) => void;
} & (T extends number
  ? {
      isWeek?: false;
    }
  : {
      isWeek: true;
    });
export function EditedCell<T extends number | Week>({
  value,
  isPercent,
  isWeek,
  children,
  editProperty,
  quitEdition,
}: EditedCellProps<T>) {
  const convertedValue = (
    isWeek ? (value as Week)?.weekNumber : isPercent ? value && (value as number) * 100 : value
  ) as number;
  const initialValue = useRef<number | Week>(isWeek ? value : convertedValue);
  const currentValue = useRef(convertedValue);

  const containerRef = useRef<HTMLDivElement>();
  const inputRef = useRef<HTMLInputElement>();
  useClickAway(containerRef, quitEdition);

  const onChange = (value: number) => {
    currentValue.current = value;
    inputRef.current.style.width = getWidth(value);
  };

  const onKeyDown: KeyboardEventHandler = (event) => {
    event.stopPropagation();
    if (event.key === 'Enter' || event.key === 'Tab') {
      event.preventDefault();
      // quit edit mode (applying value change)
      quitEdition();
    }
    if (event.key === 'Escape') {
      event.preventDefault();
      // ignore value change
      currentValue.current = convertedValue;
      quitEdition();
    }
  };

  useEffect(() => {
    currentValue.current = convertedValue;
  }, [convertedValue]);

  useEffect(() => {
    // apply value change when unmounting the element
    return () => {
      const currentPositive = currentValue.current && Math.abs(currentValue.current);
      if (currentPositive !== initialValue.current) {
        if (isWeek) {
          // eslint-disable-next-line react-hooks/exhaustive-deps
          const initial = initialValue.current as Week;
          const current = currentPositive && Math.floor(currentPositive);
          if (!initial || !current || current > 52) {
            // TODO support week 53 ?
            console.log('Invalid week value, no change', current, initial);
          } else if (initial.weekNumber !== current) {
            editProperty(buildWeek(initial.year, current) as T);
          }
        } else if (initialValue.current !== currentPositive) {
          editProperty((isPercent && currentPositive ? currentPositive / 100 : currentPositive) as T);
        }
      }
    };
  }, [editProperty, isPercent, isWeek]);

  // useEffect(() => {
  //   console.log('focus & select');
  //   inputRef.current.focus();
  //   inputRef.current.select();
  // }, []);

  return (
    <Container ref={containerRef}>
      <Input
        ref={inputRef}
        value={convertedValue || null}
        onValueChange={onChange}
        onKeyDown={onKeyDown}
        autoFocus
        selectTextOnFocus
        // autoSize
        style={{
          width: getWidth(currentValue.current),
        }}
      />
      {children}
    </Container>
  );
}
