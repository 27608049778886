import { ApolloQueryResult } from '@apollo/client';
import React, { FC, PropsWithChildren, useMemo } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { routes } from '../../app/routes';
import config from '../../config';
import { FullPageSpinner } from '../components/Spinner';
import { MeQuery, useMeQuery, useSigninMutation } from '../generated/graphql';

interface AuthContextType {
  user?: MeQuery['me2'];
  signin: (username: string, password: string) => Promise<ApolloQueryResult<MeQuery>>;
  signout: () => void;
  loading: boolean;
}

let AuthContext = React.createContext<AuthContextType>(null!);

export function AuthProvider({ children }: PropsWithChildren<{}>) {
  const [mutateSignin] = useSigninMutation()
  const { data: meQueryData, refetch: meQueryRefetch, loading: meQueryLoading } = useMeQuery();

  const value = useMemo(() => {
    const user: MeQuery['me2'] | undefined = meQueryData?.me2;

    const signin = async (username: string, password: string) => {
      const { data } = await mutateSignin({
        variables: {
          email: username, password
        },
      });
      //@ts-ignore
      if (!data.signin?.token) {
        return null
      }
      //@ts-ignore
      localStorage.setItem(config.AUTH_TOKEN, data.signin.token);
      const result = await meQueryRefetch();
      return result;
    };

    const signout = () => {
      localStorage.removeItem(config.AUTH_TOKEN);
      // Refresh the page to clear the browser state
      window.location.reload();
    };

    return { signin, signout, user, loading: meQueryLoading };
  }, [meQueryData?.me2, meQueryRefetch, mutateSignin, meQueryLoading]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return React.useContext(AuthContext);
}

const ViewLoading = styled(FullPageSpinner)`
  height: 100vh;
`;

export const RequireAuth: FC<PropsWithChildren<{}>> = ({ children }) => {
  let auth = useAuth();
  let location = useLocation();

  if (!auth.user?.id) {
    if (auth.loading) {
      return <ViewLoading />;
    }
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return (
      <Redirect
        to={{
          pathname: routes.login.root,
          state: { from: location },
        }}
      />
    );
  }

  return <>{children}</>;
};
