import { useBasePositionPlanningApplyCommand, useBasePositionPlanningState } from '@elzeard/common-planning';
import { Dispatch } from 'react';
import { ProjectPageState } from '../state';
import { AllOutletCommands } from './commands';
import { OutletsPageState } from './state-full';

export function useOutletsState() {
  return useBasePositionPlanningState() as ProjectPageState<OutletsPageState>;
}

export function useOutletsCommands() {
  return useBasePositionPlanningApplyCommand() as Dispatch<AllOutletCommands>;
}
