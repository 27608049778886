import styled from 'styled-components';
import { colorStyle } from '../../style/colors';
import { getTextStyle } from '../../style/text';

export type FieldVariant = 'block' | 'line';

export const Field = styled.div<{
  $hasValue?: boolean;
  $error?: boolean;
  $disabled?: boolean;
  $variant?: FieldVariant;
}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
  color: ${({ $error }) => ($error ? colorStyle.error['2'] : colorStyle.greys['4-defaultIcon'])};

  & > label {
    ${getTextStyle(12, 'regular')}
  }

  & > .input {
    width: 100%;
    outline: 0;
    box-sizing: border-box;
    ${getTextStyle(14, 'regular')}
    border-color: ${({ $error, $hasValue }) =>
      $error ? colorStyle.error['2'] : $hasValue ? colorStyle.cta2['1'] : colorStyle.greys['5']};
    ${({ $variant }) =>
      $variant === 'line'
        ? `
        padding: 0 16px 0 0;
        border-style: solid;
        border-width: 0 0 1px;
        background-color: transparent;
      `
        : `
        padding: 8px 16px;
        border-style: solid;
        border-width: 1px;
        background-color: ${colorStyle.greys['9-white']};
        border-radius: 4px;
      `}
    ::placeholder {
      color: ${colorStyle.greys['5']};
    }
  }

  & > .checkbox {
    width: 100%;
  }

  &:hover {
    & > .input {
      background-color: ${colorStyle.cta2['5']};
    }
  }
  &:focus-within {
    & > .input {
      background-color: ${colorStyle.cta2['5']};
      border-color: ${colorStyle.cta2['1']};
      ${({ $variant }) =>
        $variant === 'line'
          ? `
        border-width: 0 0 2px;
      `
          : `
        padding: 7px 16px;
        border-width: 2px;
      `}
    }
  }
`;
