import { CultureModeEnum } from '@elzeard/shared-dimensions';
import { ComponentType } from 'react';
import { ReactComponent as CultureModeHeatedGreenHouseIcon } from '../../assets/culture-mode-abri-chaud.svg';
import { ReactComponent as CultureModeUnderCoverIcon } from '../../assets/culture-mode-abri-froid.svg';
import { ReactComponent as CultureModePurchaseResaleIcon } from '../../assets/culture-mode-achat-revente.svg';
import { ReactComponent as CultureModeMixedIcon } from '../../assets/culture-mode-mixed.svg';
import { ReactComponent as CultureModeOpenFieldIcon } from '../../assets/culture-mode-plein-champ.svg';

export type CultureMode = CultureModeEnum | 'PurchaseResale' | 'Mixed';

export const cultureModeIcons: Record<CultureMode, ComponentType> = {
  [CultureModeEnum.HeatedGreenHouse]: CultureModeHeatedGreenHouseIcon,
  [CultureModeEnum.OpenField]: CultureModeOpenFieldIcon,
  [CultureModeEnum.UnderCover]: CultureModeUnderCoverIcon,
  Mixed: CultureModeMixedIcon,
  PurchaseResale: CultureModePurchaseResaleIcon,
};
