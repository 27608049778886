export const outletsHeights = {
  outletRow: '50px',
  targetRow: '50px',
  productRow: '50px',
  subProductRow: '40px',
};

export const firstHeaderColumnWidth = {
  allOutlets: '429px',
  editedOutlet: '264px',
};
