import { useTranslation } from '@elzeard/common-components';
import React, { PropsWithChildren, ReactNode } from 'react';
import styled from 'styled-components';
import { ReactComponent as CloseIcon } from '../../../assets/icon-cancel.svg';
import { colorStyle, popupShadow } from '../../style/colors';
import { getTextStyle } from '../../style/text';
import { Button, buttonWithDefaults } from '../Button';
import { Modal } from './Modal';

const Container = styled(Modal)<{ $blueBackGround: boolean }>`
  width: 500px;
  top: 70px;
  right: 90px;
  /* left: auto;
  bottom: auto; */
  background: ${({ $blueBackGround }) => ($blueBackGround ? colorStyle.cta2['6'] : colorStyle.greys['9-white'])};
  ${popupShadow}
  border-radius: 16px;
`;
const ModalHeader = styled.header`
  .modal-header-buttons {
    height: 50px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 4px;
    padding-right: 10px;
  }
  .modal-header-title {
    padding: 0 36px;
    margin-bottom: 18px;
    ${getTextStyle(24, 'semibold')}
    color: ${colorStyle.greys['3']};
  }
`;
const DetailsContainer = styled.main`
  max-height: calc(100vh - 280px);
  min-height: 80px;
  overflow-y: auto;
  padding: 0 36px;
`;
// const Details = styled.div`
//   margin-left: 20px;
// `;
const ModalFooter = styled.div`
  margin: 18px 36px 36px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  .modal-footer-info {
    flex: auto;
  }
  .modal-footer-actions {
    flex: none;
    display: flex;
    gap: 8px;
  }
`;

const CircularButton = buttonWithDefaults({
  variant: 'CTA',
  sizing: 'M',
  isFilled: true,
})`
  border-radius: 15px;
`;

export const ModalErrorInformation = styled.div`
  ${getTextStyle(12, 'regular')}
  color: ${colorStyle.error['3']};
`;

export interface RightFormModalProps {
  title: string;
  className?: string;
  loading?: boolean;
  confirmButtonLabelKey?: string;
  cancelButtonLabelKey?: string;
  footerInfo?: ReactNode;
  blueBackground?: boolean;
  onConfirm?: () => void;
  onCancel: () => void;
  onClose?: () => void;
}
export function RightFormModal({
  title,
  className,
  loading,
  confirmButtonLabelKey = 'common:button.validate',
  cancelButtonLabelKey = 'common:button.cancel',
  footerInfo = null,
  blueBackground,
  onConfirm,
  onCancel,
  onClose = onCancel,
  children,
}: PropsWithChildren<RightFormModalProps>) {
  const { t } = useTranslation();
  return (
    <Container
      className={className}
      onClose={onClose}
      $blueBackGround={blueBackground}
    >
      <ModalHeader>
        <div className="modal-header-buttons">
          <CircularButton
            leftIcon={CloseIcon}
            onClick={onClose}
          />
        </div>
        <div className="modal-header-title">{title}</div>
      </ModalHeader>
      <DetailsContainer>{children}</DetailsContainer>
      <ModalFooter>
        <div className="modal-footer-info">{footerInfo}</div>
        <div className="modal-footer-actions">
          {onConfirm && (
            <Button
              variant="CTA"
              sizing="M"
              onClick={onCancel}
            >
              {t(cancelButtonLabelKey)}
            </Button>
          )}
          <Button
            variant="CTA"
            sizing="M"
            isFilled
            loading={loading}
            onClick={onConfirm || onCancel}
          >
            {t(confirmButtonLabelKey)}
          </Button>
        </div>
      </ModalFooter>
    </Container>
  );
}
