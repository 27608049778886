import { NumberMap } from '@elzeard/common-planning';
import React from 'react';
import styled from 'styled-components';
import { colorStyle } from '../../../common/style/colors';
import { timelineRow } from '../../table/style';
import { useOutletsState } from '../state-context';
import { getWeekKey } from '../utils';
import { EditableTargetCell } from './EditableTargetCell';

const Row = styled.tr`
  ${timelineRow}
  background-color: ${colorStyle.greys['7']};
`;

export type SalesTargetTimelineRowProps = {
  outletRowId: string;
  weeklySalesTarget: NumberMap;
  isDisabled?: boolean;
};

export function SalesTargetTimelineRow({ outletRowId, weeklySalesTarget, isDisabled }: SalesTargetTimelineRowProps) {
  const { editedCell, time } = useOutletsState();

  const isEditingOutlet =
    editedCell && editedCell.outletRowId === outletRowId && editedCell.parentCropItineraryId === null;
  return (
    <Row>
      {time.weeks.map((week) => {
        const weekKey = getWeekKey(week);
        const isEditing = isEditingOutlet && editedCell.weekKey === weekKey;
        return (
          <EditableTargetCell
            key={weekKey}
            weekKey={weekKey}
            isEditing={isEditing}
            isDisabled={isDisabled}
            outletRowId={outletRowId}
            value={weeklySalesTarget[weekKey]}
          />
        );
      })}
    </Row>
  );
}
