import { useTranslation } from '@elzeard/common-components';
import { PositionPlanningAdapter, PositionPlanningStateProvider } from '@elzeard/common-planning';
import React, { Dispatch, useCallback, useEffect, useReducer, useRef } from 'react';
import { Redirect, Route, Switch, generatePath, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { routes } from '../app/routes';
import { FullPageSpinner } from '../common/components/Spinner';
import { useUpdatePepiniereProjectMutation } from '../common/generated/graphql';
import { usePrompt } from '../common/hook/usePrompt';
import { baseProjectCommandHandler } from './commandHandler';
import { AllProjectCommands } from './commands';
import { OutletsHomePage } from './outlet/OutletsHomePage';
import { outletCommandHandler } from './outlet/commandHandler';
import { PositionPage } from './position/PositionPage';
import { positionPageCommandHandler } from './position/commandHandler';
import { AllPositionPageCommands, PositionPageCommands } from './position/commands';
import { planningCulturesStateAdapter } from './position/state-adapter-cultures';
import { planningSpacetimeStateAdapter } from './position/state-adapter-spacetime';
import { Culture, CultureCoordinates, PositionPageState, PositionSerie } from './position/state-full';
import { SeriesPage } from './series/SeriesPage';
import { seriesCommandHandler } from './series/commandHandler';
import { BasePlot, BaseProjectPageState, ProjectPageState } from './state';
import { buildUpdateProjectInput } from './state-save';
import { useCropItineraries } from './useCropItineraries';
import { usePepiniereProject } from './usePepiniereProject';

const commandHandler = {
  ...baseProjectCommandHandler,
  ...outletCommandHandler,
  ...seriesCommandHandler,
  ...positionPageCommandHandler,
};

function useProjectState(initialState: ProjectPageState<unknown>) {
  const stateToSaveRef = useRef<BaseProjectPageState>(null);

  // Reducer function is in a callback to avoid double dispatch
  const reducer = useCallback(
    (state: ProjectPageState<unknown>, command: AllProjectCommands): ProjectPageState<unknown> => {
      const { error, ...prevState } = state;
      const handler = commandHandler[command.type] as (
        prevState: ProjectPageState<unknown>,
        command: AllProjectCommands,
      ) => ProjectPageState<unknown>;
      try {
        // setTimeout(() => {
        //   console.log('command', command);
        // }, 0);
        const state = handler(prevState, command);
        if (command.type === 'save') {
          stateToSaveRef.current = state;
        }
        // setTimeout(() => {
        //   if (state !== prevState) {
        //     console.log('updated state', { state, prevState });
        //   } else {
        //     console.log('unchanged', state);
        //   }
        // }, 0);
        return state;
      } catch (error) {
        console.error(error);
        return {
          ...prevState,
          error,
        };
      }
    },
    [],
  );

  const previousBasePlots = useRef<BasePlot[]>(null);

  const [state, applyCommand] = useReducer(reducer, initialState);

  const [triggerUpdateProject] = useUpdatePepiniereProjectMutation({
    onCompleted({ updatePepiniereProject: { updatedObject } }) {
      applyCommand({
        type: 'saveSuccess',
        updatedObject,
      });
    },
    onError(error) {
      applyCommand({
        type: 'saveError',
        error,
      });
    },
  });

  useEffect(() => {
    if (previousBasePlots.current) {
      applyCommand({
        type: 'refreshPlots',
        updatedPlots: initialState.basePlots,
      });
    }
    previousBasePlots.current = initialState.basePlots;
  }, [initialState.basePlots]);

  useEffect(() => {
    if (state.isSaving) {
      triggerUpdateProject({
        variables: {
          farmId: stateToSaveRef.current.farmId,
          projectInput: buildUpdateProjectInput(stateToSaveRef.current),
        },
      });
    }
  }, [state.isSaving, triggerUpdateProject]);

  return {
    state,
    applyCommand,
  };
}

export const ProjectPageSpinner = styled(FullPageSpinner)`
  .label {
    width: 150px;
  }
`;

const positionPlanningAdapter: PositionPlanningAdapter<
  CultureCoordinates,
  Culture,
  PositionSerie,
  ProjectPageState<PositionPageState>,
  PositionPageCommands
> = {
  ...planningCulturesStateAdapter,
  ...planningSpacetimeStateAdapter,
};

export function ProjectPage() {
  const { cropItineraries, error: itinerariesError, loading: itinerariesLoading } = useCropItineraries();

  let { farmId } = useParams<{ farmId: string }>();
  const {
    baseState: initialState,
    error: projectError,
    loading: projectLoading,
  } = usePepiniereProject(`farm/${farmId}`, cropItineraries);

  const queryLoading = itinerariesLoading || projectLoading;
  const queryError = itinerariesError || projectError;

  if (queryLoading) {
    return <ProjectPageSpinner textI18nKey="outlet:home.spinner" />;
  }
  if (queryError) {
    // TODO
    return <div>{'ERREUR : ' + queryError.message}</div>;
  }

  return <ProjectPageInner initialState={initialState} />;
}

export function ProjectPageInner({ initialState }: { initialState: ProjectPageState<unknown> }) {
  const { t } = useTranslation();

  const { state, applyCommand } = useProjectState(initialState);

  const routeParams = useParams();
  const hasUnsavedActions = state.actions.length !== 0;
  const rootPath = generatePath(routes.project.details.root, routeParams);
  usePrompt(t('outlet:alertUnsavedChanges'), hasUnsavedActions, rootPath);

  return state ? (
    <PositionPlanningStateProvider
      adapter={positionPlanningAdapter}
      applyCommand={applyCommand as unknown as Dispatch<AllPositionPageCommands>}
      state={state as ProjectPageState<PositionPageState>}
    >
      <Switch>
        <Route path={routes.project.details.outlet.root}>
          <OutletsHomePage />
        </Route>
        <Route path={routes.project.details.series.root}>
          <SeriesPage />
        </Route>
        <Route path={routes.project.details.position.root}>
          <PositionPage />
        </Route>
        <Redirect to={routes.project.details.outlet.root} />
      </Switch>
    </PositionPlanningStateProvider>
  ) : (
    <ProjectPageSpinner textI18nKey="outlet:home.spinner" />
  );
}
