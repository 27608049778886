import { useTranslation } from '@elzeard/common-components';
import { CultureModeEnum } from '@elzeard/shared-dimensions';
import JSZip from 'jszip';
import { round } from 'lodash';
import { BaseOutlet } from '../../project/state';
import { translateCultureModeEnum, translateHarvestUnitEnum } from '../../shared-elzeard/constants/translate-constant';
import { getWeekKey } from '../outlet/utils';
import { useProjectBaseState } from '../state-context';

export type CsvData<Columns extends readonly string[]> = { [K in Columns[number]]?: string | number };

export type Csv<Columns extends readonly string[]> = {
  headers: Columns;
  displayedHeaders: string[];
  values: CsvData<Columns>[];
};

function sanitizeCsvValues(values: Array<string | number>): string {
  return values
    .map((val) => {
      if (typeof val === 'string') return `"${val.replace('"', "''")}"`;
      if (typeof val === 'number') return val.toString().replace('.', ',');
      return null;
    })
    .join(';');
}

function toCSV(content: Csv<readonly string[]>) {
  return [
    sanitizeCsvValues(content.displayedHeaders),
    ...content.values.map((row) => sanitizeCsvValues(content.headers.map((header) => row[header]))),
  ].join('\n');
}

const planColumns = [
  'culture',
  'plantFamily',
  'cultureMode',
  'surface',
  'harvestUnits',
  'yield',
  'totalProductionFromOutlets',
  'totalProductionFromSeries',
  'totalSales',
] as const;

const cropRotationColumns = [
  'culture',
  'plantFamily',
  'implantationWeek',
  'implantationYear',
  'harvestBeginWeek',
  'harvestBeginYear',
  'endWeek',
  'endYear',
  'cultureMode',
  'harvestUnit',
  'yield',
  'surface',
  'plot',
] as const;

function composeWeek(weekNumber: number) {
  return `S${weekNumber?.toString().padStart(2, '0') || '?'}`;
}

export function useExportData() {
  const { selectedParentProducts: parentProducts, time, outletsByRowId } = useProjectBaseState();
  const { t } = useTranslation();

  return () => {
    let planRows: CsvData<typeof planColumns>[] = [];
    let cropRotationRows: CsvData<typeof cropRotationColumns>[] = [];
    let salesRows: string[] = [];

    for (const parentProduct of Object.values(parentProducts)) {
      const { totalProductionFromOutlets, totalSales } = Object.values(
        parentProduct.productionNeedsByOutletRowId,
      ).reduce(
        ({ totalProductionFromOutlets, totalSales }, outlet) => {
          const productionForOutlet = Object.values(outlet.weeklyNeeds).reduce((sum, need) => sum + need, 0);
          return {
            totalProductionFromOutlets: totalProductionFromOutlets + productionForOutlet,
            totalSales: totalSales + productionForOutlet * outlet.defaultUnitPrice,
          };
        },
        { totalProductionFromOutlets: 0, totalSales: 0 },
      );

      const { cultureModes, totalSurface, quantityUnits, totalProductionFromSeries } = Object.values(
        parentProduct.selectedChildrenByRowId,
      ).reduce(
        (acc, child) => {
          const productSurface = child.series.reduce(
            (sum, s) => sum + (s.editedSurfaceNeeds || s.computedSurfaceNeeds),
            0,
          );
          return {
            cultureModes: [...new Set(acc.cultureModes.concat(child.cultureMode))],
            totalSurface: acc.totalSurface + productSurface,
            quantityUnits: [...new Set(acc.quantityUnits.concat(child.quantityUnit))],
            totalProductionFromSeries: acc.totalProductionFromSeries + child.series[0].expectedVolume,
          };
        },
        {
          cultureModes: [],
          totalSurface: 0,
          quantityUnits: [],
          totalProductionFromSeries: 0,
        },
      );

      planRows.push({
        culture: parentProduct.name,
        plantFamily: parentProduct.plantFamily,
        cultureMode: cultureModes.map((mode) => translateCultureModeEnum(t, mode)).join(', '),
        surface: round(totalSurface, 1),
        harvestUnits: quantityUnits.map((unit) => translateHarvestUnitEnum(t, unit, 'long')).join(', '),
        totalProductionFromOutlets: round(totalProductionFromOutlets),
        totalProductionFromSeries: round(totalProductionFromSeries),
        totalSales: round(totalSales, 1),
      });

      for (const childProduct of Object.values(parentProduct.selectedChildrenByRowId)) {
        for (const series of childProduct.series) {
          cropRotationRows.push({
            culture: childProduct.name,
            plantFamily: childProduct.plantFamily,
            implantationWeek: series.begin.weekNumber,
            implantationYear: series.begin.year,
            harvestBeginWeek: series.harvest.begin.weekNumber,
            harvestBeginYear: series.harvest.begin.year,
            endWeek: series.harvest.end.weekNumber,
            endYear: series.harvest.end.year,
            cultureMode: translateCultureModeEnum(t, childProduct.cultureMode as CultureModeEnum),
            harvestUnit: translateHarvestUnitEnum(t, childProduct.quantityUnit, 'long'),
            yield: round(series.expectedYield, 1),
            surface: round(series.editedSurfaceNeeds || series.computedSurfaceNeeds, 1),
            plot: [...new Set(series.positions.map((p) => p.plotName))].join(', '),
          });
        }
      }

      salesRows.push(
        ...Object.entries(parentProduct.productionNeedsByOutletRowId).map(([outletRowId, needs]) => {
          const outlet: BaseOutlet = outletsByRowId[outletRowId];
          const outletPrice = outlet.defaultPrices[parentProduct.parentCropItineraryId]?.value;
          return sanitizeCsvValues([
            parentProduct.name,
            outlet.name,
            ...time.weeks.map((week) => {
              const weekKey = getWeekKey(week);
              const need = needs.weeklyNeeds[weekKey];
              const weeklyPrice = needs.weeklyPrices[weekKey];
              const price = weeklyPrice || outletPrice || 0;
              const sales = need && price ? round(need * price, 2) : '';
              // sales && console.log({ sales, need, price, rawSales: need * price });
              return sales;
            }),
          ]);
        }),
      );
    }

    let zip = JSZip();

    zip.file(
      'planification.csv',
      toCSV({
        headers: planColumns,
        displayedHeaders: planColumns.map((column) => t(`project:export.planification.${column}`)),
        values: planRows,
      }),
    );
    zip.file(
      'assolement.csv',
      toCSV({
        headers: cropRotationColumns,
        displayedHeaders: cropRotationColumns.map((column) => t(`project:export.rotation.${column}`)),
        values: cropRotationRows,
      }),
    );
    zip.file(
      'ventes.csv',
      [
        sanitizeCsvValues([
          ...['culture', 'outlet'].map((column) => t(`project:export.sales.${column}`)),
          ...time.weeks.map((week) => composeWeek(week.weekNumber)),
        ]),
        ...salesRows,
      ].join('\n'),
    );
    zip
      .generateAsync({
        type: 'base64',
      })
      .then(function (content) {
        window.location.href = 'data:application/zip;base64,' + content;
      });
  };
}
