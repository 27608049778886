import clsx from 'clsx';
import { useSelect } from 'downshift';
import { noop } from 'lodash';
import React, { ReactNode } from 'react';
import ReactDOM from 'react-dom';
import { FieldPath, FieldValues } from 'react-hook-form';
import { Modifier, usePopper } from 'react-popper';
import styled from 'styled-components';
import { ReactComponent as ArrowDown } from '../../../assets/arrow-full-down.svg';
import { ReactComponent as ArrowRight } from '../../../assets/arrow-full-right.svg';
import { colorStyle } from '../../style/colors';
import { getTextStyle } from '../../style/text';
import { Svg } from '../Svg';
import { ControlledHookedField, ControlledHookedFieldProps } from './base/ControlledHookedField';
import { BaseFieldProps } from './base/types';
import { Field } from './Field';

export const StyledContainer = styled(Field)<{
  $hasValue: boolean;
  $error: boolean;
  $disabled: boolean;
  $isOpen: boolean;
}>`
  position: relative;

  & > .input {
    display: flex;
    gap: 8px;

    .toggle-value {
      flex: auto;
    }
    .toggle-button {
      flex: none;
    }
  }
`;

const StyledMenu = styled.ul<{
  $isOpen: boolean;
}>`
  display: ${({ $isOpen }) => ($isOpen ? 'block' : 'none')};
  z-index: 200; // over modal dimmer
  box-sizing: border-box;
  padding: 8px 0px;
  background: ${colorStyle.greys['9-white']};
  border: 1px solid ${colorStyle.greys['5']};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;

  & > .menu-element {
    ${getTextStyle(14, 'regular')}
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 16px;
    height: 36px;
    /* gap: 10px; */
    &.highlighted {
      background-color: ${colorStyle.cta2['5']};
    }
    &.selected {
    }
  }
`;

const sameWidthPopperModifier: Modifier<'sameWidth', {}> = {
  name: 'sameWidth',
  enabled: true,
  phase: 'beforeWrite',
  requires: ['computeStyles'],
  fn: ({ state }) => {
    state.styles.popper.width = `${state.rects.reference.width}px`;
  },
  effect: ({ state }) => {
    state.elements.popper.style.width = `${(state.elements.reference as HTMLElement).offsetWidth}px`;
  },
};

export type SelectProps<T> = BaseFieldProps<T> & {
  options: T[];
  placeholder?: ReactNode;
} & (T extends string
    ? {
        getOptionAsString?: (value: T) => string;
        getOptionLabel?: (value: T) => ReactNode;
      }
    : {
        getOptionAsString: (value: T) => string;
        getOptionLabel?: (value: T) => ReactNode;
      });

export function Select<T>({
  error,
  label,
  onValueChange = noop,
  className,
  disabled,
  onBlur,
  required,
  value,
  options,
  placeholder,
  getOptionAsString = (value: string) => value,
  getOptionLabel = getOptionAsString,
}: SelectProps<T>) {
  const { isOpen, selectedItem, getToggleButtonProps, getLabelProps, getMenuProps, highlightedIndex, getItemProps } =
    useSelect<T>({
      items: options,
      itemToString: getOptionAsString,
      selectedItem: value,
      onSelectedItemChange: ({ selectedItem }) => onValueChange(selectedItem),
    });

  const [referenceElement, setReferenceElement] = React.useState(null);
  const [popperElement, setPopperElement] = React.useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, -10],
        },
      },
      sameWidthPopperModifier,
    ],
  });

  return (
    <StyledContainer
      $disabled={disabled}
      $error={!!error}
      $hasValue={!!value}
      $isOpen={isOpen}
      className={className}
      tabIndex={0}
      onBlur={onBlur}
    >
      {label && (
        <label {...getLabelProps()}>
          {label}
          {required && ' *'}
        </label>
      )}
      <div
        className="input"
        {...getToggleButtonProps({ disabled, ref: setReferenceElement })}
      >
        <span className="toggle-value">{value != null ? getOptionLabel(value) : placeholder}</span>
        <span className="toggle-button">
          <Svg value={isOpen ? ArrowDown : ArrowRight} />
        </span>
      </div>
      {ReactDOM.createPortal(
        <StyledMenu
          $isOpen={isOpen}
          {...getMenuProps({
            $isOpen: isOpen,
            // don't close a potential parent modal
            onMouseDown: (e) => {
              e.stopPropagation();
            },
            onTouchStart: (e) => {
              e.stopPropagation();
            },
            disabled,
            ref: setPopperElement,
            style: styles.popper,
            ...attributes.popper,
          })}
        >
          {isOpen &&
            options.map((item, index) => (
              <li
                className={clsx(
                  'menu-element',
                  highlightedIndex === index && 'highlighted',
                  selectedItem === item && 'selected',
                )}
                key={`${getOptionAsString(item)}${index}`}
                {...getItemProps({ item, index })}
              >
                {getOptionLabel(item)}
              </li>
            ))}
        </StyledMenu>,
        document.body,
      )}
    </StyledContainer>
  );
}

export function HookedSelect<T, Form extends FieldValues>(
  props: ControlledHookedFieldProps<
    FieldPath<Form>,
    // @ts-ignore
    T,
    Form,
    SelectProps<T>
  >,
) {
  return (
    // @ts-ignore
    <ControlledHookedField
      {...props}
      // @ts-ignore
      Child={Select}
    />
  );
}
