import { useTranslation } from '@elzeard/common-components';
import { format, parseISO } from 'date-fns';
import React, { ComponentType, FunctionComponent, MouseEvent } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { routes } from '../../app/routes';
import { Svg } from '../../common/components/Svg';
import { colorStyle } from '../../common/style/colors';
import { getTextStyle } from '../../common/style/text';

const Container = styled.div<{ active: boolean }>`
  background-color: ${colorStyle.backgrounds['1']};
  width: 328px;
  height: 210px;
  border: 3px solid ${(props) => (props.active ? colorStyle.cta1['2-hover'] : colorStyle.backgrounds['1'])};
`;

const CardMedia = styled.div`
  height: 134px;
  width: 100%;
  background-color: ${colorStyle.greys['3']};
`;
const CardInfo = styled.div`
  padding: 17px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 8px;
  color: ${colorStyle.greys['4-defaultIcon']};
`;
const CardTitle = styled.div`
  ${getTextStyle(12, 'semibold')}
`;
const CardDetails = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  ${getTextStyle(10, 'regular')}
`;
const CardDate = styled.div`
  width: 50%;
`;
const CardActions = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

export interface ICardProjectAction {
  label: string;
  onClick: (event: MouseEvent) => any;
  icon: ComponentType;
}

export interface ICardProjectProps {
  farmId?: string;
  name?: string;
  avatar?: string;
  updatedAt?: string;
  actions?: ICardProjectAction[];
  active?: boolean;
}
export const CardProject: FunctionComponent<ICardProjectProps> = ({
  name,
  avatar,
  updatedAt,
  actions,
  active,
  farmId,
}) => {
  const { t, dateTranslations } = useTranslation();
  const history = useHistory();
  const goToOutlets = () => history.push(generatePath(routes.project.details.root, { farmId: farmId.split('/')[1] }));
  const selectProject = () => {
    history.push(generatePath(routes.project.selected, { farmId: farmId.split('/')[1] }));
  };
  return (
    <Container
      active={active}
      onClick={farmId ? selectProject : undefined}
      onDoubleClick={farmId ? goToOutlets : undefined}
    >
      <CardMedia>
        {avatar && (
          <img
            src={avatar}
            alt=""
          />
        )}
      </CardMedia>
      <CardInfo>
        <CardTitle>{name}</CardTitle>
        <CardDetails>
          <CardDate>
            {updatedAt &&
              `${t('project:card.updated-at')} ${format(parseISO(updatedAt), t('common:date-format.full'), {
                locale: dateTranslations,
              })}`}
          </CardDate>
          <CardActions>
            {actions?.map((action) => (
              <button
                key={action.label}
                onClick={action.onClick}
                title={action.label}
              >
                <Svg
                  value={action.icon}
                  size={14}
                />
              </button>
            ))}
          </CardActions>
        </CardDetails>
      </CardInfo>
    </Container>
  );
};

CardProject.defaultProps = {};

export default CardProject;
