import { useTranslation } from '@elzeard/common-components';
import React, { useState } from 'react';
import styled from 'styled-components';
import { NumberInput } from '../../../common/components/field/NumberInput';
import { CenteredModal } from '../../../common/components/modal/CenteredModal';
import { colorStyle } from '../../../common/style/colors';
import { getTextStyle } from '../../../common/style/text';

const StyledModal = styled(CenteredModal)<{ $border: 'length' | 'width' }>`
  width: 250px;
  & > .modal-content {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    gap: 20px;
    /* width: 250px; */
    background: ${colorStyle.cta2['6']};
    border-width: 2px 0px;
    border-style: solid;
    border-color: ${colorStyle.cta2['1']};

    & > .rectangle {
      height: 100px;
      width: 40px;
      background-color: ${colorStyle.greys['9-white']};
      border-style: solid;
      border-width: 4px;
      border-left-color: ${({ $border }) =>
        $border === 'length' ? colorStyle.greys['2'] : colorStyle.greys['6-inactive']};
      border-right-color: ${({ $border }) =>
        $border === 'length' ? colorStyle.greys['2'] : colorStyle.greys['6-inactive']};
      border-top-color: ${({ $border }) =>
        $border === 'width' ? colorStyle.greys['2'] : colorStyle.greys['6-inactive']};
      border-bottom-color: ${({ $border }) =>
        $border === 'width' ? colorStyle.greys['2'] : colorStyle.greys['6-inactive']};
    }

    & > .input-with-unit {
      width: 100%;
      display: flex;
      align-items: center;
      flex-flow: row nowrap;
      gap: 4px;

      .input-container {
        flex: auto;
        min-width: 0;
      }
      .border-unit {
        flex: 0 0 auto;
        padding: 8px 16px;
        gap: 8px;
        border: 1px solid ${colorStyle.greys['5']};
        border-radius: 4px;
        ${getTextStyle(14, 'regular')}
      }
    }
  }
`;

export function BorderStep({
  border,
  initialValue,
  onConfirm,
  onPrevious,
  onClose,
}: {
  border: 'length' | 'width';
  initialValue: number;
  onConfirm: (value: number) => void;
  onPrevious: () => void;
  onClose: () => void;
}) {
  const { t } = useTranslation();
  const [value, setValue] = useState(initialValue);
  const isValid = value && value > 0;
  return (
    <StyledModal
      $border={border}
      title={
        <>
          <div>{t('position:plot-modal.create.border-step-title.main')}</div>
          <div>{t(`position:plot-modal.create.border-step-title.${border}`)}</div>
        </>
      }
      mainActionLabelKey="common:button.next"
      cancelActionLabelKey={border === 'width' ? 'common:button.cancel' : 'common:button.previous'}
      noCloseButton
      disabled={!isValid}
      onConfirm={() => {
        onConfirm(value);
      }}
      onClose={onClose}
      onCancel={onPrevious}
    >
      <div className="modal-content">
        <div className="rectangle" />
        <div className="input-with-unit">
          <NumberInput
            variant="block"
            className="input-container"
            value={value}
            placeholder={t(`position:plot-modal.create.border-placeholder.${border}`)}
            onValueChange={setValue}
            onKeyDown={(event) => {
              if (isValid && event.key === 'Enter') {
                onConfirm(value);
              }
            }}
          />
          <div className="border-unit">{t('position:plot-modal.create.border-unit-meters')}</div>
        </div>
      </div>
    </StyledModal>
  );
}
