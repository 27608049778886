import { useTranslation } from '@elzeard/common-components';
import React, { createRef, RefObject, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { ReactComponent as ArrowFullDownIcon } from '../../../../assets/arrow-full-down.svg';
import { ReactComponent as ArrowFullUpIcon } from '../../../../assets/arrow-full-up.svg';
import { NumberInput } from '../../../../common/components/field/NumberInput';
import { RightFormModal } from '../../../../common/components/modal/RightFormModal';
import { Svg } from '../../../../common/components/Svg';
import { OutletDefaultPrice } from '../../../../common/generated/graphql';
import { colorStyle } from '../../../../common/style/colors';
import { getTextStyle } from '../../../../common/style/text';
import { mod } from '../../../../common/utils/math';
import { translateHarvestUnitEnum } from '../../../../shared-elzeard/constants/translate-constant';
import { BaseOutlet, DistributionCircuit, ParentProduct, PriceLevel } from '../../../state';
import { getItineraryPrices, getPriceValueByLevel } from '../../../utils-prices';
import { Outlet } from '../../state-full';
import { OutletForm } from './types';

const tableBorder = css`
  border: 1px solid ${colorStyle.greys['7']};
  border-collapse: collapse;
`;

const section = css`
  height: 35px;
`;

const Main = styled.div`
  flex: auto;
  overflow-y: auto;
`;

const mainSection = css`
  ${section}
  background-color: ${colorStyle.greys['9-white']};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 11px 0px 20px;
  margin-bottom: 1px;
`;
const Label = styled.div`
  ${mainSection}
  ${getTextStyle(12, 'medium')}
  color: ${colorStyle.greys['4-defaultIcon']};
`;
const Table = styled.table`
  text-align: left;
  color: ${colorStyle.greys['4-defaultIcon']};
  ${tableBorder}

  thead {
    position: sticky;
    inset-block-start: 0;
    z-index: 3;
    tr {
      th {
        ${section}
        ${getTextStyle(14, 'regular')}
        border-bottom: 1px solid ${colorStyle.greys['7']};
        background-color: ${colorStyle.greys['9-white']};
        padding: 0;
        & > * {
          margin: 0;
        }
      }
    }
  }
  tbody {
    background-color: ${colorStyle.greys['9-white']};
    td {
      ${getTextStyle(12, 'regular')}
    }
  }
`;
const TableCell = styled.td`
  height: 35px;
  padding: 0 12px;
  ${tableBorder};
  cursor: default;
`;
const TableHeader = styled(TableCell)`
  ${getTextStyle(12, 'medium')}
  padding: 0 12px 0 20px;
  background-color: ${colorStyle.greys['9-white']};
  .container {
    display: flex;
    align-items: center;
  }
  .unit {
    ${getTextStyle(10, 'regular')}
    margin-left: 1ch;
  }
`;
const TableCellCrop = styled(TableCell)`
  max-width: 134px;
  padding: 0 12px 0 20px;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${getTextStyle(12, 'medium')}
`;
const TableCellPrice = styled(TableCell)`
  padding: 0 11px;
`;
const TableCellPriceContent = styled.div`
  height: 100%;
  width: 134px;
  display: flex;
  align-items: center;
  gap: 8px;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const TableCellUnit = styled(TableCell)`
  width: 100%;
`;
const PriceLevelValue = styled.div`
  flex-grow: 1;
`;
const PriceLevelActions = styled.div`
  display: flex;
  flex-direction: column;
`;
const PriceLevelAction = styled.button`
  color: ${colorStyle.greys['6-inactive']};
  &:hover {
    color: ${colorStyle.greys['4-defaultIcon']};
  }
`;
const PriceLevelInput = styled(NumberInput)`
  width: 35px;
  height: calc(100% - 4px);
  padding: 0 2px;
  border: none;
  ${getTextStyle(12, 'regular')}
  text-align: right;
  &:active,
  &:focus,
  &:focus-visible,
  &:hover {
    border: 1px solid ${colorStyle.cta1['1-active']};
  }
`;

export interface Step2Props {
  onClose: () => void;
  onSave: (defaultPrices: BaseOutlet['defaultPrices']) => void;
  outletForm: OutletForm;
  editedOutlet: Outlet | undefined;
  selectedProducts: ParentProduct[];
}

/**
 * https://www.figma.com/file/rlROk0f9Am247kGr5zNxUO/Pep-new?node-id=966%3A488308
 */
export function Step2({ onClose, onSave, outletForm, editedOutlet, selectedProducts }: Step2Props) {
  const { t } = useTranslation();

  const inputRefs = useRef<Record<string, RefObject<HTMLInputElement>>>(
    Object.fromEntries(selectedProducts.map(({ parentCropItineraryId }) => [parentCropItineraryId, createRef()])),
  );

  const initialValues: {
    [cropItineraryId: string]: OutletDefaultPrice;
  } = useMemo(
    () =>
      Object.fromEntries(
        selectedProducts.map((cropItinerary) => {
          const currentPrice = editedOutlet?.defaultPrices[cropItinerary.parentCropItineraryId];
          if (currentPrice) {
            return [currentPrice.cropItineraryId, currentPrice];
          }
          const referencePrice = getPriceValueByLevel(
            getItineraryPrices(cropItinerary, outletForm.distributionCircuit),
            outletForm.priceLevel,
          );

          const defaultPrice: OutletDefaultPrice =
            referencePrice !== null
              ? {
                  cropItineraryId: cropItinerary.parentCropItineraryId,
                  distributionCircuit: outletForm.distributionCircuit,
                  priceLevel: outletForm.priceLevel,
                  value: referencePrice,
                }
              : {
                  cropItineraryId: cropItinerary.parentCropItineraryId,
                  distributionCircuit: DistributionCircuit.Custom,
                  priceLevel: null,
                  value: null,
                };

          return [defaultPrice.cropItineraryId, defaultPrice];
        }),
      ),
    [selectedProducts, editedOutlet?.defaultPrices, outletForm.distributionCircuit, outletForm.priceLevel],
  );
  const [defaultPrices, setDefaultPrices] = useState(initialValues);

  const changePriceLevel = (
    cropItinerary: ParentProduct,
    defaultPriceToEdit: OutletDefaultPrice,
    action: 'up' | 'down',
  ) => {
    const referencePrices = getItineraryPrices(cropItinerary, outletForm.distributionCircuit);
    const possibleValues: (PriceLevel | null)[] = [null];
    if (referencePrices?.min != null) {
      possibleValues.push(PriceLevel.Low);
    }
    if (referencePrices?.value != null) {
      possibleValues.push(PriceLevel.Medium);
    }
    if (referencePrices?.max != null) {
      possibleValues.push(PriceLevel.High);
    }

    const currentPriceLevelIndex = possibleValues.findIndex((v) => v === defaultPriceToEdit.priceLevel);

    const increment = action === 'up' ? 1 : -1;

    const newLevelIndex = mod(currentPriceLevelIndex + increment, possibleValues.length);
    const newLevel = possibleValues[newLevelIndex];

    setDefaultPrices((defaultPrices) => ({
      ...defaultPrices,
      [defaultPriceToEdit.cropItineraryId]: newLevel
        ? {
            ...defaultPriceToEdit,
            priceLevel: newLevel,
            value: getPriceValueByLevel(referencePrices, newLevel),
          }
        : {
            ...defaultPriceToEdit,
            priceLevel: newLevel,
            distributionCircuit: DistributionCircuit.Custom,
          },
    }));
  };

  const handleSubmit = () => {
    // e.preventDefault();
    onSave(defaultPrices);
  };

  const handleChangePrice = (value: number, defaultPriceToEdit: OutletDefaultPrice, cropItinerary: ParentProduct) => {
    const newPrice: OutletDefaultPrice = value
      ? {
          ...defaultPriceToEdit,
          distributionCircuit: DistributionCircuit.Custom,
          priceLevel: null,
          value,
        }
      : {
          ...defaultPriceToEdit,
          distributionCircuit: outletForm.distributionCircuit,
          priceLevel: outletForm.priceLevel,
          value: getPriceValueByLevel(
            getItineraryPrices(cropItinerary, outletForm.distributionCircuit),
            outletForm.priceLevel,
          ),
        };

    setDefaultPrices((defaultPrices) => ({
      ...defaultPrices,
      [defaultPriceToEdit.cropItineraryId]: newPrice,
    }));
  };

  return (
    <RightFormModal
      title={outletForm.name}
      confirmButtonLabelKey="common:button.validate"
      cancelButtonLabelKey="common:button.cancel"
      onCancel={onClose}
      onConfirm={handleSubmit}
      blueBackground
    >
      <Main>
        <Table>
          <thead>
            <tr>
              <th colSpan={3}>
                <Label>{t('outlet:prices-modal.title')}</Label>
              </th>
            </tr>
            <tr>
              <TableHeader>{t('outlet:prices-modal.crops')}</TableHeader>
              <TableHeader>
                <div className="container">
                  {t('outlet:prices-modal.unit-price')}
                  <div className="unit">{t('outlet:prices-modal.unit-price-unit')}</div>
                </div>
              </TableHeader>
              <TableHeader>{t('outlet:prices-modal.unit')}</TableHeader>
            </tr>
          </thead>
          <tbody>
            {selectedProducts.map((cropItinerary, index) => {
              const defaultPrice = defaultPrices[cropItinerary.parentCropItineraryId];
              return (
                <tr key={cropItinerary.parentCropItineraryId}>
                  <TableCellCrop title={cropItinerary.name}>{cropItinerary.name}</TableCellCrop>
                  <TableCellPrice>
                    <TableCellPriceContent>
                      <PriceLevelActions>
                        <PriceLevelAction
                          onClick={() => changePriceLevel(cropItinerary, defaultPrice, 'up')}
                          type="button"
                        >
                          <Svg
                            size={8}
                            value={ArrowFullUpIcon}
                          />
                        </PriceLevelAction>
                        <PriceLevelAction
                          onClick={() => changePriceLevel(cropItinerary, defaultPrice, 'down')}
                          type="button"
                        >
                          <Svg
                            size={8}
                            value={ArrowFullDownIcon}
                          />
                        </PriceLevelAction>
                      </PriceLevelActions>
                      <PriceLevelValue>
                        {defaultPrice.priceLevel
                          ? t(`outlet:prices-modal.price-level.${defaultPrice.priceLevel}`)
                          : t('outlet:prices-modal.distribution-circuit.custom')}
                      </PriceLevelValue>
                      <PriceLevelInput
                        ref={inputRefs.current[cropItinerary.parentCropItineraryId]}
                        value={defaultPrice.value}
                        onValueChange={(value) => handleChangePrice(value, defaultPrice, cropItinerary)}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            e.stopPropagation();
                            if (index < selectedProducts.length - 1) {
                              const nextItinerary = selectedProducts[index + 1];
                              const nextInput = inputRefs.current[nextItinerary.parentCropItineraryId];
                              nextInput.current.focus();
                              nextInput.current.select();
                            }
                          }
                        }}
                      />
                    </TableCellPriceContent>
                  </TableCellPrice>
                  <TableCellUnit>
                    {cropItinerary.quantityUnit &&
                      // QUESTION short ou long ?
                      translateHarvestUnitEnum(t, cropItinerary.quantityUnit, 'short')}
                  </TableCellUnit>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Main>
    </RightFormModal>
  );
}
