import React, { Fragment } from 'react';
import { weekWidth } from '../../table/style';
import {
  StyledTimeline,
  TimelineCell,
  TimelineEmptyRow,
  TimelineFooterTotalRow,
  TimelineRow,
  TimelineTable,
  TimelineTableBody,
  TimelineTableContainer,
  TimelineTableFoot,
  TimelineTableHead,
} from '../../table/timeline-table-containers';
import { useOutletsState } from '../state-context';
import { OutletTimelineRows } from './OutletTimelineRows';
import { SalesTargetTimelineRow } from './SalesTargetTimelineRow';

export type OutletsTimelineTableProps = { isEditingSingleOutlet: boolean };

export function OutletsTimelineTable({ isEditingSingleOutlet }: OutletsTimelineTableProps) {
  const { outlets, allOutlets, time, displaySalesTarget } = useOutletsState();
  return (
    <TimelineTableContainer>
      <TimelineTable>
        <TimelineTableHead>
          <TimelineRow>
            <TimelineCell colSpan={time.weeks.length}>
              <StyledTimeline
                time={time}
                weekMargin={0}
                weekWidth={weekWidth}
                monthText="full"
              />
            </TimelineCell>
          </TimelineRow>
        </TimelineTableHead>
        <TimelineTableBody>
          {!isEditingSingleOutlet && (
            <>
              {displaySalesTarget && (
                <SalesTargetTimelineRow
                  isDisabled
                  outletRowId={allOutlets.rowId}
                  weeklySalesTarget={allOutlets.weeklySalesTarget}
                />
              )}
              <OutletTimelineRows
                {...{
                  outlet: allOutlets,
                  isAllOutlets: true,
                  isEditingSingleOutlet,
                }}
              />
            </>
          )}
          {outlets.map((outlet) => (
            <Fragment key={outlet.rowId}>
              {displaySalesTarget && (
                <SalesTargetTimelineRow
                  outletRowId={outlet.rowId}
                  weeklySalesTarget={outlet.weeklySalesTarget}
                />
              )}
              <OutletTimelineRows
                {...{
                  outlet,
                  isEditingSingleOutlet,
                }}
              />
            </Fragment>
          ))}
          <TimelineEmptyRow time={time} />
        </TimelineTableBody>
        <TimelineTableFoot>
          {isEditingSingleOutlet ? (
            <>
              <TimelineFooterTotalRow
                entity={outlets[0]}
                property="weeklySales"
                time={time}
              />
              <TimelineFooterTotalRow
                entity={outlets[0]}
                property="weeklySalesTarget"
                time={time}
              />
            </>
          ) : (
            <TimelineFooterTotalRow
              entity={allOutlets}
              property="weeklySales"
              time={time}
            />
          )}
        </TimelineTableFoot>
      </TimelineTable>
    </TimelineTableContainer>
  );
}
