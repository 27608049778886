import { PlantFamilyColors, useTranslation } from '@elzeard/common-components';
import React, { Dispatch, useMemo } from 'react';
import styled from 'styled-components';
import { EditedCell } from '../../outlet/components/EditedCell';
import { AllSeriesCommands } from '../commands';
import { useSeriesCommands } from '../state-context';
import { ProductSerie } from '../state-full';
import { displayValue } from './SeriesHeaderTable';
import { seriesHeights, WeekCell } from './style';

const Container = styled(WeekCell)<{
  $plantFamilyColors: PlantFamilyColors;
  $isEditing: boolean;
  $isImplantationWeek: boolean;
  $isHarvestWeek: boolean;
  $isStorageWeek: boolean;
  $isFirstWeek: boolean;
  $isLastWeek: boolean;
}>`
  height: ${seriesHeights.seriesRow};
  ${({ $isEditing, $isHarvestWeek, $isStorageWeek }) =>
    ($isHarvestWeek || $isStorageWeek) && !$isEditing ? 'cursor: pointer;' : ''}
  ${({ $isEditing }) => ($isEditing ? 'position: relative;' : '')}
  padding: 2px 0;
  .content {
    position: relative; // make it appear over the absolute background
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ $plantFamilyColors, $isImplantationWeek, $isHarvestWeek, $isStorageWeek }) =>
      $isHarvestWeek
        ? $plantFamilyColors.faded
        : $isStorageWeek
        ? $plantFamilyColors.primary
        : $isImplantationWeek
        ? $plantFamilyColors.veryFaded
        : 'transparent'};
    ${({ $isFirstWeek }) =>
      $isFirstWeek
        ? `
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      `
        : ''}
    ${({ $isLastWeek }) =>
      $isLastWeek
        ? `
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      `
        : ''}
  }
`;

export interface EditableSerieNeedCellProps {
  weekKey: string;
  previousWeekKey: string;
  nextWeekKey: string;
  serie: ProductSerie;
  isEditing: boolean;
}

export function EditableSerieNeedCell({
  weekKey,
  previousWeekKey,
  nextWeekKey,
  serie,
  isEditing,
}: EditableSerieNeedCellProps) {
  const { t, formatNumber } = useTranslation();
  const applyCommand = useSeriesCommands();

  const isImplantationWeek = serie.implantationWeeks[weekKey];
  const isHarvestWeek = serie.harvestWeeks[weekKey];
  const isStorageWeek = serie.storageWeeks[weekKey];

  const isEditable = isHarvestWeek || isStorageWeek;
  const isFirstWeek = isImplantationWeek && (!previousWeekKey || !serie.implantationWeeks[previousWeekKey]);
  const isLastWeek =
    isEditable && (!nextWeekKey || (!serie.harvestWeeks[nextWeekKey] && !serie.storageWeeks[nextWeekKey]));
  const value = serie.needs[weekKey];
  const formattedValue = displayValue({
    value,
    t,
    formatNumber,
  });
  return (
    <Container
      key={weekKey}
      $isEditing={isEditing}
      $isImplantationWeek={isImplantationWeek}
      $isHarvestWeek={isHarvestWeek}
      $isStorageWeek={isStorageWeek}
      $isFirstWeek={isFirstWeek}
      $isLastWeek={isLastWeek}
      $plantFamilyColors={serie.plantFamilyColors}
      onClick={
        !isEditable
          ? undefined
          : () =>
              applyCommand({
                type: 'setEditedSeriesCell',
                parentItineraryId: serie.parentItineraryId,
                serieRowId: serie.rowId,
                weekKey,
                editedProperty: null,
              })
      }
    >
      {isEditing ? (
        <EditedSerieNeedCell
          {...{
            serie,
            applyCommand,
            value,
            weekKey,
          }}
        />
      ) : (
        <div className="content">{isEditable ? formattedValue ?? '-' : ''}</div>
      )}
    </Container>
  );
}

interface EditedSerieNeedCellProps {
  serie: ProductSerie;
  weekKey: string;
  value: number;
  applyCommand: Dispatch<AllSeriesCommands>;
}
function EditedSerieNeedCell({ serie, weekKey, value, applyCommand }: EditedSerieNeedCellProps) {
  const { quitEdition, editProperty } = useMemo(() => {
    return {
      quitEdition: () =>
        applyCommand({
          type: 'setEditedSeriesCell',
          parentItineraryId: null,
          serieRowId: null,
          editedProperty: null,
          weekKey: null,
        }),
      editProperty: (value: number) =>
        applyCommand({
          type: 'editSerieNeed',
          parentItineraryId: serie.parentItineraryId,
          productRowId: serie.productRowId,
          childItineraryId: serie.childItineraryId,
          serieRowId: serie.rowId,
          weekKey,
          value,
          needIndex: serie.needsIndexes[weekKey],
        }),
    };
  }, [
    applyCommand,
    serie.childItineraryId,
    serie.needsIndexes,
    serie.parentItineraryId,
    serie.productRowId,
    serie.rowId,
    weekKey,
  ]);

  return (
    <EditedCell
      value={value || null}
      quitEdition={quitEdition}
      editProperty={editProperty}
    />
  );
}
