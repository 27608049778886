/* eslint-disable import/no-anonymous-default-export */
export default {
  panel: {
    'title-settings': 'Paramètres du compte',
    'title-password': 'Modifier le mot de passe',
    'action-change-password': 'Changer mon mot de passe',
    'current-password': 'Mot de passe actuel',
    'new-password': 'Nouveau mot de passe',
    'new-password-confirm': 'Confirmer le mot de passe',
    'password-updated-success': 'Mot de passe modifié avec succès',
  },
};
