import React from 'react';
import { FieldPath, FieldValues } from 'react-hook-form';
import { ReactComponent as CheckedIcon } from '../../../assets/checkbox-checked.svg';
import { ReactComponent as UncheckedIcon } from '../../../assets/checkbox-unchecked.svg';
import { BaseCheckbox, CheckboxProps, Icons } from './base/BaseCheckbox';
import { ControlledHookedField, ControlledHookedFieldProps } from './base/ControlledHookedField';

const icons: Icons = {
  checked: CheckedIcon,
  unchecked: UncheckedIcon,
};

export function Checkbox({ ...props }: CheckboxProps) {
  return (
    <BaseCheckbox
      icons={icons}
      {...props}
    />
  );
}

export function HookedCheckbox<Form extends FieldValues>(
  props: ControlledHookedFieldProps<
    FieldPath<Form>,
    // @ts-ignore
    boolean,
    Form,
    CheckboxProps
  >,
) {
  return (
    // @ts-ignore
    <ControlledHookedField
      {...props}
      // @ts-ignore
      Child={Checkbox}
    />
  );
}
