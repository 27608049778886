import styled from 'styled-components';
import { buttonWithDefaults } from '../../common/components/Button';
import { SecondaryTopbar, secondaryTopbarHeight } from '../../common/components/layouts/SecondaryTopbar';
import { colorStyle } from '../../common/style/colors';
import { getTextStyle } from '../../common/style/text';

export const plotMonthWidth = 64;

export const bedNumberWidth = 30;

export const PositionPageTopbar = styled(SecondaryTopbar)`
  .secondary-topbar-left {
    gap: 10px;
  }
`;

export const NavigationContainer = styled.div`
  display: flex;
`;
export const NavigationButton = buttonWithDefaults({
  variant: 'CTA',
  sizing: 'M',
})`
  height: 40px;
`;
const pageMarginBottom = '30px';

export const StyledScenarioPositionTable = styled.div`
  height: calc(100% - ${secondaryTopbarHeight} - ${pageMarginBottom});
  display: flex;
  gap: 9px;
  width: 100%;
  overflow: hidden;

  .cultures-column {
    flex: none;
    height: 100%;
    min-height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-flow: column nowrap;
    background-color: ${colorStyle.greys['9-white']};
    border: 1px solid ${colorStyle.greys['6-inactive']};
    & > * {
      flex: none;
    }
    .see-positioned-cultures {
      .checkbox-label {
        ${getTextStyle(12, 'regular')}
        color: ${colorStyle.greys['1-black']} !important;
      }
    }
  }
  .cultures-container {
    flex: auto;
    overflow-y: auto;
    overflow-x: visible;

    .months > * {
      box-sizing: border-box; // TODO in common-planning
    }

    .remaining-series-other {
      ${getTextStyle(14, 'regular')}
      color: ${colorStyle.greys['1-black']};
    }

    .serie-container {
      .serie-number {
        ${getTextStyle(12, 'medium')}
        color: ${colorStyle.greys['1-black']};
      }
      .remaining-surface {
        ${getTextStyle(12, 'regular')}
        color: ${colorStyle.greys['1-black']};
      }
    }
  }

  .plots-column {
    flex: auto;
    display: inline-block;
    min-height: 100%;
    max-height: 100%;
    height: 100%;

    .plots-timeline {
      width: 100%;
      background-color: transparent;
      padding: 0 ${bedNumberWidth}px;
      .months-line {
        height: 27px;
        border-bottom: 1px solid ${colorStyle.greys['5']};
      }
      .weeks-line {
        height: 27px;
      }
      .month,
      .week {
        ${getTextStyle(12, 'regular')}
        color: ${colorStyle.greys['4-defaultIcon']};
        height: 100%;
        display: inline-flex;
        justify-content: center;
        align-items: center;
      }
      .week.current {
        border: none;
      }
      /* .month {
      } */
    }

    .plots-container {
      overflow-x: auto;
      height: calc(100% - 59px); // 59px = timeline height
      min-height: calc(100% - 59px); // 59px = timeline height
      display: flex;
      flex-flow: column nowrap;
    }
  }
`;
