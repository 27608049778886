import { useTranslation } from '@elzeard/common-components';

export function mod(n, m) {
  return ((n % m) + m) % m;
}

export function suffixNumber(value: number, formatNumber: ReturnType<typeof useTranslation>['formatNumber']): string {
  if (value == null) {
    return null;
  }
  const roundedValue = Math.round(value);
  if (roundedValue < 1_000) {
    return formatNumber(value, 0, 0);
  }
  if (roundedValue < 10_000) {
    return formatNumber(value / 1000, 1, 2) + 'k';
  }
  if (roundedValue < 100_000) {
    return formatNumber(value / 1000, 0, 1) + 'k';
  }
  if (roundedValue < 1_000_000) {
    return formatNumber(value / 1000, 0, 0) + 'k';
  }
  if (roundedValue < 10_000_000) {
    return formatNumber(value / 1_000_000, 0, 2) + 'M';
  }
  if (roundedValue < 100_000_000) {
    return formatNumber(value / 1_000_000, 0, 1) + 'M';
  }
  if (roundedValue < 1_000_000_000) {
    return formatNumber(value / 1_000_000, 0, 0) + 'M';
  }
  return formatNumber(value / 1_000_000_000, 0, 2) + 'G';
}
