import React from 'react';
import { ReactComponent as SaveIcon } from '../../assets/icon-save.svg';
import { ActionIcon } from '../../common/components/ActionIcon';
import { useCommonProjectCommands, useProjectBaseState } from '../state-context';

export function SaveProjectButton() {
  const { actions, isSaving } = useProjectBaseState();
  const applyCommand = useCommonProjectCommands();

  const hasUnsavedActions = actions.length !== 0;

  return (
    <ActionIcon
      icon={SaveIcon}
      disabled={!hasUnsavedActions}
      loading={isSaving}
      onClick={() =>
        applyCommand({
          type: 'save',
        })
      }
    />
  );
}
