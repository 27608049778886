import { sortBy } from 'lodash';
import React, { FC, useMemo, useState } from 'react';
import { BaseOutlet, DistributionCircuit } from '../../../state';
import { useOutletsState } from '../../state-context';
import { Outlet } from '../../state-full';
import Step1 from './Step1';
import { Step2 } from './Step2';
import { OutletForm } from './types';

export interface OutletCreationFormProps {
  onClose: () => void;
  onSave: (form: OutletForm, defaultPrices: BaseOutlet['defaultPrices']) => void;
  editedOutlet?: Outlet;
}

export const OutletCreationForm: FC<OutletCreationFormProps> = ({ onClose, onSave, editedOutlet }) => {
  const [formStep, setFormStep] = useState<Number>(1);
  const [outletForm, setOutletForm] = useState<OutletForm | null>(
    editedOutlet
      ? {
          name: editedOutlet.name,
          distributionCircuit: editedOutlet.distributionCircuit as DistributionCircuit.Short, // TODO
          priceLevel: editedOutlet.priceLevel,
        }
      : null,
  );

  const onSubmitStep1 = (outletForm: OutletForm) => {
    setOutletForm(outletForm);
    setFormStep(2);
  };

  const onSubmitStep2 = (defaultPrices: BaseOutlet['defaultPrices']) => {
    onSave(outletForm, defaultPrices);
    onClose();
  };

  const state = useOutletsState();
  const selectedProducts = useMemo(
    () => sortBy(Object.values(state.selectedParentProducts), ({ name }) => name),
    [state.selectedParentProducts],
  );

  return (
    <>
      {formStep === 1 && (
        <Step1
          outletForm={outletForm}
          onClose={onClose}
          onSave={onSubmitStep1}
        />
      )}
      {formStep === 2 && (
        <Step2
          outletForm={outletForm}
          selectedProducts={selectedProducts}
          onClose={onClose}
          onSave={onSubmitStep2}
          editedOutlet={editedOutlet}
        />
      )}
    </>
  );
};
