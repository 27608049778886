import React, { FC } from 'react';
import styled from 'styled-components';
import { colorStyle } from '../style/colors';
import { getTextStyle } from '../style/text';
import ButtonObsolete from './ButtonObsolete';

const Title = styled.div`
  margin-bottom: 24px;
  text-align: center;
  ${getTextStyle(16, 'semibold')}
`;
const Body = styled.span`
  ${getTextStyle(12, 'medium')}
`;
const Container = styled.div`
  max-width: 312px;
  box-sizing: border-box;
  min-height: 400px;
  padding: 50px;
  background-color: ${colorStyle.greys['9-white']};
  display: flex;
  flex-direction: column;
`;
const StyledButton = styled(ButtonObsolete)`
  margin-top: auto;
`;

export interface IModalContentProps {
  title: string;
  onClick: () => void;
  children: React.ReactNode;
  buttonLabel: string;
}

export const ModalContent: FC<IModalContentProps> = ({ title, onClick, children, buttonLabel }) => {
  return (
    <Container>
      <Title>{title}</Title>
      <Body>{children}</Body>
      <StyledButton onClick={onClick}>{buttonLabel}</StyledButton>
    </Container>
  );
};

export default ModalContent;
