import { convertPlanningSerie, getStorageBegin, getStorageEnd, NumberMap } from '@elzeard/common-planning';
import {
  CultureModeEnum,
  lengthToUnit,
  LengthUnitEnum,
  parameterToVariable,
  surfaceToUnit,
  SurfaceUnitEnum,
} from '@elzeard/shared-dimensions';
import { sortBy } from 'lodash';
import {
  CultivationBed,
  GetPepiniereProjectQuery,
  PlannedSeries,
  Plot,
  ProductionNeedDetail,
  WeeklyFloat,
} from '../common/generated/graphql';
import { ProductAvailability } from './common/ProductAvailability';
import {
  BaseOutlet,
  BasePlot,
  BasePlotWithBeds,
  BasePlotWithoutBeds,
  BaseProjectPageState,
  ChildProduct,
  DistributionCircuit,
  OutletProductionNeeds,
  PriceLevel,
  PurchaseResaleChild,
} from './state';
import { InitialBaseState } from './state-init';
import { getItineraryPrices, getPriceValueByLevel } from './utils-prices';

type PlotFromDb =
  GetPepiniereProjectQuery['farmForPepiniere']['hasProductionCell']['edges'][0]['node']['hasPlot']['edges'][0]['node'];

export function buildBasePlot(plotFromDb: PlotFromDb): BasePlot {
  const basePlot: BasePlotWithoutBeds = {
    plotId: plotFromDb.id,
    plotName: plotFromDb.identifierPlot,
    cultureMode: plotFromDb.hasCultureMode.id as CultureModeEnum,
    length: lengthToUnit(plotFromDb.hasLength, LengthUnitEnum.Meter),
    width: lengthToUnit(plotFromDb.hasWidth, LengthUnitEnum.Meter),
    surface: surfaceToUnit(plotFromDb.hasCultivableSurface, SurfaceUnitEnum.SquareMeter),
  };
  const numberOfBeds = plotFromDb.hasCultivationBed.edges.length;
  if (numberOfBeds) {
    const anyBed = plotFromDb.hasCultivationBed.edges[0].node;
    const footpassWidth = lengthToUnit(plotFromDb.hasFootPassWidth);
    const basePlotWithBeds: BasePlotWithBeds = {
      ...basePlot,
      bedsWidth: parameterToVariable(anyBed.hasWidth),
      footpathWidth: parameterToVariable<LengthUnitEnum>(plotFromDb.hasFootPassWidth),
      numberOfBeds,
      beds: plotFromDb.hasCultivationBed.edges.map(({ node: bed }) => ({
        bedId: bed.id,
        bedNumber: bed.bedNumber,
        footpassWidth,
        length: lengthToUnit(bed.hasLength),
        width: lengthToUnit(bed.hasWidth),
      })),
    };
    return basePlotWithBeds;
  } else {
    return basePlot;
  }
}

export function buildBaseState(
  dbObject: GetPepiniereProjectQuery['farmForPepiniere'],
  initialBaseState: InitialBaseState,
): Pick<
  BaseProjectPageState,
  'outletsByRowId' | 'otherPossibleParentProducts' | 'selectedParentProducts' | 'basePlots'
> {
  const { basePlots, plotsById, bedsById } = dbObject.hasProductionCell.edges.reduce(
    (acc, { node: cell }) => {
      const { plotsById, bedsById, basePlots } = acc;
      cell.hasPlot.edges.forEach(({ node: plot }) => {
        basePlots.push(buildBasePlot(plot));
        plotsById[plot.id] = plot as Plot;
        plot.hasCultivationBed.edges.forEach(({ node: bed }) => {
          bedsById[bed.id] = [bed as CultivationBed, plot as Plot];
        });
      });
      return acc;
    },
    {
      plotsById: {} as Record<string, Plot>,
      bedsById: {} as Record<string, [CultivationBed, Plot]>,
      basePlots: [] as BasePlot[],
    },
  );
  const {
    allPossibleParentProducts,
    parentItineraryIdByChildId,
    // TODO parentItineraryIdByName,
  } = initialBaseState;

  const unselectedParentCropItinerariesIds = Object.fromEntries(
    (dbObject.isPepiniereProject.unselectedParentCropItinerariesIds || []).map((parentCropItineraryId) => [
      parentCropItineraryId,
      true,
    ]),
  );

  const { otherPossibleParentProducts, selectedParentProducts } = Object.values(allPossibleParentProducts).reduce(
    (acc, possibleParentProduct) => {
      if (unselectedParentCropItinerariesIds[possibleParentProduct.parentCropItineraryId]) {
        acc.otherPossibleParentProducts[possibleParentProduct.parentCropItineraryId] = possibleParentProduct;
      } else {
        acc.selectedParentProducts[possibleParentProduct.parentCropItineraryId] = possibleParentProduct;
      }
      return acc;
    },
    {
      selectedParentProducts: {},
      otherPossibleParentProducts: {},
    } as Pick<BaseProjectPageState, 'selectedParentProducts' | 'otherPossibleParentProducts'>,
  );

  for (const { node: dbProduct } of dbObject.isPepiniereProject.hasPurchaseResale.edges) {
    // dirty trick to store the parentCropItineraryId as the product name...
    const parentCropItineraryId = dbProduct.name;
    if (!parentCropItineraryId) {
      console.log('Corrupted purchase-resale product ignored: no crop itinerary', dbProduct);
      continue;
    }

    let parentProduct = selectedParentProducts[parentCropItineraryId];
    if (!parentProduct) {
      parentProduct = otherPossibleParentProducts[parentCropItineraryId];
      // deactivated product
      if (!parentProduct) {
        console.log('Corrupted purchase-resale product ignored: matches a non-existent or corrupted ITK', dbProduct);
        continue;
      }
    }
    if (parentProduct.purchaseResale) {
      console.log(
        'Corrupted purchase-resale product ignored: a parent product has more than 1 purchase-resale child',
        parentProduct,
        dbProduct,
      );
      continue;
    }
    if (dbProduct.hasProductionNeed?.edges?.length !== 1) {
      console.log('Corrupted purchase-resale product ignored: has not exactly 1 production need', dbProduct);
      continue;
    }

    const clientNeeds = dbProduct.hasProductionNeed.edges[0].node;

    const childProduct: PurchaseResaleChild = {
      parentCropItineraryId,
      productId: dbProduct.id,
      productionNeedId: clientNeeds.id,
      weeklyNeeds: convertNeeds(clientNeeds.needs),
      isUpdated: false,
      isToBeDeleted: false,
    };

    parentProduct.purchaseResale = childProduct;
  }

  for (const { node: dbProduct } of dbObject.isPepiniereProject.hasProduct.edges) {
    // We need to store the outlets' needs on the parent product,
    //   so we need to store parent products as well as children products in the DB.
    //   In order to distinguish parent and children, we use the name property of PlannedProduct.
    //   For a parent product, PlannedProduct.name stores the name of the product.
    //   For a child product, PlannedProduct.name stores the parent crop itinerary ID.
    if (!dbProduct.hasCropItinerary) {
      console.log('Corrupted product ignored: an existing product has no crop itinerary', dbProduct);
      continue;
    }
    if (allPossibleParentProducts[dbProduct.name]) {
      // dbProduct is a child product => should have series but no client needs
      // TODO assert no client needs ? Maybe we should wait for all obsolete children to be deleted from the DB
      // TODO assert has 1 or 2 series ?

      const series = dbProduct.hasPlannedSeries.edges.map(({ node: dbSerie }) =>
        convertPlanningSerie(dbSerie as PlannedSeries, plotsById, bedsById),
      );
      const [harvestPeriods, storagePeriods] = series.reduce(
        (acc, serie) => {
          acc[0].push({
            begin: serie.harvest.begin,
            end: serie.harvest.end,
            weeks: serie.harvestWeeks,
          });
          acc[1].push({
            begin: getStorageBegin(serie.harvest.end, serie.storageDays),
            end: getStorageEnd(serie.harvest.end, serie.storageDays),
            weeks: serie.storageWeeks,
          });
          return acc;
        },
        [[], []] as [ProductAvailability[], ProductAvailability[]],
      );

      let childCropItineraryId = dbProduct.hasCropItinerary.id;
      let parentCropItineraryId = parentItineraryIdByChildId[childCropItineraryId];
      if (!parentCropItineraryId) {
        // custom child product
        parentCropItineraryId = childCropItineraryId;
        childCropItineraryId = null;
      }

      let parentProduct = selectedParentProducts[parentCropItineraryId];
      if (!parentProduct) {
        parentProduct = otherPossibleParentProducts[parentCropItineraryId];
        // deactivated product
        if (!parentProduct) {
          console.log('An existing product matches a non-existent or corrupted ITK', dbProduct);
          continue;
        }
      }

      const possibleChildProduct =
        childCropItineraryId && parentProduct.otherPossibleChildrenByChildItineraryId[childCropItineraryId];
      if (possibleChildProduct) {
        delete parentProduct.otherPossibleChildrenByChildItineraryId[childCropItineraryId];
        // TODO assert that dbProduct.hasCultureMode.id, dbProduct.name and series dates match with possibleChildProduct values
      }
      const { plantFamily, plantFamilyColors, plantId, plantName, quantityUnit, name } = parentProduct;
      // const maturationWeeks = differenceInWeeks(series[0].harvest.begin.firstDay, series[0].begin.firstDay);

      const childProduct: ChildProduct = {
        plantFamily,
        plantFamilyColors,
        plantId,
        plantName,
        quantityUnit,
        name, // TODO update later, once we're sure to have the parent's name from the saved product
        parentCropItineraryId,
        childCropItineraryId,
        productId: dbProduct.id,
        rowId: dbProduct.id,
        cultureMode: dbProduct.hasCultureMode.id as CultureModeEnum,
        isUpdated: false,
        harvestPeriods,
        storagePeriods,
        series,
        serieToRemoveId: null,
        itinerarySeries: possibleChildProduct?.itinerarySeries,
        itineraryYield: possibleChildProduct?.itineraryYield,
        itkRotation: null,
        rotation: null,
      };

      parentProduct.selectedChildrenByRowId[childProduct.rowId] = childProduct;
    } else if (allPossibleParentProducts[dbProduct.hasCropItinerary.id]) {
      // dbProduct is a parent product => should have client needs but no series
      // TODO assert has production needs (?) and no series
      const productionNeeds: Array<[string, OutletProductionNeeds]> = dbProduct.hasProductionNeed.edges.map(
        ({ node: clientNeeds }) => {
          const weeklyNeeds = convertNeeds(clientNeeds.needs);
          const weeklyPrices = convertNeeds(clientNeeds.prices);
          const defaultUnitPrice = clientNeeds.unitPrice;
          return [
            clientNeeds.concernsClient.id,
            {
              productionNeedId: clientNeeds.id,
              isUpdated: false,
              weeklyNeeds,
              weeklyPrices,
              defaultUnitPrice,
            },
          ];
        },
      );

      let parentProduct = selectedParentProducts[dbProduct.hasCropItinerary.id];
      if (!parentProduct) {
        parentProduct = otherPossibleParentProducts[dbProduct.hasCropItinerary.id];
        // deactivated product
        if (!parentProduct) {
          console.log('An existing product matches a non-existent or corrupted ITK', dbProduct);
          continue;
        }
      }

      parentProduct = {
        ...parentProduct,
        productId: dbProduct.id,
        productionNeedsByOutletRowId: Object.fromEntries(productionNeeds),
        name: dbProduct.name,
      };
      selectedParentProducts[dbProduct.hasCropItinerary.id] = parentProduct;
    } else {
      console.warn(`Invalid product ignored [productId=${dbProduct.id}]`);
      continue;
    }
  }

  const outletsByClientId = Object.fromEntries(
    dbObject.isPepiniereProject.hasOutlet.edges.map(({ node }) => {
      const {
        /*hasBasketOffer,*/ id,
        isDisabled,
        name,
        salesTarget,
        concernsClient,
        hasDefaultPrices,
        distributionCircuitPrices,
        priceLevel,
      } = node;
      const clientId = concernsClient.id;
      const outletRowId = clientId;

      const outletDefaultPrices = hasDefaultPrices
        ? Object.fromEntries(
            hasDefaultPrices.map((price) => {
              const parentItinerary =
                selectedParentProducts[price.cropItineraryId] || otherPossibleParentProducts[price.cropItineraryId];
              return [
                price.cropItineraryId,
                !price || price.distributionCircuit === DistributionCircuit.Custom
                  ? price
                  : {
                      ...price,
                      value: getPriceValueByLevel(
                        getItineraryPrices(parentItinerary, price.distributionCircuit as DistributionCircuit),
                        price.priceLevel as PriceLevel,
                      ),
                    },
              ];
            }),
          )
        : {};
      const outlet: BaseOutlet = {
        isDisabled,
        isUpdated: false,
        name,
        outletId: id,
        clientId,
        defaultPrices: outletDefaultPrices,
        distributionCircuit: (distributionCircuitPrices as DistributionCircuit) || DistributionCircuit.Short,
        priceLevel: priceLevel as PriceLevel,
        rowId: outletRowId,
        weeklySalesTarget: convertWeeklyFloats(salesTarget),
      };
      return [outletRowId, outlet];
    }),
  );

  return {
    outletsByRowId: outletsByClientId,
    otherPossibleParentProducts,
    selectedParentProducts,
    basePlots: sortBy(basePlots, (plot) => plot.plotName),
  };
}

function convertNeeds(needs: Array<ProductionNeedDetail>): NumberMap {
  return Object.fromEntries(needs.map(({ year, week, volume }) => [year + '-' + week, volume]));
}

function convertWeeklyFloats(needs: Array<WeeklyFloat>): NumberMap {
  return Object.fromEntries(needs.map(({ year, week, number }) => [year + '-' + week, number]));
}

export function buildProductAvailabilityPeriod(product: ChildProduct): ProductAvailability[] {
  return product.harvestPeriods.map((harvestPeriod, periodIndex) => {
    const storagePeriod = product.storagePeriods[periodIndex];
    if (!storagePeriod) {
      console.warn('WTF?');
      return harvestPeriod;
    }
    if (storagePeriod.begin) {
      return {
        begin: harvestPeriod.begin,
        end: storagePeriod.end,
        weeks: {
          ...harvestPeriod.weeks,
          ...storagePeriod.weeks,
        },
      };
    } else {
      return harvestPeriod;
    }
  });
}
