import { gql } from '@apollo/client';

export const MyProjectFragment = gql`
  fragment MyProjectFragment on Farm_Farm {
    id
    name
    address {
      street1
      street2
      postalCode
      city
    }
    geolocation {
      lat
      long
    }
    hasCulturalContext {
      cultivatesIn {
        edges {
          node {
            id
          }
        }
      }
    }
    isPepiniereProject {
      id
      beginDatePlan
      endDatePlan
      lastSaveAt
      hasOutlet {
        edges {
          node {
            id
            name
            isDisabled
          }
        }
      }
    }
  }
`;

export const MyProjects = gql`
  ${MyProjectFragment}
  query MyProjects {
    farmsForPepiniere {
      ...MyProjectFragment
    }
  }
`;


export const MyProject = gql`
  ${MyProjectFragment}
  query MyProject($id: ID!) {
    farmForPepiniere(id: $id) {
      ...MyProjectFragment
    }
  }
`;

export const CreatePepiniereProject = gql`
  ${MyProjectFragment}
  mutation CreatePepiniereProject($farmInput: Farm_FarmInput!, $projectInput: PepiniereProjectInput!) {
    createPepiniereProject(farmInput: $farmInput, projectInput: $projectInput) {
      createdObject {
        ...MyProjectFragment
      }
    }
  }
`;

export const UpdatePepiniereProjectResume = gql`
  ${MyProjectFragment}
  mutation UpdatePepiniereProjectResume(
    $farmId: ID!
    $farmInput: Farm_FarmInput
    $projectInput: PepiniereProjectInput
  ) {
    updatePepiniereProject(farmId: $farmId, farmInput: $farmInput, projectInput: $projectInput) {
      updatedObject {
        ...MyProjectFragment
      }
    }
  }
`;

export const RemovePepiniereProject = gql`
  mutation RemovePepiniereProject($farmId: ID!) {
    removePepiniereProject(farmId: $farmId) {
      deletedFarmId
    }
  }
`;
