import { CultureModeEnum, LengthUnitEnum, variableToParameterInput } from '@elzeard/shared-dimensions';
import React, { useState } from 'react';
import { usePositionState } from '../state-context';
import { BedsInfoModal } from './BedsInfoModal';
import { BorderStep } from './BorderStep';
import { PlotInfoModal } from './PlotInfoModal';
import { useCreatePlotMutation } from './useCreatePlotMutation';

export interface PlotForm {
  width: number;
  length: number;
  name: string;
  cultureMode: CultureModeEnum;
  withBeds: boolean;
}

type Step = 'width' | 'length' | 'infos' | 'beds';

export function CreatePlotModal({ onClose }: { onClose: () => void }) {
  const { farmId } = usePositionState();

  const [form, setForm] = useState<PlotForm>({
    width: null,
    length: null,
    name: null,
    cultureMode: null,
    withBeds: null,
  });
  const [step, setStep] = useState<Step>('width');

  const [
    triggerCreatePlotMutation,
    {
      loading,
      // TODO error
    },
  ] = useCreatePlotMutation(farmId, onClose);

  switch (step) {
    case 'width':
      return (
        <BorderStep
          key={step}
          border={step}
          initialValue={form.width}
          onConfirm={(value) => {
            setForm((form) => ({
              ...form,
              width: value,
            }));
            setStep('length');
          }}
          onPrevious={onClose}
          onClose={onClose}
        />
      );
    case 'length':
      return (
        <BorderStep
          key={step}
          border={step}
          initialValue={form.length}
          onConfirm={(value) => {
            setForm((form) => ({
              ...form,
              length: value,
            }));
            setStep('infos');
          }}
          onPrevious={() => setStep('width')}
          onClose={onClose}
        />
      );
    case 'infos':
      return (
        <PlotInfoModal
          value={form}
          loading={loading}
          onClose={onClose}
          onPrevious={() => setStep('length')}
          onConfirm={(value) => {
            if (value.withBeds) {
              setForm(value);
              setStep('beds');
            } else {
              triggerCreatePlotMutation({
                variables: {
                  farmId: farmId,
                  input: {
                    hasCultivationBedInputs: null,
                    hasCultureModeInput: { id: value.cultureMode },
                    hasLengthInput: variableToParameterInput({ value: value.length, unit: LengthUnitEnum.Meter }),
                    hasWidthInput: variableToParameterInput({ value: value.width, unit: LengthUnitEnum.Meter }),
                    identifierPlot: value.name,
                  },
                },
              });
            }
          }}
        />
      );
    case 'beds':
      return (
        <BedsInfoModal
          loading={loading}
          plotForm={form}
          onClose={onClose}
          onPrevious={() => setStep('infos')}
          onConfirm={(value) => {
            triggerCreatePlotMutation({
              variables: {
                farmId,
                input: {
                  hasCultureModeInput: { id: value.cultureMode },
                  hasLengthInput: variableToParameterInput({ value: value.length, unit: LengthUnitEnum.Meter }),
                  hasWidthInput: variableToParameterInput({ value: value.width, unit: LengthUnitEnum.Meter }),
                  identifierPlot: value.name,
                  hasFootPassWidthInput: variableToParameterInput({ value: value.footpassWidthValue, unit: value.footpassWidthUnit }),
                  hasCultivationBedInputs: Array(value.numberOfBeds).fill(0).map((_, index) => ({
                    bedNumber: index+1,
                    hasWidthInput: variableToParameterInput({ value: value.bedWidthValue, unit: value.bedWidthUnit }),
                    hasLengthInput: variableToParameterInput({ value: value.length, unit: LengthUnitEnum.Meter }),
                  }))
                },
              },
            });
          }}
        />
      );
  }
}
