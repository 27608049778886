import styled, { css } from 'styled-components';
import { Token } from '../../common/components/Token';
import { colorStyle } from '../../common/style/colors';
import { getTextStyle } from '../../common/style/text';
import { borderColor } from './page-containers';

// COMMON STYLES

export const height = {
  monthRow: '35px',
  monthBottomBorder: '1px',
  weekRow: '35px',
  headerRow: '71px',
  footerRow: '40px',
};

export const thead = css`
  position: sticky;
  inset-block-start: 0;
  background-color: ${colorStyle.greys['9-white']};
  // workaround to display a border on a sticky table element in Chrome (see https://stackoverflow.com/questions/57166162/table-headers-positionsticky-and-border-issue)
  &::before {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    border-bottom: 1px solid ${borderColor};
    z-index: 2;
  }
  /* // display a background color overridable
  &::after {
    content: '';
    position: absolute;
    background-color: ${colorStyle.greys['9-white']};
    left: 0;
    width: 100%;
  } */
`;

export const tfoot = css`
  position: sticky;
  inset-block-end: 0;
  z-index: 1;
  // workaround to display a border on a sticky table element in Chrome (see https://stackoverflow.com/questions/57166162/table-headers-positionsticky-and-border-issue)
  &::after {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    border-bottom: 1px solid ${borderColor};
  }
`;

export const headerCell = css`
  box-sizing: border-box;
  border-right: 1px solid ${colorStyle.greys['7']};
  border-bottom: 1px solid ${colorStyle.greys['7']};
  text-align: left;
`;

export const HeaderRow = styled.tr`
  :hover {
    background-color: ${colorStyle.cta2['6']};
  }
`;

export const TopHeaderCell = styled.th`
  ${headerCell}
  height: ${height.headerRow};
  padding: 13px 4px 0;
  vertical-align: top;
`;
export const TopHeaderTitle = styled.div`
  flex: auto;
  ${getTextStyle(12, 'medium')}
`;
export const TopHeaderUnit = styled.div`
  ${getTextStyle(12, 'regular', 'italic')}
`;

export const firstHeaderCell = css`
  ${headerCell}
  padding: 0 16px;
  color: ${colorStyle.greys['1-black']};
  .content {
    display: flex;
    align-items: center;
  }
  .arrow-box {
    margin-right: 8px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  & ${Token} {
    margin-right: 12px;
  }
  .title {
    flex: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .right-icons {
    gap: 8px;
    margin-left: 8px;
    display: flex;
    align-items: center;
  }
`;

// HEADER TABLE STYLES

// used for padding
export const MarginRightCell = styled.td`
  box-sizing: border-box;
  min-width: 13px;
`;

// TIMELINE TABLE STYLES

export const weekWidth = 35; // px

export const timelineRow = ``;
export const timelineCell = `
  text-align: center;
  border-width: 0 1px 0 0;
  border-style: solid;
  border-color: ${colorStyle.greys['7']};
  width: ${weekWidth - 1}px;
  box-sizing: border-box;
  padding: 0;
  `;
// TODO find a way to hover the column without fucking up the scrollbar
//  // column hover
// position: relative;
//  &:hover::after {
//    background-color: ${colorStyle.cta2['6']};
//    content: '';
//    height: 10000px;
//    left: 0;
//    position: absolute;
//    top: -5000px;
//    width: ${weekWidth}px;
//    z-index: -1;
//  }
