import React, { FC } from 'react';
import styled from 'styled-components';
import { colorStyle, getPlantFamilyColor } from '../style/colors';
import { getTextStyle } from '../style/text';

export interface IErrorMessageProps {
  message: React.ReactNode;
}

const errorColor = getPlantFamilyColor('solanacees', 'faded');

const Container = styled.div`
  padding: 10px 16px;
  /* background-color: ${colorStyle.greys['9-white']}; */
  color: ${errorColor};
  ${getTextStyle(12, 'regular')}
`;

export const ErrorMessage: FC<IErrorMessageProps> = ({ message }) => {
  return message ? <Container>{message}</Container> : null;
};
