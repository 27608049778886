import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { otherColors } from '../../style/colors';

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('/cadre_poireau_opacity.png');
  background-size: 220px;
  background-color: ${otherColors.fondAuth};
`;

export function LayoutAuthentication(props: PropsWithChildren<{}>) {
  return <Container>{props.children}</Container>;
}
