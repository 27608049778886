import React, { Dispatch } from 'react';
import { ConfirmationModal, ConfirmationModalProps } from './ConfirmationModal';

export type ConfirmCommandModalProps<C> = Omit<ConfirmationModalProps, 'onConfirm'> & {
  command: C;
  applyCommand: Dispatch<C>;
};

export function ConfirmCommandModal<C>({
  command,
  applyCommand,
  ...confirmationModalProps
}: ConfirmCommandModalProps<C>) {
  return (
    <ConfirmationModal
      {...confirmationModalProps}
      onConfirm={() => {
        applyCommand(command);
        confirmationModalProps.onCancel();
      }}
    />
  );
}
