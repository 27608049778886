import { useSpacetimeApplyCommand, useSpacetimeState } from '@elzeard/common-planning';
import { Dispatch } from 'react';
import { ProjectPageState } from '../state';
import { AllPositionPageCommands } from './commands';
import { PositionPageState } from './state-full';

export function usePositionState() {
  return useSpacetimeState() as ProjectPageState<PositionPageState>;
}
export function usePositionApplyCommand() {
  return useSpacetimeApplyCommand() as Dispatch<AllPositionPageCommands>;
}
