import { Timeline, useTranslation } from '@elzeard/common-components';
import { NumberMap } from '@elzeard/common-planning';
import React from 'react';
import styled from 'styled-components';
import { colorStyle } from '../../common/style/colors';
import { getTextStyle } from '../../common/style/text';
import { KeyOfType } from '../../common/utils/types';
import { displayValue } from '../outlet/components/OutletsHeaderTable';
import { getWeekKey } from '../outlet/utils';
import { ProjectPageState } from '../state';
import { rightMargin } from './page-containers';
import { height, tfoot, thead, timelineCell, timelineRow, weekWidth } from './style';

export const TimelineTableContainer = styled.div`
  display: flex;
  position: sticky;
  padding-bottom: 0px;
  z-index: 2;
  left: 0;
  background-color: ${colorStyle.greys['9-white']};
  margin-left: 8px; // .timeline-header-table::after width
`;

export const TimelineTable = styled.table`
  position: relative;
  border-spacing: 0;
  border-collapse: collapse;
  margin-bottom: 2px; // for the border pseudo-elements
  margin-right: ${rightMargin};
`;

// HEADER
export const TimelineTableHead = styled.thead`
  ${thead}
  z-index: 2;
`;
export const TimelineRow = styled.tr`
  ${timelineRow}
`;
export const TimelineCell = styled.th`
  border: 0;
  padding: 0;
  height: ${height.monthRow};
`;
export const StyledTimeline = styled(Timeline)`
  /* display: inline-flex;
  overflow: hidden; */
  padding: 0;
  .months-line {
    height: ${height.monthRow};
    line-height: ${height.monthRow};
    background-color: ${colorStyle.greys['9-white']};
  }
  .month {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    box-sizing: border-box;
    ${getTextStyle(16, 'medium')}
    color: ${colorStyle.greys['4-defaultIcon']};
    border-right: 1px solid ${colorStyle.greys['7']};
    &:first-child {
      border-left: 1px solid ${colorStyle.greys['7']};
    }
  }
  /* .weeks-line {
    margin-top: calc(${height.monthRow});
  } */
  .week {
    justify-content: center;
    height: ${height.weekRow}; //calc(${height.weekRow} + ${height.monthBottomBorder});
    width: ${weekWidth}px;
    box-sizing: border-box;
    ${getTextStyle(14, 'semibold')}
    color: ${colorStyle.greys['2']};
    border-top: ${height.monthBottomBorder} solid ${colorStyle.greys['7']};
    border-right: 1px solid ${colorStyle.greys['7']};
    &:first-child {
      border-left: 1px solid ${colorStyle.greys['7']};
    }
  }
`;

// FOOTER keeps the 2 tables synced while scrolling
export const TimelineTableFoot = styled.tfoot`
  ${tfoot}
  background: #fcfbfa;
  td {
    height: ${height.footerRow};
  }
`;

export const TimelineFooterCell = styled.td`
  height: ${height.footerRow};
  ${timelineCell}
  ${getTextStyle(14, 'semibold')}
  color: ${colorStyle.greys['2']};
  // workaround to display a border on a sticky table element in Chrome (see https://stackoverflow.com/questions/57166162/table-headers-positionsticky-and-border-issue)
  position: relative;
  &::before {
    content: '';
    position: absolute;
    left: -1px;
    right: 0;
    top: 0;
    height: 100%;
    border-width: 0 1px;
    border-style: solid;
    border-color: ${colorStyle.greys['7']};
  }
  & > div {
    width: ${weekWidth - 1}px;
  }
`;

export function TimelineFooterTotalRow<T, K extends KeyOfType<T, NumberMap>>({
  entity,
  property,
  time,
}: Pick<ProjectPageState<unknown>, 'time'> & {
  entity: T;
  property: K;
}) {
  const { t, formatNumber } = useTranslation();
  return (
    <tr>
      {time.weeks.map((week, weekIndex) => {
        const weekKey = getWeekKey(week);
        const value = entity[property][weekKey];
        return (
          <TimelineFooterCell key={weekIndex}>
            <div>
              {displayValue({
                value,
                t,
                formatNumber,
              })}
            </div>
          </TimelineFooterCell>
        );
      })}
    </tr>
  );
}

// BODY

// background color under the column hover
export const TimelineTableBody = styled.tbody`
  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: -2;
    background-color: ${colorStyle.greys['9-white']};
  }
`;

const EmptyRow = styled.tr`
  ${timelineRow}
`;
const EmptyCell = styled.td`
  ${timelineCell}
  height: 100%;
`;

/**
 * Used as a vertically growing flex element
 */
export function TimelineEmptyRow({ time }: Pick<ProjectPageState<unknown>, 'time'>) {
  return (
    <EmptyRow>
      {time.weeks.map((week, weekIndex) => {
        return <EmptyCell key={weekIndex}></EmptyCell>;
      })}
    </EmptyRow>
  );
}
