import React, { MutableRefObject } from 'react';
import { ChangeHandler, Control, FieldPath, FieldValues, Path, PathValue, useController } from 'react-hook-form';
import { BaseFieldProps } from './types';

export type ControlledHookedFieldProps<
  TFieldName extends FieldPath<Form>,
  TFieldValue extends PathValue<Form, TFieldName>,
  Form extends FieldValues,
  Props extends BaseFieldProps<TFieldValue>,
> = Props & {
  fieldName: TFieldName;
  control: Control<Form>;
  forwardedRef?: MutableRefObject<(props: Props) => void>;
};

export function ControlledHookedField<
  TFieldName extends FieldPath<Form>,
  TFieldValue extends PathValue<Form, TFieldName>,
  Form extends FieldValues,
  Props extends BaseFieldProps<TFieldValue>,
>({
  Child,
  fieldName,
  control,
  required,
  disabled,
  onBlur,
  value,
  forwardedRef,
  ...inputProps
}: ControlledHookedFieldProps<TFieldName, TFieldValue, Form, Props> & {
  Child: React.ComponentType<Props>;
}) {
  const { field, fieldState } = useController({
    name: fieldName as Path<Form>,
    control,
    rules: {
      onBlur,
      required,
      value: value as PathValue<Form, Path<Form>>,
    },
  });
  return (
    // @ts-ignore
    <Child
      {...inputProps}
      required={required}
      disabled={disabled}
      onValueChange={field.onChange}
      onBlur={field.onBlur as unknown as ChangeHandler}
      value={field.value}
      forwardedRef={forwardedRef || field.ref} // TODO needed ?
      error={fieldState.error?.message}
    />
  );
}
