import { useTranslation } from '@elzeard/common-components';
import React, { Dispatch, MutableRefObject, useRef } from 'react';
import { useClickAway } from 'react-use';
import styled from 'styled-components';
import { Svg } from '../../../common/components/Svg';
import { colorStyle } from '../../../common/style/colors';
import { getTextStyle } from '../../../common/style/text';
import { AllSeriesCommands } from '../commands';
import { ProductSerie } from '../state-full';
import { IconEnumField, SerieHeaderColumnName } from './SeriesHeaderTable';
import { seriesHeights, WeekCell } from './style';

const Container = styled(WeekCell)<{
  isEditing: boolean;
}>`
  height: ${seriesHeights.seriesRow};
  ${({ isEditing }) => (!isEditing ? 'cursor: pointer;' : '')}
  ${({ isEditing }) => (isEditing ? 'position: relative;' : '')}
  padding: 2px 0;
  .content {
    position: relative; // make it appear over the absolute background
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
const StyledMenu = styled.div`
  position: absolute;
  left: 0;
  top: calc(${seriesHeights.seriesRow});
  padding: 8px 0px;
  background: ${colorStyle.greys['9-white']};
  border: 1px solid ${colorStyle.greys['5']};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 5px 5px;
  z-index: 1;

  .item {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 10px 16px;
    gap: 8px;
    cursor: pointer;

    &:hover {
      background-color: ${colorStyle.cta2['6']};
    }
  }
  .label {
    ${getTextStyle(14, 'regular')}
    color: ${colorStyle.greys['1-black']};
    white-space: nowrap;
  }
`;

export interface SerieIconHeaderCellProps {
  propertyName: SerieHeaderColumnName;
  fieldDefinition: IconEnumField;
  serie: ProductSerie;
  isEditing: boolean;
  applyCommand: Dispatch<AllSeriesCommands>;
}

export function SerieIconHeaderCell({
  propertyName,
  serie,
  isEditing,
  fieldDefinition,
  applyCommand,
}: SerieIconHeaderCellProps) {
  const { t } = useTranslation();
  const value = serie[propertyName] as string;

  const refMainMenu = useRef<HTMLDivElement>(null);
  const refMainMenuButton = useRef<HTMLTableCellElement>(null);
  useClickAway(refMainMenu, (e) => {
    if (!refMainMenuButton.current?.contains(e.target as Node)) {
      applyCommand({
        type: 'setEditedSeriesCell',
        parentItineraryId: null,
        serieRowId: null,
        weekKey: null,
        editedProperty: null,
      });
    }
  });
  return (
    <Container
      key={propertyName}
      ref={refMainMenuButton}
      isEditing={isEditing}
      onClick={() =>
        applyCommand({
          type: 'setEditedSeriesCell',
          parentItineraryId: serie.parentItineraryId,
          serieRowId: serie.rowId,
          weekKey: null,
          editedProperty: propertyName,
        })
      }
    >
      <div
        className="content"
        title={value ? fieldDefinition.getText(t, value) : ''}
      >
        {value ? <Svg value={fieldDefinition.optionIcons[value]} /> : '-'}
      </div>
      {isEditing && (
        <Menu
          forwardedRef={refMainMenu}
          fieldDefinition={fieldDefinition}
          serie={serie}
          propertyName={propertyName}
          value={value}
          applyCommand={applyCommand}
        />
      )}
    </Container>
  );
}

interface MenuProps {
  forwardedRef: MutableRefObject<HTMLDivElement>;
  fieldDefinition: IconEnumField;
  serie: ProductSerie;
  propertyName: SerieHeaderColumnName;
  value: string;
  applyCommand: Dispatch<AllSeriesCommands>;
}
function Menu({ forwardedRef, fieldDefinition, applyCommand, serie, propertyName, value }: MenuProps) {
  const { t } = useTranslation();

  const { rowId: serieRowId, parentItineraryId, productRowId, childItineraryId } = serie;
  return (
    <StyledMenu ref={forwardedRef}>
      {fieldDefinition.options.map((value) => (
        <div
          key={value}
          className="item"
          onClick={(event) => {
            event.stopPropagation();
            applyCommand({
              type: 'editSerieProperty',
              parentItineraryId,
              productRowId,
              childItineraryId,
              serieRowId,
              propertyName,
              value,
            });
            applyCommand({
              type: 'setEditedSeriesCell',
              parentItineraryId: null,
              serieRowId: null,
              editedProperty: null,
              weekKey: null,
            });
          }}
        >
          <Svg
            className="icon"
            value={fieldDefinition.optionIcons[value]}
          />
          <div className="label">{fieldDefinition.getText(t, value)}</div>
        </div>
      ))}
    </StyledMenu>
  );
}
