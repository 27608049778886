import { useTranslation } from '@elzeard/common-components';
import { FarmingSystemEnum, toXSDDate } from '@elzeard/shared-dimensions';
import { yupResolver } from '@hookform/resolvers/yup';
import { add, parse } from 'date-fns';
import { identity } from 'lodash';
import React, { FC } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { ErrorMessage } from '../../../common/components/ErrorMessage';
import { HookedRadioGroup } from '../../../common/components/field/RadioGroup';
import { HookedSelect } from '../../../common/components/field/Select';
import { RightFormModal } from '../../../common/components/modal/RightFormModal';
import {
  Farm_FarmInput, MyProjectsQuery,
  PepiniereProjectInput, useCreatePepiniereProjectMutation, useUpdatePepiniereProjectResumeMutation
} from '../../../common/generated/graphql';
import { MyProjects } from '../../../graphql/projects';
import { translateFarmingSystemEnum } from '../../../shared-elzeard/constants/translate-constant';
import { IStepProps } from './PanelEditProject';
import { Form, FormGroup } from './styledComponents';

const farmingSystemOptions = [FarmingSystemEnum.OrganicAgriculture, FarmingSystemEnum.ConventionalAgriculture];

const months = Array(12)
  .fill(0)
  .map((_, monthNumber) => monthNumber.toString());

export interface IStep2Props extends IStepProps {
  // initialFarm: IEditedProject;
}
export const Step2: FC<IStep2Props> = ({ editedFarm, handleClose, setFormStep }) => {
  const { t } = useTranslation();

  const step2Schema = yup.object({
    schedule: yup.number().required(),
    system: yup.string().required().typeError(t('project:edit.form.fields.system.error.type')),
  });
  interface IStep2Form extends yup.InferType<typeof step2Schema> {}

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IStep2Form>({
    resolver: yupResolver(step2Schema),
    defaultValues: !editedFarm?.beginDate
      ? {
          schedule: 0,
          system: null,
        }
      : {
          schedule: editedFarm.beginDate && parse(editedFarm.beginDate, 'yyyy-MM-dd', new Date()).getMonth(),
          system: editedFarm.farmingSystem,
        },
  });

  const [mutateCreateFarm, { loading: createLoading, error: createError }] = useCreatePepiniereProjectMutation({
    update: (cache, { data }) => {
      const initialProjectsData = cache.readQuery<MyProjectsQuery>({
        query: MyProjects,
      });
      if (initialProjectsData) {
        cache.writeQuery<MyProjectsQuery>({
          query: MyProjects,
          data: {
            farmsForPepiniere: [
              ...initialProjectsData.farmsForPepiniere,
              data.createPepiniereProject.createdObject,
            ],
          },
        });
      }
    },
  });

  const [mutateUpdateFarm, { loading: updateLoading, error: updateError }] = useUpdatePepiniereProjectResumeMutation();

  const onSubmit: SubmitHandler<IStep2Form> = async (formData) => {
    const beginDate = new Date();
    beginDate.setDate(1);
    beginDate.setMonth(formData.schedule);
    const endDate = add(beginDate, { years: 1, days: -1 });

    const farmInput: Farm_FarmInput = {
      name: editedFarm.title,
      addressInput: editedFarm.address,
      geolocation: editedFarm.geopoint ? [editedFarm.geopoint.longitude, editedFarm.geopoint.latitude] : null,
      contextInput: {
        farmingSystems: formData.system ? [formData.system] : [],
      },
    };
    const projectInput: PepiniereProjectInput = {
      beginDatePlan: toXSDDate(beginDate),
      endDatePlan: toXSDDate(endDate),
    };

    if (editedFarm.id) {
      await mutateUpdateFarm({
        variables: {
          farmId: editedFarm.id,
          farmInput,
          projectInput,
        },
      });
    } else {
      await mutateCreateFarm({
        variables: {
          farmInput,
          projectInput: projectInput,
        },
      });
    }
    handleClose();
  };

  return (
    <RightFormModal
      title={editedFarm?.id ? `${t('project:edit.title')} ${editedFarm.title}` : t('project:edit.newProjectTitle')}
      confirmButtonLabelKey="common:button.validate"
      cancelButtonLabelKey="common:button.back"
      onCancel={() => setFormStep(1)}
      onClose={handleClose}
      onConfirm={handleSubmit(onSubmit)}
      loading={updateLoading || createLoading}
      footerInfo={<ErrorMessage message={(updateError || createError) && t('common:error.generic')} />}
      blueBackground
    >
      <Form>
        <FormGroup>
          <HookedRadioGroup
            fieldName="system"
            label={t('project:edit.form.fields.system.name')}
            required
            disabled={!!editedFarm.id}
            options={farmingSystemOptions}
            getOptionLabel={(value) => translateFarmingSystemEnum(t, value)}
            getOptionId={identity}
            control={control}
          />
          <ErrorMessage message={errors.system?.message} />
        </FormGroup>
        <FormGroup>
          <HookedSelect
            fieldName="schedule"
            label={t('project:edit.form.groups.schedule')}
            required
            options={months}
            getOptionLabel={(monthNumber) => t(`cc:timeline.month.full.${monthNumber}`)}
            getOptionAsString={identity}
            control={control}
          />
          <ErrorMessage message={errors.schedule?.message} />
        </FormGroup>
      </Form>
    </RightFormModal>
  );
};
