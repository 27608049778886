import { useTranslation } from '@elzeard/common-components';
import { noop } from 'lodash';
import React, { DetailedHTMLProps, FocusEvent, Ref, useCallback, useEffect, useState } from 'react';
import { FieldPath, FieldValues } from 'react-hook-form';
import styled from 'styled-components';
import { colorStyle } from '../../style/colors';
import { ControlledHookedField, ControlledHookedFieldProps } from './base/ControlledHookedField';
import { BaseFieldProps } from './base/types';
import { Field, FieldVariant } from './Field';

const Input = styled.input`
  box-sizing: border-box;
  outline: 0;
  padding: 10px 16px;
  /* text style for the placeholder */
  font-size: 12px;
  border: none;
  ::placeholder {
    color: ${colorStyle.greys['1-black']};
  }
`;

/**
 * Select input text on focus (to use with the `autofocus` attribute)
 */
const onFocus = (event: FocusEvent<HTMLInputElement>) => {
  event.currentTarget.select();
};

export type NumberInputProps = BaseFieldProps<number> &
  Partial<
    Pick<
      DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
      // | 'onChange'
      'placeholder' | 'autoFocus' | 'onKeyDown' | 'style'
    >
  > & {
    selectTextOnFocus?: boolean;
    autoSize?: boolean;
    variant?: FieldVariant | 'unstyled';
  };

export const NumberInput = React.forwardRef(
  (
    {
      error,
      label,
      onValueChange = noop,
      value,
      selectTextOnFocus,
      autoSize,
      variant = 'unstyled',
      className,
      ...inputProps
    }: NumberInputProps,
    ref: Ref<HTMLInputElement>,
  ) => {
    const { parseNumber, formatNumber } = useTranslation();
    const [stringValue, setStringValue] = useState('');
    useEffect(() => {
      setStringValue(
        (stringValue) => (stringValue === '-' ? stringValue : formatNumber(value)), //, minDecimals, maxDecimals
      );
    }, [formatNumber, value]);
    // const keyDownHandler = useMemo(() => {
    //   if (onKeyDown) {
    //     return (event: KeyboardEvent) => onKeyDown(event, value);
    //   }
    // }, [onKeyDown, value]);

    const changeHandler = useCallback(
      (e) => {
        const value = e.target.value;
        if (value === '-') {
          onValueChange(null);
          setStringValue(value);
        } else {
          const parsed = parseNumber(value);
          if (isNaN(parsed)) {
            onValueChange(null);
            setStringValue('');
          } else {
            onValueChange(parsed);
            setStringValue(value);
          }
        }
      },
      [onValueChange, parseNumber],
    );

    return variant === 'unstyled' ? (
      <Input
        ref={ref}
        type="text"
        onChange={changeHandler}
        value={stringValue}
        onFocus={selectTextOnFocus ? onFocus : undefined}
        size={autoSize ? stringValue.length : undefined}
        className={className}
        {...inputProps}
      />
    ) : (
      <Field
        $disabled={inputProps.disabled}
        $error={!!error}
        $hasValue={value != null}
        $variant={variant}
        className={className}
      >
        {label && (
          <label>
            {label}
            {inputProps.required && ' *'}
          </label>
        )}
        <input
          ref={ref}
          className="input"
          type="text"
          onChange={changeHandler}
          value={stringValue}
          onFocus={selectTextOnFocus ? onFocus : undefined}
          size={autoSize ? stringValue.length : undefined}
          {...inputProps}
        />
      </Field>
    );
  },
);

export function HookedNumberInput<Form extends FieldValues>(
  props: ControlledHookedFieldProps<
    FieldPath<Form>,
    // @ts-ignore
    number,
    Form,
    NumberInputProps
  >,
) {
  return (
    // @ts-ignore
    <ControlledHookedField
      {...props}
      // @ts-ignore
      Child={NumberInput}
    />
  );
}
