import React, { FunctionComponent, HTMLAttributes } from 'react';
import styled from 'styled-components';
import { colorStyle } from '../style/colors';
import { getTextStyle } from '../style/text';

const Container = styled.div`
  height: 36px;
  padding: 12px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  &:hover {
    cursor: pointer;
    color: ${colorStyle.cta1['1-active']};
  }
  ${getTextStyle(12, 'regular')}
`;
export interface IMainMenuItemProps extends HTMLAttributes<HTMLDivElement> {}

const MainMenuItem: FunctionComponent<IMainMenuItemProps> = (props) => {
  return <Container {...props}>{props.children}</Container>;
};

MainMenuItem.defaultProps = {};

export default MainMenuItem;
